import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import { IAccordionSection } from '@app/components/common/Accordion/AccordionSection/types';
import Box from '@app/components/common/Box';
import Checkbox from '@app/components/common/Checkbox';
import Divider from '@app/components/common/Divider';
import Grid from '@app/components/common/Grid';
import Input from '@app/components/common/Input';
import { labelStyles } from '@app/components/common/Input/styles';
import Switch from '@app/components/common/Switch';
import { actionCreatorsMenuWeb } from '@app/state';
import {
  makeSelectItemData,
  selectIsMenuCreationMode,
  selectIsMenuMasterMode,
  selectItems,
} from '@app/state/menu/menuSelectors';
import {
  checkForChangesAndNavigateWeb as checkForChangesAndNavigateWebAction,
  deleteBucketElementWeb as deleteBucketElementWebAction,
} from '@app/state/menu/menuWebActions';
import { store } from '@app/state/store';
import {
  displayFlexEndColumnStyles,
  displayFlexRowCenterStyles,
} from '@app/theme/commonStyles';
import { MQ_MAX_MEDIUM } from '@app/theme/types';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  BreadCrumbAction,
  ECoursingType,
  IMenuType,
  SectionId,
  actionCreatorsApp,
  actionCreatorsMenu,
  coursingTypeDropdownOptions,
  coursingTypeToCoursingTypeValue,
  coursingTypeValueToCoursingType,
  useFormError,
} from '@westondev/tableturn-core';
import { useCallback, useMemo, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import MenuCloneModal from '../../../MenuCloneModal';
import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';
import Dropdown from '@app/components/common/Dropdown';
import Typography from '@app/components/common/Typography';
import { selectIsCoursingEnabled } from '@app/state/selectors/settingsSelectors';

const SECTION_ID = SectionId.BASIC;

const getMemoizedItemData = makeSelectItemData('menuTypes', SECTION_ID);
const BasicSection = ({ t, ...props }: IAccordionSection & WithTranslation) => {
  const navigate = useNavigateWithOrg();

  const dispatch = useDispatch();
  const { updateMenuType: setValue } = bindActionCreators(
    actionCreatorsMenu,
    dispatch,
  );

  const addBreadcrumbLocationWeb = bindActionCreators(
    actionCreatorsMenuWeb.addBreadcrumbLocationWeb,
    dispatch,
  );

  const { setShowConfirmationModal, showToast } = bindActionCreators(
    actionCreatorsApp,
    useDispatch(),
  );

  const deleteBucketElementWeb = bindActionCreators(
    deleteBucketElementWebAction,
    dispatch,
  );
  const checkForChangesAndNavigateWeb = bindActionCreators(
    checkForChangesAndNavigateWebAction,
    dispatch,
  );
  const { itemData } = useSelector(getMemoizedItemData);
  const isMasterMode = useSelector(selectIsMenuMasterMode);
  const isCreation = useSelector(selectIsMenuCreationMode);
  const items = useSelector(selectItems);
  const isCoursingDisabled = !useSelector(selectIsCoursingEnabled);

  const nameError = useFormError('name');
  const posNameError = useFormError('posName');
  const comboDisplayNameError = useFormError('comboDisplayName');

  const hasErrors = Boolean(nameError?.error) || Boolean(posNameError?.error);

  const [isCloneModalOpen, setIsCloneModalOpen] = useState(false);

  const handleDeleteMenuType = useCallback(() => {
    const { itemIds: itemsInMenuTypeIds } = itemData as IMenuType;
    const itemsInMasterMenu = items;

    let canDeleteMenuType = true;

    let itemsListString = '';

    for (const itemId of itemsInMenuTypeIds) {
      const currentItem = itemsInMasterMenu[itemId];

      if (!currentItem) continue;

      const menuTypeVersions = Object.keys(
        currentItem?.menuTypeVersions,
      ).length;

      if (menuTypeVersions < 2) {
        canDeleteMenuType = false;
        itemsListString =
          itemsListString.length > 0
            ? itemsListString + `\n${currentItem.name}`
            : currentItem.name;
      }
    }

    if (canDeleteMenuType)
      setShowConfirmationModal({
        active: true,
        title: t('menuScreen.common.bucket.menuTypes.singular'),
        type: 'delete',
        name: itemData?.name,
        onSuccess: () => {
          deleteBucketElementWeb();
        },
      });
    else
      setShowConfirmationModal({
        active: true,
        title: t('app.modals.cannotDeleteMenuTypeModal.title'),
        description: `${t(
          'app.modals.cannotDeleteMenuTypeModal.description',
        )}\n\n${itemsListString}`,
        type: 'warning',
        name: itemData?.name,
        onSuccess: () => null,
        hideCancel: true,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    items,
    deleteBucketElementWeb,
    itemData?.name,
    setShowConfirmationModal,
    t,
  ]);

  const options = useMemo(() => {
    return !isCreation
      ? [
          {
            text: t('commonButtons.clone'),
            handler: () => setIsCloneModalOpen(true),
            isDisabled: false,
          },
          {
            text: t('commonButtons.delete'),
            handler: handleDeleteMenuType,
            isDisabled: !isMasterMode,
          },
        ]
      : [];
  }, [handleDeleteMenuType, isCreation, isMasterMode, t]);

  const handleOnPressDisabled = () => {
    showToast({
      type: 'info',
      title: t('menuScreen.subcategoryMain.disabledActionToast.title'),
      description: isCreation
        ? t('menuScreen.menuTypeDetails.basic.optionsDisabled.creation')
        : t('menuScreen.menuTypeDetails.basic.optionsDisabled.location'),
    });
  };

  const activeSwitch = (
    <Switch
      checked={itemData ? itemData.active : false}
      onChange={active => setValue({ active })}
      showDefaultLabel
    />
  );

  const displayComboName = (
    <Input
      required
      label={t('menuScreen.menuTypeDetails.basic.comboDisplayName')}
      placeholder={t('menuScreen.menuTypeDetails.basic.comboDisplayName')}
      info={t('menuScreen.menuTypeDetails.basic.comboDisplayNametoolTip')}
      value={itemData?.comboDisplayName || ''}
      onChange={comboDisplayName =>
        setValue({
          comboDisplayName: comboDisplayName.target.value,
        })
      }
      error={Boolean(comboDisplayNameError?.error)}
      onFocus={() => {
        comboDisplayNameError?.clear();
      }}
    />
  );

  return (
    <>
      <MenuCloneModal
        bucket="menuTypes"
        active={isCloneModalOpen}
        onCancel={() => setIsCloneModalOpen(false)}
        onClone={newId => {
          checkForChangesAndNavigateWeb(
            () => {
              const updatedMenu =
                store.getState().menu.selectedMode.currentMenu;
              const menuType = updatedMenu.menuTypes[newId];
              addBreadcrumbLocationWeb({
                action: BreadCrumbAction.NAV,
                text: menuType.name,
                onPress: () => {
                  navigate(`/menu/menus/${menuType.id}`);
                },
                statusPath: `selectedMode.currentMenu.menuTypes.${menuType.id}.active`,
                pathURL: `/menu/menus/${menuType.id}`,
              });
            },
            () => null,
          );
        }}
        name={itemData?.name || ''}
      />
      <AccordionSection
        title={t('loggedIn.commonTitles.basic')}
        required
        hasError={hasErrors}
        titleTextContainerCsx={{ minWidth: '170px' }}
        collapsedHeaderComponent={
          <Box
            csx={[
              {
                display: 'flex',
                height: '100%',
                gap: '10px',
              },
            ]}>
            <Divider direction="vertical" />
            {activeSwitch}
          </Box>
        }
        options={options}
        optionsDisabled={isCreation}
        onOptionsClickDisabled={handleOnPressDisabled}
        {...props}>
        <>
          {activeSwitch}
          <Divider csx={{ marginTop: '20px' }} />
          <Grid columnGap={20}>
            <Grid.Item mb={12} lg={6} csx={{ padding: '20px 0' }}>
              <Grid rowGap={20} columnGap={20}>
                <Grid.Item mb={12} sm={6} csx={displayFlexEndColumnStyles}>
                  <Input
                    required
                    label={t('loggedIn.commonFields.basic.name')}
                    placeholder={t('loggedIn.commonFields.basic.name')}
                    value={itemData?.name || ''}
                    disabled={!isMasterMode && !isCreation}
                    onChange={name => {
                      const newName = name.target.value;

                      if (itemData?.name === itemData?.posName) {
                        setValue({
                          posName: newName,
                        });
                        if (posNameError?.error) posNameError.clear();
                      }

                      setValue({
                        name: newName,
                      });

                      if (nameError?.error) {
                        nameError.clear();
                      }
                    }}
                    error={Boolean(nameError?.error)}
                    onFocus={() => {
                      nameError?.clear();
                    }}
                  />
                </Grid.Item>
                <Grid.Item mb={12} sm={6} csx={displayFlexEndColumnStyles}>
                  <Input
                    required
                    label={t('loggedIn.commonFields.basic.posName')}
                    placeholder={t('loggedIn.commonFields.basic.posName')}
                    value={itemData?.posName || ''}
                    onChange={name => {
                      setValue({
                        posName: name.target.value,
                      });

                      if (posNameError?.error) posNameError.clear();
                    }}
                    error={Boolean(posNameError?.error)}
                    onFocus={() => {
                      posNameError?.clear();
                    }}
                  />
                </Grid.Item>
                <Grid.Item mb={12} sm={6} csx={[displayFlexEndColumnStyles]}>
                  {displayComboName}
                </Grid.Item>
              </Grid>
            </Grid.Item>
            <Grid.Item
              mb={0}
              lg={1}
              csx={{
                height: '100%',
              }}>
              <Divider
                direction="vertical"
                csx={{
                  height: '100%',
                  alignSelf: 'center',
                  [MQ_MAX_MEDIUM]: {
                    display: 'none',
                    marginLeft: '0',
                  },
                }}
              />
            </Grid.Item>
            <Grid.Item lg={5} csx={{ paddingBottom: '20px' }}>
              <Grid>
                <Grid.Item mb={12}>
                  <label css={labelStyles}>&nbsp;</label>
                  <Box
                    csx={[
                      displayFlexRowCenterStyles,
                      { flexGrow: 1, justifyContent: 'flex-start' },
                    ]}>
                    <Checkbox
                      label={t(
                        'menuScreen.menuTypeDetails.basic.enableSimpleMode',
                      )}
                      info={t(
                        'menuScreen.menuTypeDetails.basic.enableSimpleModetoolTip',
                      )}
                      checked={itemData ? itemData.enableSimpleMode : false}
                      onChange={enableSimpleMode =>
                        setValue({
                          enableSimpleMode,
                        })
                      }
                    />
                  </Box>
                </Grid.Item>
                <Grid.Item mb={12}>
                  <label css={labelStyles}>&nbsp;</label>
                  <Box
                    csx={[
                      displayFlexRowCenterStyles,
                      { flexGrow: 1, justifyContent: 'flex-start' },
                    ]}>
                    <Checkbox
                      label={t(
                        'menuScreen.menuTypeDetails.basic.defaultToHomeScreen',
                      )}
                      checked={itemData ? itemData.defaultToHomeScreen : false}
                      onChange={defaultToHomeScreen =>
                        setValue({
                          defaultToHomeScreen,
                        })
                      }
                    />
                  </Box>
                </Grid.Item>
              </Grid>
            </Grid.Item>
          </Grid>

          <Divider />

          <Grid rowGap={20} columnGap={20}>
            <Grid.Item
              mb={12}
              sm={6}
              lg={3}
              csx={[displayFlexEndColumnStyles, { gap: '10px' }]}>
              <Dropdown
                data={[coursingTypeDropdownOptions]}
                onChange={value =>
                  setValue({
                    coursingType: coursingTypeValueToCoursingType[value],
                  })
                }
                value={coursingTypeToCoursingTypeValue[itemData?.coursingType]}
                label={t('menuScreen.menuTypeDetails.basic.coursingType')}
              />
              {isCoursingDisabled &&
                itemData?.coursingType === ECoursingType.COURSING && (
                  <Typography variant="caption" color="semanticRed">
                    {t(
                      'menuScreen.menuTypeDetails.basic.coursingDisabledLabel',
                    )}
                  </Typography>
                )}
            </Grid.Item>
          </Grid>
        </>
      </AccordionSection>
    </>
  );
};

export default BasicSection;
