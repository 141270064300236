import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import Dropdown from '@app/components/common/Dropdown';
import Grid from '@app/components/common/Grid';
import Modal from '@app/components/common/Modal';
import SelectionModal from '@app/components/common/SelectionModal';
import { IItemSelection } from '@app/components/common/SelectionModal/GenericSelectionModal/types';
import Typography from '@app/components/common/Typography';
import { actionCreatorsMenuWeb } from '@app/state';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  IMenuStore,
  ItemMassUpdateCategoryOrSubcategoryOptions,
  useItemMassUpdate,
} from '@westondev/tableturn-core';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { itemMassUpdateModalStyles } from './styles';
import { IItemMassUpdateModal } from './types';

const ItemMassUpdateModal = ({
  t,
  isActive = false,
  onCancel = () => null,
  onClose = () => null,
  updateChangeData,
}: IItemMassUpdateModal) => {
  const { itemMassUpdateConfirmationWeb } = bindActionCreators(
    actionCreatorsMenuWeb,
    useDispatch(),
  );

  const [openSelectionModal, setOpenSelectionModal] = useState(false);
  const [selectionModalBucket, setSelectionModalBucket] =
    useState<keyof IMenuStore>('menuTypes');

  const closeSelectionModal = () => setOpenSelectionModal(false);

  const {
    totalItems,
    handleSelectedMenuTypes,
    handleSelectCategoryOrSubcategory,
    handleCategoryOrSubcategory,
    selectedMenuTypes,
    selectedCategoryIds,
    selectedSubcategoryIds,
    selectedCategoryOrSubcategory,
    optionsFactory,
  } = useItemMassUpdate(isActive);

  const selectedMenuTypesNumber = useMemo(
    () => selectedMenuTypes.map(selectedMenuType => selectedMenuType.value),
    [selectedMenuTypes],
  );

  const selectedButtonIds = useMemo(() => {
    switch (selectionModalBucket) {
      case 'menuTypes':
        return selectedMenuTypesNumber;
      case 'categories':
        return selectedCategoryIds || [];
      case 'subcategories':
        return selectedSubcategoryIds || [];
      default:
        return [];
    }
  }, [
    selectionModalBucket,
    selectedMenuTypesNumber,
    selectedCategoryIds,
    selectedSubcategoryIds,
  ]);

  return (
    <>
      <SelectionModal
        type={selectionModalBucket as 'menuTypes'}
        detailsScreenProps={{
          currentRelationsIds: [],
          wantedEntity: selectionModalBucket,
          filterFunction: elements => {
            if (selectionModalBucket === 'menuTypes') {
              return elements;
            }

            const optionIds = optionsFactory(
              selectionModalBucket as 'categories',
            )[1].map(option => option.value);

            return elements.filter(element =>
              optionIds.includes(Number(element)),
            );
          },
        }}
        onAssociate={(buttons: IItemSelection[]) => {
          switch (selectionModalBucket) {
            case 'menuTypes':
              handleSelectedMenuTypes(
                buttons.map(button => ({
                  value: Number(button.id),
                  label: '',
                })),
              );
              break;
            case 'categories':
            case 'subcategories':
              handleCategoryOrSubcategory(
                buttons.map(button => Number(button.id)),
              );
              break;
            default:
              break;
          }

          closeSelectionModal();
        }}
        description={' '}
        active={openSelectionModal}
        onModalClose={closeSelectionModal}
        showSelectAll
        selectedButtonIds={selectedButtonIds}
      />
      <Modal
        isActive={isActive}
        size="80%"
        title={t('app.modals.itemMassUpdate.title')}
        onModalClose={onClose}
        csx={itemMassUpdateModalStyles}
        footer={
          <>
            <Button variant="secondary" className="button" onClick={onCancel}>
              {t('commonButtons.cancel')}
            </Button>
            <Button
              className="button"
              disabled={totalItems.size === 0}
              variant="primary"
              onClick={() =>
                itemMassUpdateConfirmationWeb(
                  totalItems.size,
                  {
                    menuTypesIds: selectedMenuTypes.map(({ value }) => value),
                    categoryIds: selectedCategoryIds,
                    subcategoryIds: selectedSubcategoryIds,
                    itemsIds: Array.from(totalItems),
                  },
                  onClose,
                  updateChangeData,
                )
              }>
              {t('app.modals.itemMassUpdate.buttons.selectItems', {
                count: totalItems.size,
              })}
            </Button>
          </>
        }>
        <Grid>
          <Grid.Item>
            <Typography color="darkestGrey">
              {t('app.modals.itemMassUpdate.description')}
            </Typography>
          </Grid.Item>
          <Grid.Item>
            <Box className="filters-grid">
              <Grid columnGap={40}>
                <Grid.Item mb={12} sm={6} md={4}>
                  <Dropdown.MultiSelect
                    label={t('menuScreen.common.bucket.menuTypes.plural')}
                    placeholder={t(
                      'app.modals.itemMassUpdate.menuTypesPlaceholder',
                    )}
                    value={selectedMenuTypesNumber}
                    data={optionsFactory('menuTypes')}
                    isEditable={false}
                    onActive={() => {
                      setOpenSelectionModal(true);
                      setSelectionModalBucket('menuTypes');
                    }}
                    onChange={() => null}
                  />
                </Grid.Item>
                <Grid.Item mb={12} sm={6} md={4}>
                  <Dropdown
                    label={t('app.modals.itemMassUpdate.categoryOrSubcategory')}
                    placeholder={t(
                      'app.modals.itemMassUpdate.categoryOrSubcategory',
                    )}
                    data={[
                      [
                        {
                          value: ItemMassUpdateCategoryOrSubcategoryOptions.ALL,
                          label: t('commonTexts.all'),
                        },
                      ],
                      [
                        {
                          value:
                            ItemMassUpdateCategoryOrSubcategoryOptions.CATEGORY,
                          label: t(
                            'menuScreen.common.bucket.categories.singular',
                          ),
                        },
                        {
                          value:
                            ItemMassUpdateCategoryOrSubcategoryOptions.SUBCATEGORY,
                          label: t(
                            'menuScreen.common.bucket.subcategories.singular',
                          ),
                        },
                      ],
                    ]}
                    value={selectedCategoryOrSubcategory}
                    onChange={category =>
                      handleSelectCategoryOrSubcategory(category)
                    }
                  />
                </Grid.Item>
                <Grid.Item mb={12} sm={6} md={4}>
                  <Dropdown.MultiSelect
                    label={
                      selectedCategoryOrSubcategory ===
                      ItemMassUpdateCategoryOrSubcategoryOptions.SUBCATEGORY
                        ? t('menuScreen.common.bucket.subcategories.singular')
                        : t('menuScreen.common.bucket.categories.singular')
                    }
                    placeholder={
                      selectedCategoryOrSubcategory ===
                      ItemMassUpdateCategoryOrSubcategoryOptions.SUBCATEGORY
                        ? t('menuScreen.common.bucket.subcategories.singular')
                        : t('menuScreen.common.bucket.categories.singular')
                    }
                    data={optionsFactory(
                      selectedCategoryOrSubcategory ===
                        ItemMassUpdateCategoryOrSubcategoryOptions.CATEGORY
                        ? 'categories'
                        : 'subcategories',
                    )}
                    value={
                      (selectedCategoryOrSubcategory ===
                      ItemMassUpdateCategoryOrSubcategoryOptions.CATEGORY
                        ? selectedCategoryIds
                        : selectedSubcategoryIds) || []
                    }
                    onActive={() => {
                      setOpenSelectionModal(true);
                      setSelectionModalBucket(
                        selectedCategoryOrSubcategory ===
                          ItemMassUpdateCategoryOrSubcategoryOptions.CATEGORY
                          ? 'categories'
                          : 'subcategories',
                      );
                    }}
                    isEditable={false}
                    onChange={() => null}
                    isDisabled={
                      selectedCategoryOrSubcategory ===
                        ItemMassUpdateCategoryOrSubcategoryOptions.ALL ||
                      selectedMenuTypes.length === 0
                    }
                  />
                </Grid.Item>
              </Grid>
            </Box>
          </Grid.Item>
        </Grid>
      </Modal>
    </>
  );
};

export default ItemMassUpdateModal;
