import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import { IAccordionSection } from '@app/components/common/Accordion/AccordionSection/types';
import Box from '@app/components/common/Box';
import Checkbox from '@app/components/common/Checkbox';
import Divider from '@app/components/common/Divider';
import Dropdown from '@app/components/common/Dropdown';
import Grid from '@app/components/common/Grid';
import Input from '@app/components/common/Input';
import MoneyInput from '@app/components/common/MoneyInput';
import Switch from '@app/components/common/Switch';
import ToolTip from '@app/components/common/ToolTip';
import Typography from '@app/components/common/Typography';
import DetailsSubtitle from '@app/components/LoggedIn/DetailsSubtitle';
import useRefreshMenuWeb from '@app/hooks/useRefreshMenuWeb';
import { actionCreatorsMenuWeb } from '@app/state';
import {
  makeSelectItemData,
  selectCurrentItem,
  selectIsMenuCreationMode,
  selectIsMenuMasterMode,
} from '@app/state/menu/menuSelectors';
import {
  checkForChangesAndNavigateWeb as checkForChangesAndNavigateWebAction,
  deleteBucketElementWeb as deleteBucketElementWebAction,
} from '@app/state/menu/menuWebActions';
import { store } from '@app/state/store';
import { displayFlexEndColumnStyles } from '@app/theme/commonStyles';
import { MQ_MIN_LARGE } from '@app/theme/types';
import { useTheme } from '@emotion/react';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  BreadCrumbAction,
  CONFIRMATION_MODAL_INITIAL_STATE,
  CustomizerPartition,
  IModifierGroups,
  PriceCalcModes,
  QuantityModes,
  QuantitySizes,
  SectionId,
  SubSections,
  TPriceCalcModes,
  TQuantityModeTypes,
  actionCreatorsApp,
  actionCreatorsMenu,
  useFormError,
} from '@westondev/tableturn-core';
import { mapValues, upperCase } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import MenuCloneModal from '../../../MenuCloneModal';
import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';

const SUB_NAMES = [
  'posName' as const,
  'kdsName' as const,
  'checkName' as const,
  'onlineName' as const,
];

const gridItemSizingInputs = {
  mb: 20,
  sm: 10,
  lg: 4,
};

const gridItemSizingDropdowns = {
  mb: 12,
  sm: 6,
  md: 6,
  lg: 12,
  xl: 3,
};

const quantityModes: TQuantityModeTypes[] = [
  QuantityModes.NUMBER,
  QuantityModes.SIZE,
  QuantityModes.OFF,
];

const customizerSlicingModes: CustomizerPartition[] = [
  CustomizerPartition.QUARTERS,
  CustomizerPartition.HALVES,
];

const priceCalc: TPriceCalcModes[] = [PriceCalcModes.MAX, PriceCalcModes.MIN];

const SECTION_ID = SectionId.BASIC;

const getMemoizedItemData = makeSelectItemData('modifierGroups', SECTION_ID);

const BasicSection = ({ t, ...props }: IAccordionSection & WithTranslation) => {
  const navigate = useNavigateWithOrg();
  const dispatch = useDispatch();
  const {
    updateMenuBucket: setValue,
    updateModifierGroup,
    getDataFromChangeData,
  } = bindActionCreators(actionCreatorsMenu, dispatch);

  const addBreadcrumbLocationWeb = bindActionCreators(
    actionCreatorsMenuWeb.addBreadcrumbLocationWeb,
    dispatch,
  );

  const { setShowConfirmationModal, showToast } = bindActionCreators(
    actionCreatorsApp,
    dispatch,
  );

  const checkForChangesAndNavigateWeb = bindActionCreators(
    checkForChangesAndNavigateWebAction,
    dispatch,
  );

  const deleteBucketElementWeb = bindActionCreators(
    deleteBucketElementWebAction,
    dispatch,
  );

  const onRefresh = useRefreshMenuWeb();

  const { itemData } = useSelector(getMemoizedItemData);

  const isMasterMode = useSelector(selectIsMenuMasterMode);
  const isCreation = useSelector(selectIsMenuCreationMode);
  const theme = useTheme();

  const currentModifierGroup = useSelector(
    selectCurrentItem,
  ) as IModifierGroups;

  const [isCloneModalActive, setIsCloneModalActive] = useState(false);
  const [showHover, setShowHover] = useState(false);
  const [newTimeout, setNewTimeout] = useState<NodeJS.Timeout | null>(null);

  const activeSwitch = (
    <Switch
      checked={itemData ? itemData.active : false}
      onChange={active => setValue({ active }, SECTION_ID)}
      showDefaultLabel
    />
  );

  const nameError = useFormError('name');
  const posNameError = useFormError('posName');
  const kdsNameError = useFormError('kdsName');
  const checkNameError = useFormError('checkName');
  const onlineNameError = useFormError('onlineName');
  const includedModifierItemsError = useFormError('includedModifierItems');
  const requiredModifierItemsError = useFormError('requiredModifierItems');

  const errorMap = {
    posName: posNameError,
    kdsName: kdsNameError,
    checkName: checkNameError,
    onlineName: onlineNameError,
    includedModifierItems: includedModifierItemsError,
    requiredModifierItems: requiredModifierItemsError,
  };

  const handleDelete = useCallback(() => {
    setShowConfirmationModal({
      active: true,
      title: t('menuScreen.common.bucket.modifierGroups.singular'),
      type: 'delete',
      name: itemData?.name,
      onSuccess: async () => {
        setShowConfirmationModal(CONFIRMATION_MODAL_INITIAL_STATE);
        deleteBucketElementWeb();
      },
    });
  }, [deleteBucketElementWeb, itemData?.name, setShowConfirmationModal, t]);

  const handleNameChange = (name: string) => {
    const changes: Record<string, string> = {};
    const previousName = itemData?.name;
    SUB_NAMES.forEach(subName => {
      const value = itemData?.[subName];
      if (!value || value === previousName) {
        changes[subName] = name;
        if (errorMap[subName]?.error) {
          errorMap[subName]?.clear();
        }
      }
    });
    setValue({ ...changes, name }, SECTION_ID);
  };

  const options = useMemo(() => {
    return [
      {
        text: t('commonButtons.clone'),
        handler: () => setIsCloneModalActive(true),
        isDisabled: false,
      },
      {
        text: t('commonButtons.delete'),
        handler: handleDelete,
        isDisabled: !isMasterMode,
      },
    ];
  }, [handleDelete, isMasterMode, t]);

  const hasSectionError =
    Boolean(nameError?.error) ||
    Boolean(posNameError?.error) ||
    Boolean(kdsNameError?.error) ||
    Boolean(checkNameError?.error) ||
    Boolean(onlineNameError?.error) ||
    Boolean(includedModifierItemsError?.error) ||
    Boolean(requiredModifierItemsError?.error);

  const handleModifierItemsSelection = (value: Partial<IModifierGroups>) => {
    const modifierGroup = getDataFromChangeData() as unknown as IModifierGroups;
    const modifierItems = mapValues(modifierGroup.modifierItems, modifier => ({
      ...modifier,
      isSelected: false,
      defaultAmountSelected: QuantitySizes.REGULAR,
      defaultQuantitySelected: 0,
    }));
    updateModifierGroup(
      { ...value, modifierItems },
      SectionId.BASIC,
      SubSections.MODIFIER_ITEM,
    );
    updateModifierGroup({}, SectionId.ASSOCIATION);
  };

  const handlePopUp = () => {
    if (newTimeout) clearTimeout(newTimeout);
    setNewTimeout(
      setTimeout(() => {
        setShowHover(true);
      }, 500),
    );
  };

  return (
    <>
      <MenuCloneModal
        active={isCloneModalActive}
        bucket="modifierGroups"
        onCancel={() => setIsCloneModalActive(false)}
        onClose={() => setIsCloneModalActive(false)}
        onClone={newId => {
          const currentMenu = store.getState().menu.selectedMode.currentMenu;
          checkForChangesAndNavigateWeb(() => {
            const _navigate = () =>
              navigate(`/menu/modifiers/modifier-groups/${newId}`);
            addBreadcrumbLocationWeb({
              action: BreadCrumbAction.REPLACE,
              text: currentMenu.modifierGroups[newId]?.name,
              onPress: _navigate,
              pathURL: `/menu/modifiers/modifier-groups/${newId}`,
            });
            _navigate();
          }, onRefresh);
        }}
        name={itemData?.name || ''}
      />
      <AccordionSection
        title={t('loggedIn.commonTitles.basic')}
        required
        hasError={hasSectionError}
        titleTextContainerCsx={{ minWidth: '150px' }}
        collapsedHeaderComponent={
          <Box
            csx={[
              {
                display: 'flex',
                height: '100%',
                gap: '10px',
              },
            ]}>
            <Divider direction="vertical" />
            {activeSwitch}
          </Box>
        }
        options={options}
        optionsDisabled={isCreation}
        onOptionsClickDisabled={() =>
          showToast({
            type: 'info',
            title: t('menuScreen.categoryMain.disabledActionToast.title'),
            description: isCreation
              ? t(
                  'menuScreen.modifierGroupDetails.basic.optionsDisabled.creation',
                )
              : t(
                  'menuScreen.modifierGroupDetails.basic.optionsDisabled.location',
                ),
          })
        }
        {...props}>
        <>
          {activeSwitch}
          <Divider csx={{ marginBlock: '20px' }} />
          <Grid
            rowGap={20}
            columnGap={20}
            columns={20}
            csx={{ alignItems: 'flex-start' }}>
            <Grid.Item
              {...gridItemSizingInputs}
              csx={displayFlexEndColumnStyles}>
              <Input
                required
                caption={nameError?.error}
                error={Boolean(nameError?.error)}
                label={t('loggedIn.commonFields.basic.name')}
                placeholder={t('loggedIn.commonFields.basic.name')}
                value={itemData?.name || ''}
                disabled={!isMasterMode && !isCreation}
                onChange={e => handleNameChange(e.currentTarget.value)}
                onFocus={() => nameError && nameError.clear()}
              />
            </Grid.Item>
            <Grid.Item
              {...gridItemSizingInputs}
              csx={displayFlexEndColumnStyles}>
              <Input
                required
                label={t('loggedIn.commonFields.basic.posName')}
                placeholder={t('loggedIn.commonFields.basic.posName')}
                caption={posNameError?.error}
                error={Boolean(posNameError?.error)}
                value={itemData?.posName || ''}
                onChange={posName =>
                  setValue(
                    {
                      posName: posName.currentTarget.value,
                    },
                    SECTION_ID,
                  )
                }
                onFocus={() => posNameError && posNameError.clear()}
              />
            </Grid.Item>
            <Grid.Item
              {...gridItemSizingInputs}
              csx={displayFlexEndColumnStyles}>
              <Input
                required
                label={t('loggedIn.commonFields.basic.kdsName')}
                placeholder={t('loggedIn.commonFields.basic.kdsName')}
                caption={kdsNameError?.error}
                error={Boolean(kdsNameError?.error)}
                value={itemData?.kdsName || ''}
                onChange={kdsName =>
                  setValue(
                    {
                      kdsName: kdsName.currentTarget.value,
                    },
                    SECTION_ID,
                  )
                }
                onFocus={() => kdsNameError && kdsNameError.clear()}
              />
            </Grid.Item>
            <Grid.Item
              {...gridItemSizingInputs}
              csx={displayFlexEndColumnStyles}>
              <Input
                required
                label={t('loggedIn.commonFields.basic.checkName')}
                placeholder={t('loggedIn.commonFields.basic.checkName')}
                caption={checkNameError?.error}
                error={Boolean(checkNameError?.error)}
                value={itemData?.checkName || ''}
                onChange={checkName =>
                  setValue(
                    {
                      checkName: checkName.currentTarget.value,
                    },
                    SECTION_ID,
                  )
                }
                onFocus={() => checkNameError && checkNameError.clear()}
              />
            </Grid.Item>
            <Grid.Item
              {...gridItemSizingInputs}
              csx={displayFlexEndColumnStyles}>
              <Input
                required
                label={t('loggedIn.commonFields.basic.onlineName')}
                placeholder={t('loggedIn.commonFields.basic.onlineName')}
                caption={onlineNameError?.error}
                error={Boolean(onlineNameError?.error)}
                value={itemData?.onlineName || ''}
                onChange={onlineName =>
                  setValue(
                    {
                      onlineName: onlineName.currentTarget.value,
                    },
                    SECTION_ID,
                  )
                }
                onFocus={() => onlineNameError && onlineNameError.clear()}
              />
            </Grid.Item>
          </Grid>

          <Grid
            csx={{
              alignItems: 'flex-start',
              margin: '20px 0px',
              borderTop: '1px solid',
              borderBottom: '1px solid',
              borderColor: theme.colors.lighterGrey,
            }}>
            <Grid.Item mb={12} lg={6} xl={8}>
              <DetailsSubtitle csx={{ marginTop: '20px' }}>
                {t('loggedIn.commonFields.basic.advancedOptions')}
              </DetailsSubtitle>
              <Grid
                rowGap={20}
                columnGap={20}
                csx={{ alignItems: 'flex-start', padding: '20px 0px' }}>
                <Grid.Item
                  {...gridItemSizingDropdowns}
                  csx={[{ height: '100%' }, displayFlexEndColumnStyles]}>
                  <Dropdown
                    label={t(
                      'menuScreen.modifierGroupDetails.basic.quantityMode',
                    )}
                    placeholder={t(
                      'menuScreen.modifierGroupDetails.basic.quantityMode',
                    )}
                    info={t(
                      'menuScreen.modifierGroupDetails.basic.quantityModetoolTip',
                    )}
                    data={[
                      [
                        { label: QuantityModes.NUMBER, value: 0 },
                        { label: QuantityModes.SIZE, value: 1 },
                        { label: upperCase(QuantityModes.OFF), value: 2 },
                      ],
                    ]}
                    onChange={quantityMode => {
                      handleModifierItemsSelection({
                        quantityMode: quantityModes[quantityMode],
                      });
                    }}
                    value={quantityModes.indexOf(
                      itemData ? itemData.quantityMode : 'Off',
                    )}
                  />
                </Grid.Item>

                <Grid.Item
                  {...gridItemSizingDropdowns}
                  csx={[
                    displayFlexEndColumnStyles,
                    {
                      alignSelf: 'flex-end',
                    },
                  ]}>
                  <MoneyInput
                    label={t(
                      'menuScreen.modifierGroupDetails.basic.maximumModifierItems',
                    )}
                    placeholder={t(
                      'menuScreen.modifierGroupDetails.basic.noMax',
                    )}
                    info={t(
                      'menuScreen.modifierGroupDetails.basic.maximumModifierItemstooltip',
                    )}
                    value={
                      currentModifierGroup
                        ? currentModifierGroup.maximumModifierItems || 0
                        : 0
                    }
                    prefix=""
                    precision={0}
                    onValueChange={maximumModifierItems => {
                      updateModifierGroup(
                        {
                          maximumModifierItems,
                        },
                        SectionId.BASIC,
                        SubSections.ALL,
                      );
                      updateModifierGroup({}, SectionId.ASSOCIATION);
                    }}
                    showPlaceholder
                    maxValue={99}
                  />
                </Grid.Item>
                <Grid.Item
                  {...gridItemSizingDropdowns}
                  csx={[
                    displayFlexEndColumnStyles,
                    {
                      alignSelf: 'flex-end',
                    },
                  ]}>
                  <MoneyInput
                    label={t(
                      'menuScreen.modifierGroupDetails.basic.includedModifierItems',
                    )}
                    info={t(
                      'menuScreen.modifierGroupDetails.basic.includedModifierItemstoolTip',
                    )}
                    value={
                      currentModifierGroup
                        ? currentModifierGroup.includedModifierItems
                        : 0
                    }
                    prefix=""
                    precision={0}
                    onValueChange={includedModifierItems => {
                      updateModifierGroup(
                        {
                          includedModifierItems,
                        },
                        SectionId.BASIC,
                        SubSections.ALL,
                      );
                      updateModifierGroup({}, SectionId.ASSOCIATION);
                    }}
                    maxValue={99}
                    error={Boolean(includedModifierItemsError?.error)}
                    onFocus={() =>
                      includedModifierItemsError &&
                      includedModifierItemsError.clear()
                    }
                  />
                </Grid.Item>

                <Grid.Item
                  {...gridItemSizingDropdowns}
                  csx={[
                    displayFlexEndColumnStyles,
                    {
                      alignSelf: 'flex-end',
                    },
                  ]}>
                  <MoneyInput
                    label={t(
                      'menuScreen.modifierGroupDetails.basic.requiredModifierItems',
                    )}
                    info={t(
                      'menuScreen.modifierGroupDetails.basic.requiredModifierItemstoolTip',
                    )}
                    value={
                      currentModifierGroup
                        ? currentModifierGroup.requiredModifierItems
                        : 0
                    }
                    prefix=""
                    precision={0}
                    onValueChange={requiredModifierItems => {
                      updateModifierGroup(
                        { requiredModifierItems },
                        SectionId.BASIC,
                        SubSections.ALL,
                      );
                      updateModifierGroup({}, SectionId.ASSOCIATION);
                    }}
                    maxValue={99}
                    error={Boolean(requiredModifierItemsError?.error)}
                    onFocus={() =>
                      requiredModifierItemsError &&
                      requiredModifierItemsError.clear()
                    }
                  />
                </Grid.Item>
              </Grid>
              <Grid
                rowGap={20}
                columnGap={20}
                csx={{
                  alignItems: 'flex-start',
                  padding: '20px 0px',
                  width: '100%',
                }}>
                <Grid.Item mb={12} xl={3} csx={displayFlexEndColumnStyles}>
                  <Dropdown
                    label={t(
                      'menuScreen.modifierGroupDetails.basic.pricingMode',
                    )}
                    info={t(
                      'menuScreen.modifierGroupDetails.basic.pricingModetoolTip',
                    )}
                    placeholder={t('commonTexts.none')}
                    data={[
                      [
                        { label: 'Maximize', value: 0 },
                        { label: 'Minimize', value: 1 },
                      ],
                    ]}
                    onChange={pricingMode => {
                      updateModifierGroup(
                        {
                          priceCalcMode: priceCalc[pricingMode],
                        },
                        SectionId.BASIC,
                        SubSections.ALL,
                      );
                      updateModifierGroup({}, SectionId.ASSOCIATION);
                    }}
                    value={priceCalc.indexOf(
                      itemData ? itemData.priceCalcMode : PriceCalcModes.MAX,
                    )}
                  />
                </Grid.Item>
                <Grid.Item
                  mb={12}
                  xl={3}
                  csx={[
                    displayFlexEndColumnStyles,
                    {
                      height: '100%',
                      padding: '11px 0px',
                    },
                  ]}>
                  <Checkbox
                    label={t(
                      'menuScreen.modifierGroupDetails.basic.showSearchBar',
                    )}
                    info={t(
                      'menuScreen.modifierGroupDetails.basic.showSearchBartoolTip',
                    )}
                    checked={itemData ? itemData.showSearchBar : false}
                    onChange={showSearchBar =>
                      updateModifierGroup(
                        { showSearchBar, searchAllModifierItems: false },
                        SectionId.BASIC,
                        SubSections.ALL,
                      )
                    }
                  />
                </Grid.Item>

                <Grid.Item
                  mb={12}
                  xl={6}
                  csx={[
                    displayFlexEndColumnStyles,
                    {
                      height: '100%',
                      padding: '11px 0px',
                    },
                  ]}>
                  <Checkbox
                    label={t(
                      'menuScreen.modifierGroupDetails.basic.searchAllModifierItems',
                    )}
                    info={t(
                      'menuScreen.modifierGroupDetails.basic.searchAllModifierItemstoolTip',
                    )}
                    isDisabled={!itemData?.showSearchBar}
                    checked={itemData ? itemData.searchAllModifierItems : false}
                    onChange={searchAllModifierItems =>
                      updateModifierGroup(
                        { searchAllModifierItems },
                        SectionId.BASIC,
                        SubSections.ALL,
                      )
                    }
                  />
                </Grid.Item>
              </Grid>
            </Grid.Item>
            <Grid.Item
              mb={12}
              lg={6}
              xl={4}
              csx={{
                height: '100%',
              }}>
              <Box
                csx={{
                  flexGrow: 1,
                  gap: 20,
                  [MQ_MIN_LARGE]: {
                    padding: '0px 20px',
                  },
                }}>
                <Box
                  csx={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                    gap: 20,
                    flexDirection: 'column',
                    alignContent: 'flex-start',
                    [MQ_MIN_LARGE]: {
                      borderLeft: '1px solid',
                      borderColor: theme.colors.lighterGrey,
                      padding: '20px',
                    },
                    paddingBottom: '20px',
                    justifyContent: 'center',
                  }}>
                  <Checkbox
                    label={t(
                      'menuScreen.modifierGroupDetails.basic.multiselect',
                    )}
                    info={t(
                      'menuScreen.modifierGroupDetails.basic.multiselecttoolTip',
                    )}
                    checked={itemData ? itemData.multiselect : false}
                    onChange={multiselect => {
                      handleModifierItemsSelection({
                        multiselect,
                      });
                    }}
                    csx={{
                      alignSelf: 'flex-start',
                    }}
                  />
                  <Checkbox
                    label={t(
                      'menuScreen.modifierGroupDetails.basic.allowNoneSelection',
                    )}
                    info={t(
                      'menuScreen.modifierGroupDetails.basic.allowNoneSelectiontoolTip',
                    )}
                    checked={itemData ? itemData.allowNoneSelection : false}
                    onChange={allowNoneSelection =>
                      updateModifierGroup(
                        { allowNoneSelection },
                        SectionId.BASIC,
                        SubSections.ALL,
                      )
                    }
                    csx={{
                      alignSelf: 'flex-start',
                    }}
                  />
                  <Checkbox
                    label={t(
                      'menuScreen.modifierGroupDetails.basic.enableModTags',
                    )}
                    info={t(
                      'menuScreen.modifierGroupDetails.basic.enableModTagstoolTip',
                    )}
                    checked={itemData ? itemData.enableModTags : false}
                    onChange={enableModTags =>
                      updateModifierGroup(
                        { enableModTags },
                        SectionId.BASIC,
                        SubSections.ALL,
                      )
                    }
                    csx={{
                      alignSelf: 'flex-start',
                    }}
                  />
                  <Checkbox
                    label={t(
                      'menuScreen.modifierGroupDetails.basic.enableQuantityBar',
                    )}
                    info={t(
                      'menuScreen.modifierGroupDetails.basic.enableQuantityBartoolTip',
                    )}
                    checked={itemData ? itemData.enableQuantityBar : false}
                    onChange={enableQuantityBar =>
                      updateModifierGroup(
                        { enableQuantityBar },
                        SectionId.BASIC,
                        SubSections.ALL,
                      )
                    }
                    csx={{
                      alignSelf: 'flex-start',
                    }}
                  />
                </Box>
              </Box>
            </Grid.Item>
          </Grid>
          <Grid
            columnGap={20}
            csx={{
              alignItems: 'flex-start',
              borderBottom: '1px solid',
              borderColor: theme.colors.lighterGrey,
              paddingBottom: '15px',
              marginBottom: '15px',
            }}>
            <Grid.Item mb={12} csx={{ justifyContent: 'center' }}>
              <DetailsSubtitle
                textCsx={{
                  margin: 0,
                }}>
                {t('menuScreen.commonFields.basic.customizerSelector')}
              </DetailsSubtitle>
            </Grid.Item>
            <Grid.Item
              mb={12}
              sm={6}
              xl={3}
              csx={{ justifyContent: 'center', height: '100%' }}>
              <Checkbox
                label={t(
                  'menuScreen.modifierGroupDetails.basic.activatePizzaSectionSelector',
                )}
                info={t(
                  'menuScreen.modifierGroupDetails.basic.activatePizzaSectionSelectortoolTip',
                )}
                checked={
                  itemData ? itemData.activeCustomizerSectionSelector : false
                }
                onChange={activeCustomizerSectionSelector =>
                  updateModifierGroup(
                    { activeCustomizerSectionSelector },
                    SectionId.BASIC,
                    SubSections.ALL,
                  )
                }
                csx={{
                  alignSelf: 'flex-start',
                }}
              />
            </Grid.Item>
            <Grid.Item mb={12} sm={6} xl={3}>
              <Dropdown
                label={t(
                  'menuScreen.modifierGroupDetails.basic.customizerSlicing.title',
                )}
                placeholder={t(
                  'menuScreen.modifierGroupDetails.basic.customizerSlicing.title',
                )}
                info={t(
                  'menuScreen.modifierGroupDetails.basic.customizerSlicing.title',
                )}
                data={[
                  [
                    {
                      label: t(
                        'menuScreen.modifierGroupDetails.basic.customizerSlicing.quarters',
                      ),
                      value: 0,
                    },
                    {
                      label: t(
                        'menuScreen.modifierGroupDetails.basic.customizerSlicing.halves',
                      ),
                      value: 1,
                    },
                  ],
                ]}
                onChange={customizerSlicing => {
                  handleModifierItemsSelection({
                    customizerSlicing:
                      customizerSlicingModes[customizerSlicing],
                  });
                }}
                value={customizerSlicingModes.indexOf(
                  itemData
                    ? itemData.customizerSlicing
                    : CustomizerPartition.QUARTERS,
                )}
              />
            </Grid.Item>
          </Grid>

          <Grid columnGap={20} csx={{ alignItems: 'flex-start' }}>
            <Grid.Item
              mb={12}
              csx={{
                justifyContent: 'center',
              }}>
              <DetailsSubtitle
                textCsx={{
                  margin: 0,
                }}>
                {t('menuScreen.commonFields.basic.ticketDisplayOptions')}
              </DetailsSubtitle>
            </Grid.Item>
            <Grid.Item mb={12}>
              <Grid rowGap={20} columnGap={20}>
                <Grid.Item
                  mb={12}
                  xl={9}
                  csx={{
                    border: `1px solid ${theme.colors.lighterGrey}`,
                    borderRadius: '4px',
                    padding: '20px 10px',
                  }}>
                  <Grid rowGap={20} columnGap={20}>
                    <Grid.Item
                      mb={12}
                      sm={6}
                      lg={3}
                      csx={{
                        display: 'flex',
                        alignContent: 'center',
                        flexDirection: 'row',
                      }}>
                      <div className="infoButton">
                        <ToolTip
                          showHover={showHover}
                          content={t(
                            'menuScreen.modifierGroupDetails.basic.showModGroupTitleOntoolTip',
                          )}>
                          <div
                            style={{
                              height: '15px',
                            }}
                          />
                        </ToolTip>
                      </div>
                      <Typography
                        onMouseOver={handlePopUp}
                        onMouseOut={() => {
                          if (newTimeout) clearTimeout(newTimeout);
                          setShowHover(false);
                        }}>
                        {t(
                          'menuScreen.modifierGroupDetails.basic.showModGroupTitleOn',
                        )}
                      </Typography>
                    </Grid.Item>
                    <Grid.Item mb={12} sm={6} lg={3}>
                      <Checkbox
                        label={t(
                          'menuScreen.modifierGroupDetails.basic.showModGroupTitleOnRegisterTicket',
                        )}
                        info={t(
                          'menuScreen.modifierGroupDetails.basic.showModGroupTitleOnRegisterTickettoolTip',
                        )}
                        checked={
                          itemData
                            ? itemData.showModGroupTitleOnRegisterTicket
                            : false
                        }
                        onChange={showModGroupTitleOnRegisterTicket =>
                          updateModifierGroup(
                            { showModGroupTitleOnRegisterTicket },
                            SectionId.BASIC,
                            SubSections.ALL,
                          )
                        }
                      />
                    </Grid.Item>
                    <Grid.Item mb={12} sm={6} lg={3}>
                      <Checkbox
                        label={t(
                          'menuScreen.modifierGroupDetails.basic.showModGroupTitleOnKitchenTicket',
                        )}
                        info={t(
                          'menuScreen.modifierGroupDetails.basic.showModGroupTitleOnKitchenTickettoolTip',
                        )}
                        checked={
                          itemData
                            ? itemData.showModGroupTitleOnKitchenTicket
                            : false
                        }
                        onChange={showModGroupTitleOnKitchenTicket =>
                          updateModifierGroup(
                            { showModGroupTitleOnKitchenTicket },
                            SectionId.BASIC,
                            SubSections.ALL,
                          )
                        }
                      />
                    </Grid.Item>
                    <Grid.Item mb={12} sm={6} lg={3}>
                      <Checkbox
                        label={t(
                          'menuScreen.modifierGroupDetails.basic.guestCheck',
                        )}
                        info={t(
                          'menuScreen.modifierGroupDetails.basic.guestChecktoolTip',
                        )}
                        checked={itemData ? itemData.guestCheck : false}
                        onChange={guestCheck =>
                          updateModifierGroup(
                            { guestCheck },
                            SectionId.BASIC,
                            SubSections.ALL,
                          )
                        }
                      />
                    </Grid.Item>
                  </Grid>
                </Grid.Item>
                <Grid.Item
                  xl={3}
                  csx={[
                    displayFlexEndColumnStyles,
                    {
                      justifyContent: 'center',
                    },
                  ]}>
                  <Checkbox
                    label={t(
                      'menuScreen.modifierGroupDetails.basic.hidePriceIfFree',
                    )}
                    checked={itemData ? itemData.hidePriceIfFree : false}
                    onChange={hidePriceIfFree =>
                      updateModifierGroup(
                        { hidePriceIfFree },
                        SectionId.BASIC,
                        SubSections.ALL,
                      )
                    }
                    csx={{
                      width: 'max-content',
                    }}
                  />
                </Grid.Item>
              </Grid>
            </Grid.Item>
          </Grid>
        </>
      </AccordionSection>
    </>
  );
};

export default BasicSection;
