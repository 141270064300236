import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import Typography from '@app/components/common/Typography';
import { useTheme } from '@emotion/react';

const SpacesBeforeButton = ({
  onClick,
  count,
}: {
  onClick?: () => void;
  count: number;
}) => {
  const theme = useTheme();
  return (
    <Button
      disableHoverEffect
      onClick={onClick}
      csx={{
        padding: '3px',
        border: `1px solid ${theme.colors.darkBlue}`,
        backgroundColor: theme.colors.lighterBlue,
        borderRadius: '3px',
        width: '30px',
        height: '30px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <Box
        csx={{
          border: `1px dashed ${theme.colors.darkBlue}`,
          borderRadius: '3px',
          padding: '2px',
          backgroundColor: theme.colors.background,
          width: '100%',
          height: '100%',
        }}>
        <Typography align="center" color="darkBlue" variant="caption">
          {count || 0}
        </Typography>
      </Box>
    </Button>
  );
};

export default SpacesBeforeButton;
