import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import Box from '@app/components/common/Box';
import {
  actionCreatorsCustomers,
  addressInfoTableFactory,
  CUSTOMER_ADDRESS_INITIAL_STATE,
  CustomersSectionId,
  ICustomer,
  ICustomerAddress,
} from '@westondev/tableturn-core';
import { useDispatch, useSelector } from 'react-redux';

import {
  EAccordionSectionOptionType,
  IAccordionSection,
} from '@app/components/common/Accordion/AccordionSection/types';
import Checkbox from '@app/components/common/Checkbox';
import Button from '@app/components/common/Button';
import Icon from '@app/components/common/Icon';
import Table from '@app/components/common/Table';
import SectionLayer from '@app/components/LoggedIn/Menu/common/SectionLayer';
import OverflowText from '@app/components/LoggedIn/Menu/Items/ItemsList/ItemsTable/OverflowText';
import useScrollToNewRow from '@app/hooks/useScrollToNewRow';
import {
  makeSelectCustomersData,
  selectCurrentCustomer,
} from '@app/state/customers/customersSelectors';
import { bindActionCreators } from '@reduxjs/toolkit';

import { store } from '@app/state/store';
import cloneDeep from 'lodash/cloneDeep';
import max from 'lodash/max';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AddressInfoModal from './AddressInfoModal';
import { AddressActions } from './AddressInfoModal/types';
import { columnHelper, ROW_ID_PREFIX } from './types';

const CREATE_ACTION_DATA = {
  id: 0,
  action: AddressActions.CREATE,
  data: CUSTOMER_ADDRESS_INITIAL_STATE,
};

interface IAddressInfoSection extends IAccordionSection {}

const SECTION_ID = CustomersSectionId.ADDRESS;

const getMemoizedCustomerData = makeSelectCustomersData(SECTION_ID);

const AddressInfoSection = ({
  ...accordionSectionProps
}: IAddressInfoSection) => {
  const { t } = useTranslation();

  const { updateCustomer: setValue } = bindActionCreators(
    actionCreatorsCustomers,
    useDispatch(),
  );

  const { data } = useSelector(getMemoizedCustomerData);

  const [actionData, setActionData] = useState(CREATE_ACTION_DATA);
  const [isAddressModalActive, setIsAddressModalActive] = useState(false);

  const addressesArray = useMemo(
    () => addressInfoTableFactory(data?.addresses),
    [data?.addresses],
  );

  const closeAddressModal = () => setIsAddressModalActive(false);

  const handleScrollToNewRow = useScrollToNewRow();

  const onCreateAddress = () => {
    setActionData(CREATE_ACTION_DATA);
    setIsAddressModalActive(true);
  };

  const onEditAddress = (id: number) => {
    const currentAddresses = cloneDeep(
      (selectCurrentCustomer(store.getState()) as ICustomer).addresses,
    );
    const address = currentAddresses[id];
    setActionData({ id, action: AddressActions.EDIT, data: address });
    setIsAddressModalActive(true);
  };

  const onNewAddress = (newAddress: ICustomerAddress) => {
    const nextId =
      (max(Object.keys(data?.addresses || {}).map(key => Number(key)) || []) ||
        0) + 1;

    const nextIndex = Object.keys(data?.addresses || {}).length;
    setValue(
      {
        addresses: {
          ...data?.addresses,
          [nextId]: {
            ...newAddress,
            id: nextId,
            addressId: nextId,
          },
        },
      },
      SECTION_ID,
    );
    handleScrollToNewRow(`${ROW_ID_PREFIX}${nextIndex}`);
  };

  const onDeleteAddress = (id: number) => {
    const currentAddresses = cloneDeep(
      (selectCurrentCustomer(store.getState()) as ICustomer).addresses,
    );

    delete currentAddresses[id];

    setValue(
      {
        addresses: currentAddresses,
      },
      SECTION_ID,
    );
  };
  
  const setDefaultAddress = (newDefaultAddressId: number) => {
    const currentCustomerAddresses = cloneDeep(
      (selectCurrentCustomer(store.getState()) as ICustomer)?.addresses || {},
    );
    Object.keys(currentCustomerAddresses)
      .forEach(customerAddressId => {
        const customerAddressIdNumber = Number(customerAddressId);
        currentCustomerAddresses[customerAddressIdNumber].isDefault =
          newDefaultAddressId === customerAddressIdNumber;
      });
    setValue({ addresses: currentCustomerAddresses }, SECTION_ID);
  };

  const COLUMN_VALUES = useMemo(
    () => [
      columnHelper.accessor('isDefault', {
        header: t('customersScreen.addressInfo.table.isDefault'),
        cell: info => (
          <Box
            csx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Checkbox
              checked={info.getValue()}
              onChange={() => setDefaultAddress(info.row.original.id)}
            />
          </Box>
        ),
        size: 60,
      }),
      columnHelper.accessor('addressType', {
        header: t('customersScreen.addressInfo.table.addressType'),
        cell: info => (
          <OverflowText
            rows={1}
            text={t(
              `customersScreen.addressInfo.addressType.${info.getValue()}`,
            )}
          />
        ),
        size: 150,
      }),
      columnHelper.accessor('streetLine1', {
        header: t('customersScreen.addressInfo.table.streetLine1'),
        cell: info => <OverflowText rows={1} text={info.getValue()} />,
        minSize: 150,
      }),
      columnHelper.accessor('streetLine2', {
        header: t('customersScreen.addressInfo.table.streetLine2'),
        cell: info => <OverflowText rows={1} text={info.getValue() || '-'} />,
        minSize: 150,
      }),
      columnHelper.accessor('city', {
        header: t('customersScreen.addressInfo.table.city'),
        cell: info => <OverflowText rows={1} text={info.getValue()} />,
        size: 100,
      }),
      columnHelper.accessor('state', {
        header: t('customersScreen.addressInfo.table.state'),
        cell: info => <OverflowText rows={1} text={info.getValue()} />,
        size: 100,
      }),
      columnHelper.accessor('postalCode', {
        header: t('customersScreen.addressInfo.table.postalCode'),
        cell: info => <OverflowText rows={1} text={info.getValue()} />,
        size: 100,
      }),
      columnHelper.accessor('addressName', {
        header: t('customersScreen.addressInfo.table.addressName'),
        cell: info => <OverflowText rows={1} text={info.getValue() || '-'} />,
        minSize: 150,
      }),
      columnHelper.accessor('unitNumber', {
        header: t('customersScreen.addressInfo.table.unitNumber'),
        cell: info => (
          <OverflowText rows={1} text={`${info.getValue() || '-'}`} />
        ),
        size: 100,
      }),
      columnHelper.accessor('actions', {
        header: t('settingsModule.devices_licenses.licensesTable.actions'),
        cell: info => (
          <Box
            csx={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
            }}>
            <Button
              csx={{ width: '50px' }}
              icon={<Icon name="MdEdit" />}
              onClick={() => onEditAddress(info.row.original.id)}
            />
            <Button
              variant="danger"
              csx={{ width: '50px' }}
              icon={<Icon name="FaTrashAlt" />}
              onClick={() => onDeleteAddress(info.row.original.id)}
            />
          </Box>
        ),
        size: 120,
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t],
  );

  const handleAddressEdit = (newAddress: ICustomerAddress) => {
    closeAddressModal();
    if (actionData.action === AddressActions.CREATE) {
      onNewAddress(newAddress);
    } else {
      const currentAddresses = cloneDeep(
        (selectCurrentCustomer(store.getState()) as ICustomer).addresses,
      );

      currentAddresses[actionData.id] = newAddress;

      setValue(
        {
          addresses: currentAddresses,
        },
        SECTION_ID,
      );
    }
  };

  return (
    <>
      <AddressInfoModal
        onSave={handleAddressEdit}
        action={actionData.action}
        isActive={isAddressModalActive}
        value={actionData.data}
        onModalClose={closeAddressModal}
      />
      <AccordionSection
        title={t('customersScreen.addressInfo.title')}
        optionType={EAccordionSectionOptionType.ACTION_BUTTON}
        options={[
          {
            text: '',
            handler: onCreateAddress,
            icon: false,
          },
        ]}
        titleTextContainerCsx={{ minWidth: '150px' }}
        {...accordionSectionProps}>
        {addressesArray.length > 0 ? (
          <Box
            csx={{
              height: '100%',
            }}>
            <Table
              scrollEnabled
              nestedScrollEnabled
              data={addressesArray}
              columns={COLUMN_VALUES}
              cellCsx={{
                height: '70px',
                paddingTop: '5px',
                paddingBottom: '5px',
              }}
              rowIdPrefix={ROW_ID_PREFIX}
            />
          </Box>
        ) : (
          <SectionLayer
            title={t('customersScreen.addressInfo.emptyDataText')}
            csx={{
              justifyContent: 'center',
              paddingTop: 20,
              paddingBottom: 20,
            }}
          />
        )}
      </AccordionSection>
    </>
  );
};

export default AddressInfoSection;
