import { Theme, css } from '@emotion/react';
import { BUTTON_BORDER_RADIUS, BUTTON_HEIGHT } from '../../Button/styles';

export const listItemStyles = (theme: Theme) =>
  css({
    display: 'flex',
    alignItems: 'center',
    userSelect: 'none',
    height: '50px',
    borderRadius: BUTTON_BORDER_RADIUS,
    zIndex: 10,
    transition: 'all 0.1s ease-in',
    justifyContent: 'space-between',
    width: '100%',
    '.details': {
      paddingLeft: '15px',
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      maxHeight: BUTTON_HEIGHT,
      textDecoration: 'none',
    },
    '.icon': {
      display: 'flex',
      marginRight: '15px',
      justifyContent: 'center',
      alignItems: 'center',
      minWidth: '5px',
    },
    '.iconRight': {
      display: 'flex',
      justifyContent: 'center',
      paddingRight: '5px',
      width: '50px',
    },
    p: {
      color: theme.colors.darkGrey,
      fontWeight: theme.fontWeights.medium,
    },
    svg: {
      fill: theme.colors.darkGrey,
    },
  });

export const listItemClickableStyles = (theme: Theme) =>
  css({
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.colors.lighterBlue,
      svg: {
        fill: theme.colors.semanticBlue,
      },
      p: {
        color: theme.colors.semanticBlue,
      },
      '&.active': { ...listItemActiveStyles(theme) },
      '&.activeSubItem': { ...listSubItemStyles(theme) },
    },
  });

export const listItemActiveStyles = (theme: Theme) =>
  css({
    backgroundColor: theme.colors.lighterBlue,
    border: `1px solid ${theme.colors.darkBlue}`,
    svg: {
      fill: theme.colors.darkBlue,
    },
    p: {
      color: theme.colors.darkBlue,
    },
  });

export const listSubItemStyles = (theme: Theme) =>
  css({
    backgroundColor: theme.colors.semanticBlue,
    svg: {
      fill: theme.colors.textWhite,
    },
    p: {
      color: theme.colors.textWhite,
    },
  });
