import useOutsideClick from '@app/hooks/useOutsideClick';
import useRootSelector from '@app/hooks/useRootSelector';
import { actionCreatorsApp } from '@app/state';
import { selectIsMainPortal } from '@app/state/selectors/appSelectors';
import { css } from '@emotion/react';
import { bindActionCreators } from '@reduxjs/toolkit';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Box from '../Box';
import Typography from '../Typography';
import { toolTipContentStyles, tooltipContainerStyles } from './styles';
import { IToolTip, TDirectionTypes } from './types';

const ToolTip = ({
  content = 'Tooltip',
  direction = 'bottom-right',
  mode = 'hover',
  children,
  dismissOnContentClick = true,
  isDisabled = false,
  onClickOutside,
  moveOnChildrenChange = false,
  isToolTipActive = false,
  triggerCsx,
  tooltipCsx,
  showHover,
}: IToolTip) => {
  // Redux
  const updatePortals = bindActionCreators(
    actionCreatorsApp.updatePortals,
    useDispatch(),
  );

  // Local state
  const childrenRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLDivElement>(null);
  const [childrenRect, setChildrenRect] = useState<DOMRect | undefined>();
  const [showTooltip, setShowTooltip] = useState(false);

  const isMainPortal = useRootSelector(selectIsMainPortal);

  useOutsideClick([childrenRef, titleRef], isMainPortal, value => {
    setShowTooltip(value);
    onClickOutside && onClickOutside();
    if (!value) handleRemovePortal();
  });

  useEffect(() => {
    setShowTooltip(isToolTipActive);
  }, [isToolTipActive]);

  const getTooltipStyle = () => {
    if (childrenRect && titleRef.current) {
      const titleRect = titleRef.current.getBoundingClientRect();
      const titleYPosition =
        window.scrollY + childrenRect.y + childrenRect.height;

      const isOutsideVerticalScreen =
        titleYPosition + titleRect.height > window.scrollY + window.innerHeight;

      const directionAndPosition = direction.split('-');
      const currentDirection = isOutsideVerticalScreen
        ? 'top'
        : (directionAndPosition[0] as TDirectionTypes);

      switch (currentDirection) {
        case 'top': {
          const titlePosition =
            childrenRect.x - titleRect.width / 2 + childrenRect.width / 2;
          const isOutsideScreen =
            titlePosition + titleRect.width > window.innerWidth;

          if (directionAndPosition[1] === 'left') {
            return {
              transform: `translate(${
                childrenRect.x - titleRect.width + childrenRect.width + 2
              }px, ${
                childrenRect.top + window.scrollY - titleRect.height - 10
              }px)`,
            };
          }

          if (directionAndPosition[1] === 'right') {
            const isOutsideScreenRight =
              titleRect.width + childrenRect.x - window.innerWidth;

            const isOutsideScreenTop =
              childrenRect.top + window.scrollY - titleRect.height - 10;

            return {
              transform: `translate(${
                childrenRect.x -
                (isOutsideScreenRight > 0 ? isOutsideScreenRight : 0)
              }px, ${
                isOutsideScreenTop < 0
                  ? 0
                  : childrenRect.top + window.scrollY - titleRect.height - 10
              }px)`,
            };
          }

          return {
            transform: `translate(${
              isOutsideScreen
                ? window.innerWidth - titleRect.width - 10
                : titlePosition
            }px, ${
              childrenRect.top + window.scrollY - titleRect.height - 10
            }px)`,
          };
        }

        case 'bottom': {
          const titlePosition =
            childrenRect.x - titleRect.width / 2 + childrenRect.width / 2;
          const isOutsideScreen =
            titlePosition + titleRect.width > window.innerWidth;

          if (directionAndPosition[1] === 'left') {
            return {
              transform: `translate(${
                childrenRect.x - titleRect.width + childrenRect.width + 2
              }px, ${
                childrenRect.top + window.scrollY + childrenRect.height + 10
              }px)`,
            };
          }

          if (directionAndPosition[1] === 'right') {
            const isOutsideScreenRight =
              titleRect.width + childrenRect.x - window.innerWidth;

            return {
              transform: `translate(${
                childrenRect.x -
                (isOutsideScreenRight > 0 ? isOutsideScreenRight : 0)
              }px, ${
                childrenRect.top + window.scrollY + childrenRect.height + 10
              }px)`,
            };
          }

          return {
            transform: `translate(${
              isOutsideScreen
                ? window.innerWidth - titleRect.width - 10
                : titlePosition
            }px, ${
              childrenRect.top + window.scrollY + childrenRect.height + 10
            }px)`,
          };
        }
        case 'left':
          return {
            transform: `translate(${childrenRect.x - titleRect.width - 10}px, ${
              childrenRect.top +
              window.scrollY +
              childrenRect.height / 2 -
              titleRect.height / 2
            }px)`,
          };
        case 'right':
          return {
            transform: `translate(${
              childrenRect.x + childrenRect.width + 10
            }px, ${
              childrenRect.top +
              window.scrollY +
              childrenRect.height / 2 -
              titleRect.height / 2
            }px)`,
          };
        default:
          return {};
      }
    }
  };

  const getTriangleStyle = () => {
    if (childrenRect && titleRef.current) {
      const titleRect = titleRef.current.getBoundingClientRect();
      const titleYPosition =
        window.scrollY + childrenRect.y + childrenRect.height;
      const isOutsideVerticalScreen =
        titleYPosition + titleRect.height > window.scrollY + window.innerHeight;

      const directionAndPosition = direction.split('-');
      const currentDirection = isOutsideVerticalScreen
        ? 'top'
        : (directionAndPosition[0] as TDirectionTypes);

      switch (currentDirection) {
        case 'top': {
          const titlePosition =
            childrenRect.x - titleRect.width / 2 + childrenRect.width / 2;
          const isOutsideScreen =
            titlePosition + titleRect.width > window.innerWidth;
          const defaultTrianglePosition = titleRect.width / 2;
          const screenDifference =
            titlePosition + titleRect.width - window.innerWidth;
          const trianglePosition = isOutsideScreen
            ? screenDifference + defaultTrianglePosition + 2
            : defaultTrianglePosition;

          if (directionAndPosition[1] === 'left') {
            return {
              top: '100%',
              right: childrenRect.width / 2 - 8,
              marginLeft: 0,
            };
          }

          return {
            top: '100%',
            left: isOutsideScreen ? trianglePosition : defaultTrianglePosition,
            marginLeft: isOutsideScreen ? 0 : '-8px',
          };
        }

        case 'bottom': {
          const titlePosition =
            childrenRect.x - titleRect.width / 2 + childrenRect.width / 2;
          const isOutsideScreen =
            titlePosition + titleRect.width > window.innerWidth;
          const defaultTrianglePosition = titleRect.width / 2;
          const screenDifference =
            titlePosition + titleRect.width - window.innerWidth;
          const trianglePosition = isOutsideScreen
            ? screenDifference + defaultTrianglePosition + 2
            : defaultTrianglePosition;

          if (directionAndPosition[1] === 'left') {
            return {
              bottom: '100%',
              right: childrenRect.width / 2 - 8,
              transform: 'rotateZ(180deg) ',
              marginLeft: 0,
            };
          }

          return {
            bottom: '100%',
            left: isOutsideScreen ? trianglePosition : defaultTrianglePosition,
            transform: 'rotateZ(180deg) ',
            marginLeft: isOutsideScreen ? 0 : '-8px',
          };
        }

        case 'left':
          return {
            left: '100%',
            transform: 'rotateZ(-90deg)',
            marginLeft: '-4px',
            bottom: `${titleRect.height / 2 - 6}px`,
          };
        case 'right':
          return {
            right: '100%',
            transform: 'rotateZ(90deg)',
            marginRight: '-4px',
            bottom: `${titleRect.height / 2 - 6}px`,
          };
        default:
          return {};
      }
    }
  };

  useLayoutEffect(() => {
    handleResize();
  }, [showTooltip]);

  useEffect(() => {
    const handleWheel = (e: WheelEvent) => {
      const target = e.target as HTMLElement;
      if (!target.closest('#portal-dropdown')) {
        if (childrenRef.current && showTooltip) {
          setShowTooltip(false);
          handleRemovePortal();
        }
      }
    };
    window.addEventListener('resize', handleResize);
    window.addEventListener('wheel', handleWheel);
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('wheel', handleWheel);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showTooltip]);

  useEffect(() => {
    if (moveOnChildrenChange) handleResize();
  }, [children, moveOnChildrenChange]);

  useEffect(() => {
    if (mode === 'always-show') setShowTooltip(true);
  }, [mode]);

  useEffect(() => {
    if (mode !== 'hover') return;

    if (showHover) {
      setShowTooltip(true);
      updatePortals('tooltip', 'initialize');
    } else {
      setShowTooltip(false);
      updatePortals('tooltip', 'remove');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showHover, mode]);

  const handleResize = () => {
    if (childrenRef.current) {
      if (!childrenRef.current.firstElementChild) return;

      const initialChildrenRect =
        childrenRef.current.firstElementChild.getBoundingClientRect();

      setChildrenRect(initialChildrenRect);
    }
  };

  const handleOnClick = () => {
    if (mode === 'click' && !isDisabled) {
      setShowTooltip(!showTooltip);
      if (!showTooltip) handleInitializePortal();
      else handleRemovePortal();
      if (showTooltip && onClickOutside) onClickOutside();
    }
  };

  const handleRemovePortal = () => {
    updatePortals('tooltip', 'remove');
  };

  const handleInitializePortal = () => {
    updatePortals('tooltip', 'initialize');
  };

  return (
    <>
      <div
        ref={childrenRef}
        css={[tooltipContainerStyles, { left: childrenRect?.x }, triggerCsx]}
        onClick={handleOnClick}>
        {children}
      </div>
      {content &&
        showTooltip &&
        createPortal(
          <Box
            id="portal-tooltip"
            ref={titleRef}
            csx={[
              toolTipContentStyles,
              getTooltipStyle(),
              {
                display: showTooltip ? 'block' : 'none',
              },
              tooltipCsx,
            ]}
            onClick={() => dismissOnContentClick && setShowTooltip(false)}>
            {typeof content === 'string' ? (
              <Typography variant="body" csx={{ userSelect: 'none' }}>
                <Trans components={{ 1: <br /> }}>{content}</Trans>
              </Typography>
            ) : (
              content
            )}
            {mode !== 'hover' && (
              <div
                css={theme =>
                  css({
                    position: 'absolute',
                    borderLeft: '8px solid transparent',
                    borderRight: '8px solid transparent',
                    borderTop: `8px solid ${theme.colors.white}`,
                    ...getTriangleStyle(),
                  })
                }
              />
            )}
          </Box>,
          document.body,
        )}
    </>
  );
};

export default ToolTip;
