import ActionButtons from '@app/components/common/ActionButtons';
import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import Card from '@app/components/common/Card';
import { EItemTypes } from '@app/components/common/Card/ItemButton/types';
import Checkbox from '@app/components/common/Checkbox';
import Dropdown from '@app/components/common/Dropdown';
import { IDropdownItem } from '@app/components/common/Dropdown/types';
import Grid from '@app/components/common/Grid';
import Icon from '@app/components/common/Icon';
import Input from '@app/components/common/Input';
import Modal from '@app/components/common/Modal';
import MoneyInput from '@app/components/common/MoneyInput';
import SelectionModal from '@app/components/common/SelectionModal';
import { IItemSelection } from '@app/components/common/SelectionModal/GenericSelectionModal/types';
import Typography from '@app/components/common/Typography';
import AssignedElements from '@app/components/LoggedIn/Menu/AssignedElements';
import { quantityModeConversion } from '@app/components/LoggedIn/Menu/Items/ItemDetails/ModifierGroupsSection/ModifierGroup/Content/types';
import ShowAllPricingInputs from '@app/components/LoggedIn/Menu/Items/ItemDetails/ModifierGroupsSection/ModifierGroup/ShowAllPricing/ShowAllPricingInputs';
import { openNewTabWithOrgData } from '@app/helpers/navigation';
import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';
import useRefreshMenuWeb from '@app/hooks/useRefreshMenuWeb';
import { actionCreatorsMenuWeb } from '@app/state';
import { makeSelectItemData } from '@app/state/menu/menuSelectors';
import { areSettingsMasterModeSelector } from '@app/state/selectors/settingsSelectors';
import { RootState, store } from '@app/state/store';
import { DragEndEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { TCsx, useTheme } from '@emotion/react';
import {
  actionCreatorsMenu,
  BreadCrumbAction,
  BreadCrumbScreens,
  filter,
  formatMoney,
  getAdditionalAmountPrice,
  getIncludedAmountPrice,
  handleDecrementModifierItem,
  handleIncrementModifierItemQuantity,
  handleSetDefaultSelected,
  IMenuStore,
  IModifierGroupModifierItem,
  IModifierGroupPricingVersion,
  IModifierGroups,
  IModifierItemOfModifierGroup,
  IModifierItemPricingVersion,
  IModifierItemWithFields,
  QuantityModes,
  ReturnTypes,
  SectionId,
  SortOptions,
  SubSections,
  useModifierItemPriceCalc,
  useSort,
} from '@westondev/tableturn-core';
import { cloneDeep, isNumber, mapValues } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import DraggableContainer from '../../../../common/DragAndDrop/DraggableContainer';
import AssignedPricingVersions from '../AssignedPricingVersions';
import LinesBeforeButton from './LinesBeforeButton';
import SpacesBeforeButton from './SpacesBeforeButton';
import { IPosModifierItemDelete } from './types';

const SECTION_ID = SectionId.ASSOCIATION;

interface IAssignedItemsCombosSection extends WithTranslation {
  csx?: TCsx;
}

export interface IItemCardModifierGroup {
  menuTypeId: number;
}

const getMemoizedItemData = makeSelectItemData('modifierGroups', SECTION_ID);

const modifierGroupModifierItemsFactory = (
  modifierGroupPricingVersions: {
    [key: number]: IModifierGroupPricingVersion;
  },
  modifierItems: {
    [key: number]: IModifierItemOfModifierGroup;
  },
  modifierGroupPricingVersionId: number,
  currentMenu: IMenuStore,
) => {
  const menuModifierItems = currentMenu.modifierItems;

  const modItemPricingVersions =
    modifierGroupPricingVersions[modifierGroupPricingVersionId]
      ?.modItemPricingVersions;

  if (modifierGroupPricingVersionId >= 0 && modItemPricingVersions) {
    const formattedModifierItems: {
      id: number;
      title: string;
      active: boolean;
      pricingVersions: IDropdownItem[];
      selectedPricingVersion: number;
      sortPriority: number;
      spacesBefore: number;
      linesBefore: number;
    }[] = [];

    Object.entries(modItemPricingVersions).forEach(([key, value]) => {
      const modifierItem = menuModifierItems[Number(key)];
      const modifierItemPricingVersions =
        modifierItem.modifierItemPricingVersions;

      const pricingVersion = modifierItemPricingVersions[
        value
      ] as IModifierItemPricingVersion;

      const pricingVersions: IDropdownItem[] = Object.values(
        modifierItemPricingVersions,
      ).map(version => ({
        value: version.modifierItemPricingVersionId,
        label: version.name,
      }));
      const modifierItemOfModifierGroup = modifierItems[Number(key)];

      if (!modifierItemOfModifierGroup) return;

      formattedModifierItems.push({
        id: modifierItemOfModifierGroup.modifierItemId,
        title: modifierItemOfModifierGroup.posName,
        active: modifierItemOfModifierGroup.active,
        pricingVersions,
        selectedPricingVersion: pricingVersion.modifierItemPricingVersionId,
        sortPriority: modifierItemOfModifierGroup.sortPriority,
        spacesBefore: modifierItemOfModifierGroup.spacesBefore,
        linesBefore: modifierItemOfModifierGroup.linesBefore,
      });
    });

    formattedModifierItems
      .filter(value => value.id !== -1)
      .sort((a, b) => a.sortPriority - b.sortPriority);

    return formattedModifierItems;
  }

  return [];
};

function appendPricesToModifierItems(
  modifierItemsObject: {
    [key: number]: IModifierItemOfModifierGroup;
  },
  modifierGroupPricingVersionObj: { [key: number]: number },
  globalState?: RootState,
): { [key: number]: IModifierItemWithFields } {
  let state: RootState;

  if (!globalState) state = store.getState();
  else state = globalState;

  const menuModifierItems = state.menu.selectedMode.currentMenu.modifierItems;

  return mapValues(modifierItemsObject, (modifierItem, modifierItemId) => {
    const modifierItemPricingVersionId =
      modifierGroupPricingVersionObj[modifierItem.modifierItemId];

    const currentPricingVersion =
      menuModifierItems[Number(modifierItemId)].modifierItemPricingVersions[
        modifierItemPricingVersionId
      ];

    return {
      ...modifierItem,
      isSelected: modifierItem.isSelected,
      modifierItemId: Number(modifierItemId),
      defaultQuantitySelected: modifierItem?.defaultQuantitySelected || 0,
      defaultAmountSelected: modifierItem.defaultAmountSelected,
      name: modifierItem.posName,
      defaultSelectionPrice: modifierItem?.defaultSelectionPrice || 0,
      includedMasterPrice: currentPricingVersion?.includedMasterPrice || 0,
      additionalMasterPrice: currentPricingVersion?.additionalMasterPrice || 0,
      quarterPricePercent: currentPricingVersion?.quarterPricePercent || 0,
      halfPricePercent: currentPricingVersion?.halfPricePercent || 0,
      includedLightPrice: currentPricingVersion?.includedLightPrice || 0,
      includedRegularPrice: currentPricingVersion?.includedRegularPrice || 0,
      includedExtraPrice: currentPricingVersion?.includedExtraPrice || 0,
      additionalLightPrice: currentPricingVersion?.additionalLightPrice || 0,
      additionalRegularPrice:
        currentPricingVersion?.additionalRegularPrice || 0,
      additionalExtraPrice: currentPricingVersion?.additionalExtraPrice || 0,
    };
  }) as { [key: number]: IModifierItemWithFields };
}

const ModifierItemsSection = ({ csx, t }: IAssignedItemsCombosSection) => {
  // Redux
  const { itemData: modifierGroupData } = useSelector(getMemoizedItemData);

  const [selectedPricingVersionId, setSelectedPricingVersionId] =
    useState<number>(-1);

  const isMasterMode = useSelector(areSettingsMasterModeSelector);

  const { currentMenu } = useSelector(
    (state: RootState) => state.menu.selectedMode,
  );

  const { modifierItems: menuModifierItems } = useSelector(
    (state: RootState) => state.menu.selectedMode.currentMenu,
  );

  const {
    updateModifierGroup: setValue,
    getDataFromChangeData,
    associateModifierItemToModifierGroup,
    updateSelectionPriceForModifierItemsOfModifierGroup,
    updateLastModifierItemsOrder,
    removeModifierItemFromModifierGroup,
  } = bindActionCreators(actionCreatorsMenu, useDispatch());

  const { addBreadcrumbLocationWeb } = bindActionCreators(
    actionCreatorsMenuWeb,
    useDispatch(),
  );

  const { checkForChangesAndNavigateWeb } = bindActionCreators(
    actionCreatorsMenuWeb,
    useDispatch(),
  );

  // Local state
  const navigate = useNavigateWithOrg();
  const onRefresh = useRefreshMenuWeb();

  const theme = useTheme();

  const [
    showAddExistingModifierItemModal,
    setShowAddExistingModifierItemModal,
  ] = useState(false);
  const [showAllPricing, setShowAllPricing] = useState(false);
  const [editMaximums, setEditMaximums] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const onSort = useSort('title');

  const [showModal, setShowModal] = useState(false);
  const [selectedModifierItemId, setSelectedModifierItemId] = useState(0);
  const [blankSpaces, setBlankSpaces] = useState(0);
  const [lineBreaks, setLineBreaks] = useState(0);
  const [selectedModifierItemField, setSelectedModifierItemField] = useState<
    'spacesBefore' | 'linesBefore' | ''
  >('');

  const [editablePricingVersionData, setEditablePricingVersion] = useState<{
    data: { name: string; id: number } | null;
    mode: 'edit' | 'add';
  }>({
    data: null,
    mode: 'edit',
  });

  const [activeId, setActiveId] = useState<string | null>(null);

  const { modifierGroupPricingVersions, modifierItems } = modifierGroupData;

  const modifierItemsData = useMemo(() => {
    return modifierGroupModifierItemsFactory(
      modifierGroupPricingVersions,
      modifierItems,
      selectedPricingVersionId,
      currentMenu,
    ).sort((a, b) => a.sortPriority - b.sortPriority);
  }, [
    modifierGroupPricingVersions,
    modifierItems,
    selectedPricingVersionId,
    currentMenu,
  ]);

  const handleUpdatePricingVersion = useCallback(
    (newPricingVersionId: number, modifierItemId: number) => {
      const newModifierGroupPricingVersions = cloneDeep(
        modifierGroupPricingVersions,
      );

      const modItemPricingVersions =
        newModifierGroupPricingVersions[selectedPricingVersionId]
          ?.modItemPricingVersions;

      setValue(
        {
          modItemPricingVersions: {
            ...modItemPricingVersions,
            [modifierItemId]: newPricingVersionId,
          },
        },
        SECTION_ID,
        SubSections.PRICING_VERSION,
        selectedPricingVersionId,
      );
    },
    [modifierGroupPricingVersions, selectedPricingVersionId, setValue],
  );

  const handleAddNewModifierItem = (text: string) => {
    checkForChangesAndNavigateWeb(
      () =>
        addBreadcrumbLocationWeb({
          action: BreadCrumbAction.ADD,
          text,
          screen: BreadCrumbScreens.MODIFIER_ITEMS_DETAILS,
          onPress: () => {
            navigate('/menu/modifiers/modifier-items/add', {
              state: {
                modifierGroupId: modifierGroupData.modifierGroupId,
              },
            });
          },
          pathURL: '/menu/modifiers/modifier-items/add',
        }),
      onRefresh,
    );
  };

  const handleAssociateModifierItem = (
    modifierItemsSelected: IItemSelection[],
  ) => {
    associateModifierItemToModifierGroup(
      modifierItemsSelected,
      modifierItems,
      SECTION_ID,
    );
    setShowAddExistingModifierItemModal(false);
  };

  const extendedModifierItems = useMemo(
    () =>
      appendPricesToModifierItems(
        modifierGroupData.modifierItems,
        modifierGroupData.modifierGroupPricingVersions[
          modifierGroupData.defaultModifierGroupPricingVersionId
        ]
          ? modifierGroupData.modifierGroupPricingVersions[
              modifierGroupData.defaultModifierGroupPricingVersionId
            ]?.modItemPricingVersions
          : Object.keys(modifierGroupData.modifierGroupPricingVersions).length >
            0
          ? modifierGroupData.modifierGroupPricingVersions[
              Number(
                Object.keys(modifierGroupData.modifierGroupPricingVersions)[0],
              )
            ]?.modItemPricingVersions
          : {},
      ),
    [modifierGroupData],
  );

  const {
    additionalModifierItemsCount,
    includedModifierItemsCount,
    selectedModifierItemElements,
    totalDifferenceObj,
    totalPrice,
  } = useModifierItemPriceCalc(
    extendedModifierItems,
    modifierGroupData.includedModifierItems,
    modifierGroupData.quantityMode,
    modifierGroupData.priceCalcMode,
  );

  useEffect(() => {
    if (modifierGroupData.defaultSelectionPrice !== totalPrice) {
      setValue({ defaultSelectionPrice: totalPrice }, SECTION_ID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPrice, modifierGroupData.modifierGroupId]);

  const handleHideModifierItem = (
    isSwitchActive: boolean,
    modifierItemId: number,
  ) => {
    setValue(
      {
        active: isSwitchActive,
      },
      SECTION_ID,
      SubSections.MODIFIER_ITEM,
      modifierItemId,
    );
  };

  const onIncrement = useCallback(
    (modifierItemId: number) => {
      const modifierGroup = getDataFromChangeData();
      setValue(
        handleIncrementModifierItemQuantity(
          Number(modifierItemId),
          modifierGroup as unknown as IModifierGroups,
        ).data,
        SECTION_ID,
        SubSections.MODIFIER_ITEM,
        modifierItemId,
      );
    },
    [getDataFromChangeData, setValue],
  );

  const onDecrement = useCallback(
    (modifierItemId: number) => {
      const modifierGroup = getDataFromChangeData();
      setValue(
        handleDecrementModifierItem(
          Number(modifierItemId),
          modifierGroup as unknown as IModifierGroups,
        ).data,
        SECTION_ID,
        SubSections.MODIFIER_ITEM,
        modifierItemId,
      );
    },
    [getDataFromChangeData, setValue],
  );

  const handleOnSortAscDesc = (
    sortOption: SortOptions | null,
    isEditModeEnabled: boolean,
  ) => {
    if (isEditModeEnabled) {
      const newModifierItemsOrder = filter(
        modifierItemsData,
        {},
        sortOption,
        onSort,
      );

      const newSortPriorities = newModifierItemsOrder
        .map(modifierItem => modifierItem.sortPriority)
        .sort((a, b) => a - b);

      const newCardsPositions = Object.assign(
        {},
        ...newModifierItemsOrder.map((child, index) => ({
          [child.id]: {
            index,
            sortPriority: newSortPriorities[index],
          },
        })),
      );
      updateLastModifierItemsOrder(newCardsPositions);
    }
  };

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;

      if (active.id !== over?.id) {
        const oldIndex = modifierItemsData.findIndex(
          ({ id }) => id === Number(active.id),
        );
        const newIndex = modifierItemsData.findIndex(
          ({ id }) => id === Number(over?.id),
        );

        const newModifierItemsOrder = arrayMove(
          modifierItemsData,
          oldIndex,
          newIndex,
        );

        const newSortPriorities = newModifierItemsOrder
          .map(modifierItem => modifierItem.sortPriority)
          .sort((a, b) => a - b);

        const newCardsPositions = Object.assign(
          {},
          ...newModifierItemsOrder.map((child, index) => ({
            [child.id]: {
              index,
              sortPriority: newSortPriorities[index],
            },
          })),
        );
        updateLastModifierItemsOrder(newCardsPositions);
      }

      setActiveId(null);
    },
    [setActiveId, modifierItemsData, updateLastModifierItemsOrder],
  );

  const onChange = (modifierItemId: number, isSelected: boolean) => {
    const data = handleSetDefaultSelected(modifierItemId, isSelected, {
      multiselect: modifierGroupData.multiselect,
      modifierItems: modifierItems,
      quantityMode: modifierGroupData.quantityMode,
    });

    if (data?.type === ReturnTypes.modifierItemData) {
      setValue(
        data.data as IModifierGroupModifierItem,
        SECTION_ID,
        SubSections.MODIFIER_ITEM,
        modifierItemId,
      );
    } else if (data?.type === ReturnTypes.modifierGroupData) {
      setValue(data.data, SECTION_ID);
    }
  };

  const onClose = () => {
    setShowModal(false);
  };

  const onDone = () => {
    setValue(
      {
        linesBefore: lineBreaks,
        spacesBefore: blankSpaces,
      },
      SECTION_ID,
      SubSections.MODIFIER_ITEM,
      selectedModifierItemId,
    );
    setShowModal(false);
  };

  return (
    <>
      <Modal
        fullHeight
        displayAfterNavBar={false}
        isActive={showModal}
        title={t(
          'menuScreen.modifierGroupDetails.associations.modifierItemsPricing.breaksSpacesModal.title',
        )}
        onModalClose={onClose}
        size={1000}
        footer={
          <>
            <Button
              csx={{ width: '80px' }}
              variant="secondary"
              onClick={onClose}>
              {t('commonButtons.cancel')}
            </Button>
            <Button csx={{ width: '80px' }} variant="primary" onClick={onDone}>
              {t('commonButtons.continue')}
            </Button>
          </>
        }>
        <Grid
          rowGap={30}
          csx={{
            padding: '20px 15px',
          }}>
          <Grid.Item mb={12}>
            <Input
              type="text"
              label={t(
                'menuScreen.modifierGroupDetails.associations.modifierItemsPricing.breaksSpacesModal.lineBreaks',
              )}
              ref={ref => {
                if (ref && selectedModifierItemField === 'linesBefore') {
                  ref.focus();
                  setSelectedModifierItemField('');
                }
              }}
              value={lineBreaks}
              onChange={e => {
                if (!isNumber(Number(e.target.value)) && e.target.value !== '')
                  return;

                setLineBreaks(Number(e.target.value) || 0);
              }}
            />
          </Grid.Item>
          <Grid.Item mb={12}>
            <Input
              type="text"
              label={t(
                'menuScreen.modifierGroupDetails.associations.modifierItemsPricing.breaksSpacesModal.blankSpaces',
              )}
              ref={ref => {
                if (ref && selectedModifierItemField === 'spacesBefore') {
                  ref.focus();
                  setSelectedModifierItemField('');
                }
              }}
              value={blankSpaces}
              onChange={e => {
                if (!isNumber(Number(e.target.value)) && e.target.value !== '')
                  return;

                setBlankSpaces(Number(e.target.value));
              }}
            />
          </Grid.Item>
        </Grid>
      </Modal>

      <SelectionModal
        type="modifierItems"
        detailsScreenProps={{
          currentRelationsIds: Object.keys(modifierItems),
          wantedEntity: 'modifierItems',
        }}
        onAssociate={handleAssociateModifierItem}
        onModalClose={() => setShowAddExistingModifierItemModal(false)}
        active={showAddExistingModifierItemModal}
      />

      <DraggableContainer
        draggableItems={modifierItemsData.map(({ id }) => `${id}`)}
        setActiveId={setActiveId}
        handleDragEnd={handleDragEnd}>
        <AssignedElements
          noCardsPadding
          type="modifierItems"
          detailsScreenProps={{
            currentRelationsIds: modifierItemsData.map(({ id }) => `${id}`),
            wantedEntity: 'modifierItems',
          }}
          noElementsMessage={t(
            'menuScreen.modifierGroupDetails.associations.modifierItemsPricing.emptyCardsMessage',
          )}
          isSortDisabled={!isEditMode}
          customSort={sortOption => handleOnSortAscDesc(sortOption, isEditMode)}
          elements={modifierItemsData}
          subCardProps={{
            titleComponent: (
              <Box
                csx={{
                  width: '100%',
                  display: 'flex',
                  gap: '10px',
                  justifyContent: 'space-between',
                }}>
                <Box>
                  <Typography
                    csx={{ display: 'flex', gap: '5px' }}
                    color="persistentSemanticBlue"
                    fontWeight="medium">
                    {t(
                      'menuScreen.modifierGroupDetails.associations.modifierItemsPricing.title',
                    )}
                  </Typography>
                  <Typography color="darkestGrey" variant="caption">
                    {t(
                      'menuScreen.modifierGroupDetails.associations.modifierItemsPricing.description',
                    )}
                  </Typography>
                </Box>
                <Box
                  csx={{
                    display: 'flex',
                  }}>
                  <ActionButtons
                    onEditAction={() => {
                      setIsEditMode(!isEditMode);
                    }}
                    optionList={[
                      {
                        text: t(
                          'menuScreen.menuLocation.actionButtons.addNewModifierItem',
                        ),
                        icon: false,
                        handler: () => {
                          handleAddNewModifierItem(
                            t(
                              'menuScreen.menuLocation.actionButtons.addNewModifierItemTag',
                            ),
                          );
                        },
                      },
                      {
                        text: t(
                          'menuScreen.menuLocation.actionButtons.addExistingModifierItem',
                        ),
                        icon: false,
                        handler: () => {
                          setShowAddExistingModifierItemModal(true);
                        },
                      },

                      {
                        text: t(
                          'menuScreen.menuLocation.actionButtons.addNewPricingVersion',
                        ),
                        icon: false,
                        handler: () => {
                          setEditablePricingVersion({
                            data: { name: '', id: 0 },
                            mode: 'add',
                          });
                        },
                      },
                    ]}
                    disabled={false}
                  />
                </Box>
              </Box>
            ),
            extraOptions: (
              <Box
                csx={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                  width: '100%',
                }}>
                <Checkbox
                  label={t(
                    'menuScreen.modifierGroupDetails.associations.modifierItems.showPricing',
                  )}
                  info={t(
                    'menuScreen.modifierGroupDetails.associations.modifierItems.showPricingtoolTip',
                  )}
                  checked={showAllPricing}
                  onChange={showPricing => setShowAllPricing(showPricing)}
                />
                <Checkbox
                  label={t(
                    'menuScreen.modifierGroupDetails.associations.modifierItems.showMaximums',
                  )}
                  checked={editMaximums}
                  onChange={showMaximums => setEditMaximums(showMaximums)}
                />
                <Typography>
                  {t('menuScreen.itemDetails.modifierGroupsSection.totalPrice')}
                  : {formatMoney('', totalPrice)}
                </Typography>
              </Box>
            ),
            csx,
          }}
          cardsContainerCsx={{
            border: 'none',
          }}
          subCardDescription={
            <AssignedPricingVersions
              setSelectedPricingVersionId={(id: number) =>
                setSelectedPricingVersionId(id)
              }
              selectedPricingVersionId={selectedPricingVersionId}
              isEditMode={isEditMode}
              showAllPricing={showAllPricing}
              editablePricingVersionData={editablePricingVersionData}
              setEditablePricingVersion={setEditablePricingVersion}
              csx={{
                border: 'none',
                padding: 0,
                marginBottom: '15px',
              }}
            />
          }
          renderItem={item => {
            const modifierItem = item as IPosModifierItemDelete;

            const currentPricingVersion =
              menuModifierItems[modifierItem.id].modifierItemPricingVersions[
                modifierGroupData.modifierGroupPricingVersions[
                  selectedPricingVersionId
                ]?.modItemPricingVersions[modifierItem.id]
              ];

            const modifier = (extendedModifierItems[modifierItem.id] ||
              {}) as IModifierGroupModifierItem &
              IModifierItemWithFields & {
                defaultSelectionPrice: number;
              };

            const _totalPrice =
              (includedModifierItemsCount[modifier.modifierItemId]?.count ??
                0) *
                (modifierGroupData.quantityMode === QuantityModes.NUMBER
                  ? modifier.includedMasterPrice
                  : getIncludedAmountPrice(modifier)) +
              (additionalModifierItemsCount[modifier.modifierItemId]?.count ??
                0) *
                (modifierGroupData.quantityMode === QuantityModes.NUMBER
                  ? modifier.additionalMasterPrice
                  : getAdditionalAmountPrice(modifier));

            return (
              <>
                <Box
                  csx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                    width: '100%',
                    position: 'relative',
                    zIndex: 0,
                  }}>
                  <Box
                    csx={{
                      padding: '1px 5px 0px 0px',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '5px',
                      alignSelf: 'flex-start',
                    }}>
                    {(modifierItem?.linesBefore > 0 || isEditMode) &&
                      !activeId && (
                        <LinesBeforeButton
                          onClick={() => {
                            if (!isEditMode) return;
                            setShowModal(true);
                            setSelectedModifierItemId(modifierItem.id);
                            setBlankSpaces(modifierItem?.spacesBefore);
                            setLineBreaks(modifierItem?.linesBefore);
                            setSelectedModifierItemField('linesBefore');
                          }}
                          count={
                            modifierItem.linesBefore > 0
                              ? modifierItem.linesBefore
                              : 0
                          }
                        />
                      )}

                    {(modifierItem?.spacesBefore > 0 || isEditMode) &&
                      !activeId && (
                        <SpacesBeforeButton
                          onClick={() => {
                            if (!isEditMode) return;
                            setShowModal(true);
                            setSelectedModifierItemId(modifierItem.id);
                            setBlankSpaces(modifierItem.spacesBefore);
                            setLineBreaks(modifierItem.linesBefore);
                            setSelectedModifierItemField('spacesBefore');
                          }}
                          count={
                            modifierItem.spacesBefore > 0
                              ? modifierItem.spacesBefore
                              : 0
                          }
                        />
                      )}
                  </Box>

                  <Box
                    csx={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                    }}>
                    <Card.ItemButton
                      draggable={isEditMode}
                      draggedItemId={activeId}
                      itemId={modifierItem.id}
                      isDisabled={!modifierItem.active}
                      isActive={modifier.isSelected}
                      totalPrice={_totalPrice}
                      nextPrice={totalDifferenceObj[modifier.modifierItemId]}
                      isSelected={modifier.isSelected}
                      switchValue={modifierItem.active || false}
                      showSwitch
                      isMultipleQuantity={
                        modifierGroupData.quantityMode === QuantityModes.NUMBER
                      }
                      isQuantityOff={
                        modifierGroupData.quantityMode === QuantityModes.OFF
                      }
                      itemType={
                        modifierGroupData.quantityMode === QuantityModes.SIZE
                          ? EItemTypes.SIZE
                          : EItemTypes.QUANTITY
                      }
                      onSwitchChange={isActive => {
                        handleHideModifierItem(isActive, modifierItem.id);
                      }}
                      onIncrement={id => onIncrement(id as number)}
                      onDecrement={id => onDecrement(id as number)}
                      onTotalPriceChanged={total => {
                        if (total !== modifier.defaultSelectionPrice) {
                          updateSelectionPriceForModifierItemsOfModifierGroup(
                            modifier.modifierItemId,
                            SECTION_ID,
                            total,
                          );
                        }
                      }}
                      quantity={(
                        selectedModifierItemElements.modifierItems[
                          modifier.modifierItemId
                        ]?.count ?? 0
                      ).toString()}
                      showRemoveIcon={isEditMode && isMasterMode}
                      onRemoveClick={() => {
                        removeModifierItemFromModifierGroup(modifierItem.id);
                      }}
                      onClick={openInNewTab => {
                        const pathURL = `/menu/modifiers/modifier-items/${item.id}`;
                        if (openInNewTab) return openNewTabWithOrgData(pathURL);

                        checkForChangesAndNavigateWeb(
                          () =>
                            addBreadcrumbLocationWeb({
                              action: BreadCrumbAction.NAV,
                              text: item.title as string,
                              onPress: () => navigate(pathURL),
                              pathURL,
                            }),
                          onRefresh,
                        );
                      }}
                      onChange={isActive => {
                        modifierItem.active
                          ? onChange(modifier.modifierItemId, isActive)
                          : handleHideModifierItem(
                              true,
                              modifier.modifierItemId,
                            );
                      }}
                      selectedQuantity={
                        selectedModifierItemElements.modifierItems[
                          modifier.modifierItemId
                        ]?.count ?? 0
                      }
                      hidePrice
                      isHidden={!modifier.inheritedActive}
                      renderHiddenIfIsActive
                      selectedSize={
                        quantityModeConversion[modifier.defaultAmountSelected]
                      }
                      {...modifierItem}
                    />

                    {modifierItem.pricingVersions.length > 0 &&
                      !activeId &&
                      !isEditMode &&
                      (showAllPricing || editMaximums) && (
                        <Box
                          csx={{
                            padding: '10px',
                            backgroundColor: theme.colors.white,
                            width: 'inherit',
                            border: `1px solid ${theme.colors.lightGrey}`,
                            borderRadius: '8px',
                            alignItems: 'center',
                            display: 'flex',
                            position: 'relative',
                            marginTop: '16px',
                            flexDirection: 'column',
                          }}>
                          <Box
                            csx={{
                              position: 'absolute',
                              top: '-19px',
                              justifyContent: 'center',
                              display: 'flex',
                              width: '100%',
                              left: 0,
                              right: 0,
                            }}>
                            <Icon
                              name="IoTriangleSharp"
                              size="20px"
                              color="lighterGrey"
                            />
                          </Box>
                          {showAllPricing && (
                            <>
                              <Dropdown
                                placeholder="Pricing Version"
                                data={[modifierItem.pricingVersions]}
                                value={modifierItem.selectedPricingVersion}
                                isDisabled={
                                  !modifierItem.active ||
                                  modifierItem.pricingVersions.length === 1
                                }
                                onChange={newPricingVersion => {
                                  handleUpdatePricingVersion(
                                    newPricingVersion,
                                    modifierItem.id,
                                  );
                                }}
                              />
                              <Box
                                csx={{
                                  width: '100%',
                                  marginTop: '10px',
                                  opacity: 0.6,
                                }}>
                                <ShowAllPricingInputs
                                  isMultipleQuantity={
                                    modifierGroupData.quantityMode ===
                                    QuantityModes.NUMBER
                                      ? true
                                      : false
                                  }
                                  isSizeItem={
                                    modifierGroupData.quantityMode ===
                                    QuantityModes.SIZE
                                      ? true
                                      : false
                                  }
                                  isMultiselect={modifierGroupData.multiselect}
                                  data={{
                                    active: modifierItem?.active || false,
                                    override: {},
                                    ...currentPricingVersion,
                                  }}
                                  onChange={(key, value) => {
                                    setValue(
                                      { [key]: value },
                                      SECTION_ID,
                                      SubSections.MODIFIER_ITEM,
                                      modifierItem.id,
                                    );
                                  }}
                                  hasBorders={true}
                                  isEditable={false}
                                />
                              </Box>
                            </>
                          )}
                          {editMaximums && (
                            <Box
                              csx={{
                                display: 'flex',
                                flexDirection: 'column',
                                padding: '5px 0px',
                                width: '100%',
                              }}>
                              <Box
                                csx={{
                                  backgroundColor: theme.colors.lightestGrey,
                                  padding: '5px',
                                  marginBottom: '5px',
                                }}>
                                <Typography align="center">
                                  {t(
                                    'menuScreen.modifierGroupDetails.associations.modifierItemsPricing.maximumQuantity',
                                  )}
                                </Typography>
                              </Box>
                              <MoneyInput
                                value={modifier.maximumQuantity}
                                showPlaceholder
                                placeholder={t(
                                  'menuScreen.modifierGroupDetails.basic.noMax',
                                )}
                                prefix=""
                                precision={0}
                                onValueChange={value =>
                                  setValue(
                                    {
                                      maximumQuantity: value,
                                    },
                                    SECTION_ID,
                                    SubSections.MODIFIER_ITEM,
                                    modifierItem.id,
                                  )
                                }
                                maxValue={99}
                              />
                            </Box>
                          )}
                        </Box>
                      )}
                  </Box>
                </Box>
              </>
            );
          }}
        />
      </DraggableContainer>
    </>
  );
};

export default ModifierItemsSection;
