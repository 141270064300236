import React, {
  ChangeEvent,
  forwardRef,
  useImperativeHandle,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import Box from '../Box';
import {
  captionStyles,
  emptyCaptionStyles,
  errorCaptionStyles,
} from '../Input/styles';
import Typography from '../Typography';
import { codeElementStyles, elementsContainer } from './styles';
import { VALID_KEYS } from './types';

interface ICodeVerifier {
  id?: string;
  length: number;
  error?: boolean;
  caption?: string;
  isLoading?: boolean;
  emptyCation?: boolean;
  getCode?: (code: string) => void;
  submitCode?: () => void;
}

const CodeVerifier = forwardRef(
  (
    {
      id = 'cv',
      length,
      error,
      caption,
      emptyCation,
      getCode,
      isLoading,
      submitCode,
    }: ICodeVerifier,
    ref,
  ) => {
    const { t } = useTranslation();

    const [code, setCode] = useState([...Array(length).fill('')]);

    useImperativeHandle(ref, () => ({
      resetInput: () => {
        setCode([...Array(length).fill('')]);
        handleCheckAllInserted(false, [...Array(length).fill('')]);
      },
    }));

    const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
      const currentValue = e.currentTarget.value;
      const currentTargetId = e.currentTarget.id;
      const index = Number(currentTargetId.split('_')[1]);

      const currentKey =
        code[index] === currentValue[0]
          ? currentValue[currentValue.length - 1]
          : currentValue[0];
      const innerTextLength = currentValue.length;

      if (currentKey)
        handleValidateKey(currentTargetId, currentKey, innerTextLength);
    };

    const handleKeyDownCapture = (e: React.KeyboardEvent<HTMLInputElement>) => {
      const currentKey = e.key;
      const currentTargetId = e.currentTarget.id;
      const innerTextLength = e.currentTarget.value.length;

      if (Number(currentKey) === 0) {
        return e;
      }

      if (currentKey === 'Backspace')
        handleValidateKey(currentTargetId, currentKey, innerTextLength);

      if (
        currentKey !== 'Enter' &&
        !Number(currentKey) &&
        !(e.metaKey && currentKey === 'v') &&
        !(e.ctrlKey && currentKey === 'v')
      ) {
        return e.preventDefault();
      }

      return e;
    };

    const handleValidateKey = (
      currentTargetId: string,
      currentKey: string,
      innerTextLength: number,
    ) => {
      try {
        const index = parseInt(currentTargetId.split('_')[1]);
        const codeArray = code;
        const currentNumber = codeArray[index];

        if (VALID_KEYS.indexOf(currentKey) === -1) return;

        if (currentKey === 'Backspace') {
          if (innerTextLength === 1) {
            codeArray[index] = '';
            setCode(code);
          }
          handleCheckAllInserted(false);
          if (innerTextLength === 0) handleFocusPrevious(currentTargetId);
        } else if (currentKey === 'Enter') {
          codeArray[index] = currentNumber;
          if (index === length - 1) {
            handleCheckAllInserted(true);
          } else if (currentNumber) {
            handleFocusNext(currentTargetId);
          }
        } else {
          codeArray[index] = currentKey;
          setCode(code);
          handleCheckAllInserted(false);
          handleFocusNext(currentTargetId);
        }
      } catch (_error) {
        console.info(_error);
      }
    };

    const handleCheckAllInserted = (
      submit: boolean,
      currentCode: any[] = code,
    ) => {
      const codeVerified = currentCode.filter(digit => isFinite(digit));
      if (codeVerified.length === length) {
        if (getCode) getCode(codeVerified.join(''));
        if (submit) if (submitCode) submitCode();
      } else {
        if (getCode) getCode(codeVerified.join(''));
      }
    };

    const handleFocusPrevious = (currentId: string) => {
      const splittedId = currentId.split('_');
      const nextId = parseInt(splittedId[1]) - 1;
      const beforeElement = document.getElementById(
        `${splittedId[0]}_${nextId}`,
      );
      beforeElement?.focus();
      beforeElement?.click();
    };

    const handleFocusNext = (currentId: string) => {
      const splittedId = currentId.split('_');
      const nextId = Number(splittedId[1]) + 1;
      const nextElement = document.getElementById(`${splittedId[0]}_${nextId}`);

      nextElement?.focus();
      nextElement?.click();
    };

    const handlePaste = (e: any) => {
      e.preventDefault();
      const pastedCode = (e.clipboardData || window.ClipboardItem).getData(
        'text',
      ) as string;
      const newCode = pastedCode.split('');
      if (Number(pastedCode) && pastedCode.length === length) {
        setCode(newCode);
        handleFocusLast();
        handleCheckAllInserted(true, newCode);
      }
      return false;
    };

    const handleFocusLast = () => {
      const nextElement = document.getElementById(`${id}_${length - 1}`);

      nextElement?.focus();
      nextElement?.click();
    };

    const renderItem = (_: number, key: number) => {
      return (
        <input
          id={`${id}_${key}`}
          key={`${id}_${key}`}
          type="number"
          step="1"
          value={code[key]}
          className={`${error ? 'error' : ''}`}
          css={codeElementStyles}
          onChange={handleOnChange}
          onCopy={() => false}
          onCut={() => false}
          onPaste={handlePaste}
          onKeyDownCapture={handleKeyDownCapture}
          disabled={isLoading}
          autoComplete="one-time-code"
        />
      );
    };

    return (
      <Box>
        <Box csx={elementsContainer}>{[...Array(length)].map(renderItem)}</Box>
        {!caption && !error && emptyCation ? (
          <label css={emptyCaptionStyles} />
        ) : (
          (caption || error) && (
            <Typography csx={error ? errorCaptionStyles : captionStyles}>
              {caption || t('validations.invalidCodePleaseTryAgain')}
            </Typography>
          )
        )}
      </Box>
    );
  },
);

CodeVerifier.displayName = 'CodeVerifier';

export default CodeVerifier;
