import Grid from '@app/components/common/Grid';
import MainScreenWithFilterContainer from '@app/components/common/MainScreenWithFilterContainer';
import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';
import { useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import ItemComboSalesTable from '../../ItemComboSales/ItemComboSalesList/ItemComboSalesTable';

const SalesOverviewList = ({ t }: WithTranslation) => {
  const navigate = useNavigateWithOrg();
  const dropdownOptions = useMemo(
    () => [
      [{ label: 'All', value: 0 }],
      [
        { label: t('commonTexts.active'), value: 1 },
        { label: t('commonTexts.inactive'), value: 2 },
      ],
    ],
    [t],
  );

  return (
    <MainScreenWithFilterContainer<{ title: string; activeFilter: number }>
      data={[]}
      menusSet={new Set()}
      //@ts-expect-error whatever
      renderItem={() => null}
      breakpoint="medium"
      optionList={[
        {
          text: '',
          handler() {
            navigate('/reports/sales-overview/add');
          },
        },
      ]}
      dropdowns={[
        {
          id: 'activeFilter',
          label: t('commonTexts.status'),
          data: dropdownOptions,
          defaultValue: 1,
          dataExample: 1,
        },
      ]}
      groupFilters
      showChildren
      sortAvailable={false}
      customButtons={
        <Grid.Item mb={12} sm={7} md={7} lg={5} xl={6}></Grid.Item>
      }>
      {filterParams => (
        <ItemComboSalesTable
          bucket="salesOverviewReports"
          filterParams={filterParams}
        />
      )}
    </MainScreenWithFilterContainer>
  );
};

export default SalesOverviewList;
