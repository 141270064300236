import DetailsSubtitle from '@app/components/LoggedIn/DetailsSubtitle';
import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import { IAccordionSection } from '@app/components/common/Accordion/AccordionSection/types';
import Box from '@app/components/common/Box';
import Checkbox from '@app/components/common/Checkbox';
import Dropdown from '@app/components/common/Dropdown';
import Grid from '@app/components/common/Grid';
import useRootSelector from '@app/hooks/useRootSelector';
import {
  ILicenseSettingsStore,
  serviceAreasDropdownOptionsFactory,
} from '@westondev/tableturn-core';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface ITableServiceOrderSettings extends IAccordionSection {
  data: ILicenseSettingsStore;
  updateInfo: (
    field: keyof ILicenseSettingsStore,
    newValue: boolean | number | string | null,
  ) => void;
}

const TableServiceOrderSettings = ({
  updateInfo,
  data,
  ...sectionProps
}: ITableServiceOrderSettings) => {
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isExpanded, ...otherProps } = sectionProps;

  // Redux
  const serviceAreas = useRootSelector(
    state => state.tableLayout.savedData.serviceAreas,
  );

  // Local state
  const serviceAreasDropdownOptions = useMemo(() => {
    const serviceData = serviceAreasDropdownOptionsFactory(
      t,
      serviceAreas,
      false,
    );
    serviceData[0].unshift({
      label: t('commonTexts.default'),
      value: 0,
    });
    return serviceData;
  }, [t, serviceAreas]);

  return (
    <AccordionSection
      isExpanded={true}
      isExpandable={false}
      expandedHeight="70px"
      title={t(
        'settingsModule.licenseSettings.tableServiceOrderSettings.title',
      )}
      description={t(
        'settingsModule.licenseSettings.tableServiceOrderSettings.description',
      )}
      {...otherProps}>
      <DetailsSubtitle>
        {t(
          'settingsModule.licenseSettings.tableServiceOrderSettings.makingAndPlacingOrders',
        )}
      </DetailsSubtitle>
      <Grid columnGap={20} rowGap={20}>
        <Grid.Item sm={6} lg={4} xl={3}>
          <Dropdown
            label={t(
              'settingsModule.licenseSettings.tableServiceOrderSettings.defaultServiceArea',
            )}
            placeholder={t('commonTexts.placeholderDropdown')}
            data={serviceAreasDropdownOptions}
            value={
              data.defaultServiceAreaId === null ? 0 : data.defaultServiceAreaId
            }
            onChange={value =>
              updateInfo('defaultServiceAreaId', value === 0 ? null : value)
            }
          />
        </Grid.Item>
        <Grid.Item
          sm={6}
          lg={4}
          xl={3}
          csx={{
            flexDirection: 'row',
            alignItems: 'flex-end',
          }}>
          <Box
            csx={{
              display: 'flex',
              alignItems: 'center',
              height: '50px',
            }}>
            <Checkbox
              label={t(
                'settingsModule.licenseSettings.tableServiceOrderSettings.suppressSeating',
              )}
              checked={data.suppressSeating}
              onChange={suppressSeating =>
                updateInfo('suppressSeating', suppressSeating)
              }
            />
          </Box>
        </Grid.Item>
      </Grid>
    </AccordionSection>
  );
};

export default TableServiceOrderSettings;
