import Card from '@app/components/common/Card';
import Checkbox from '@app/components/common/Checkbox';
import Dropdown from '@app/components/common/Dropdown';
import Grid from '@app/components/common/Grid';
import {
  IRegisterSettings,
  RestaurantModes,
  SettingsSectionId,
  actionCreatorsSettings,
  settingsSelectors,
} from '@westondev/tableturn-core';
import { useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import TimeBasedMenuTypeSection from './TimeBasedMenuTypeSection';

const { makeSelectSettingsData } = settingsSelectors;

const SECTION_ID = SettingsSectionId.GENERAL;
const getMemoizedItemData =
  makeSelectSettingsData<IRegisterSettings>(SECTION_ID);

const RESTAURANT_MODE_OPTIONS: Record<number, RestaurantModes> = {
  1: RestaurantModes.TSR,
  2: RestaurantModes.QSR,
};

const GeneralSettings = ({ t }: WithTranslation) => {
  // Redux
  const { updateGenericSettings: setValue } = bindActionCreators(
    actionCreatorsSettings,
    useDispatch(),
  );

  const { data } = useSelector(getMemoizedItemData);

  // Local state
  const restaurantModeDropdownValue = useMemo(
    () =>
      Number(
        Object.keys(RESTAURANT_MODE_OPTIONS).find(
          key =>
            RESTAURANT_MODE_OPTIONS[Number(key)] ===
            data?.defaultRestaurantMode,
        ),
      ),
    [data?.defaultRestaurantMode],
  );

  return (
    <>
      <Grid>
        <Grid.Item mb={12} sm={6} md={6} lg={4}>
          <Dropdown
            placeholder={t(
              'settingsModule.registerModeSettings.general.defaultRestaurantMode.label',
            )}
            data={[
              [
                { value: 1, label: 'Table Service' },
                { value: 2, label: 'Quick Service' },
              ],
            ]}
            label={t(
              'settingsModule.registerModeSettings.general.defaultRestaurantMode.label',
            )}
            info={t(
              'settingsModule.registerModeSettings.general.defaultRestaurantMode.tooltip',
            )}
            onChange={defaultRestaurantMode => {
              const newValue =
                defaultRestaurantMode in RESTAURANT_MODE_OPTIONS
                  ? RESTAURANT_MODE_OPTIONS[defaultRestaurantMode]
                  : undefined;
              setValue(
                {
                  defaultRestaurantMode: newValue,
                },
                SECTION_ID,
              );
            }}
            value={restaurantModeDropdownValue}
          />
        </Grid.Item>
        <Grid.Item sm={12}>
          <Grid rowGap={15} columnGap={15} csx={{ marginTop: '15px' }}>
            <Grid.Item md={12} lg={8}>
              <Card.SubCard
                title={t(
                  'settingsModule.registerModeSettings.general.registerDisplay.title',
                )}>
                <Grid rowGap={15} columnGap={15}>
                  <Grid.Item
                    mb={12}
                    sm={6}
                    csx={{
                      justifyContent: 'center',
                    }}>
                    <Checkbox
                      label={t(
                        'settingsModule.registerModeSettings.general.registerDisplay.showModTagBar',
                      )}
                      info={t(
                        'settingsModule.registerModeSettings.general.registerDisplay.showModTagBarToolTip',
                      )}
                      checked={data ? data.showModTagBar : false}
                      onChange={showModTagBar =>
                        setValue<IRegisterSettings>(
                          { showModTagBar },
                          SECTION_ID,
                        )
                      }
                    />
                  </Grid.Item>

                  <Grid.Item
                    mb={12}
                    sm={6}
                    csx={{
                      justifyContent: 'center',
                    }}>
                    <Checkbox
                      info={t(
                        'settingsModule.registerModeSettings.general.registerDisplay.showConfirmPopupTooltip',
                      )}
                      label={t(
                        'settingsModule.registerModeSettings.general.registerDisplay.showConfirmPopup',
                      )}
                      checked={data ? data.showConfirmPopupToRemoveItem : false}
                      onChange={showConfirmPopupToRemoveItem =>
                        setValue<IRegisterSettings>(
                          { showConfirmPopupToRemoveItem },
                          SECTION_ID,
                        )
                      }
                    />
                  </Grid.Item>

                  <Grid.Item
                    mb={12}
                    sm={6}
                    csx={{
                      justifyContent: 'center',
                    }}>
                    <Checkbox
                      info={t(
                        'settingsModule.registerModeSettings.general.registerDisplay.enforceItemGroupOrderForCombosTooltip',
                      )}
                      label={t(
                        'settingsModule.registerModeSettings.general.registerDisplay.enforceItemGroupOrderForCombos',
                      )}
                      checked={
                        data ? data.enforceItemGroupOrderForCombos : false
                      }
                      onChange={enforceItemGroupOrderForCombos =>
                        setValue<IRegisterSettings>(
                          { enforceItemGroupOrderForCombos },
                          SECTION_ID,
                        )
                      }
                    />
                  </Grid.Item>

                  <Grid.Item
                    mb={12}
                    sm={6}
                    csx={{
                      justifyContent: 'center',
                    }}>
                    <Checkbox
                      info={t(
                        'settingsModule.registerModeSettings.general.registerDisplay.showConfirmPopUpOnClearUnsentWithinAnItemTooltip',
                      )}
                      label={t(
                        'settingsModule.registerModeSettings.general.registerDisplay.showConfirmPopUpOnClearUnsentWithinAnItem',
                      )}
                      checked={
                        data
                          ? data.showConfirmPopUpOnClearUnsentWithinAnItem
                          : false
                      }
                      onChange={showConfirmPopUpOnClearUnsentWithinAnItem =>
                        setValue<IRegisterSettings>(
                          { showConfirmPopUpOnClearUnsentWithinAnItem },
                          SECTION_ID,
                        )
                      }
                    />
                  </Grid.Item>

                  <Grid.Item
                    mb={12}
                    sm={6}
                    csx={{
                      justifyContent: 'center',
                    }}>
                    <Checkbox
                      info={t(
                        'settingsModule.registerModeSettings.general.registerDisplay.showConfirmPopUpOnLeaveIncompleteItemTooltip',
                      )}
                      label={t(
                        'settingsModule.registerModeSettings.general.registerDisplay.showConfirmPopUpOnLeaveIncompleteItem',
                      )}
                      checked={
                        data
                          ? data.showConfirmPopUpOnLeaveIncompleteItem
                          : false
                      }
                      onChange={showConfirmPopUpOnLeaveIncompleteItem =>
                        setValue<IRegisterSettings>(
                          { showConfirmPopUpOnLeaveIncompleteItem },
                          SECTION_ID,
                        )
                      }
                    />
                  </Grid.Item>

                  <Grid.Item
                    mb={12}
                    sm={6}
                    csx={{
                      justifyContent: 'center',
                    }}>
                    <Checkbox
                      info={t(
                        'settingsModule.registerModeSettings.general.registerDisplay.showSentItemModificationsOnRegisterTicketTooltip',
                      )}
                      label={t(
                        'settingsModule.registerModeSettings.general.registerDisplay.showSentItemModificationsOnRegisterTicket',
                      )}
                      checked={
                        data
                          ? data.showSentItemModificationsOnRegisterTicket
                          : false
                      }
                      onChange={showSentItemModificationsOnRegisterTicket =>
                        setValue<IRegisterSettings>(
                          { showSentItemModificationsOnRegisterTicket },
                          SECTION_ID,
                        )
                      }
                    />
                  </Grid.Item>

                  <Grid.Item
                    mb={12}
                    sm={6}
                    csx={{
                      justifyContent: 'center',
                    }}>
                    <Checkbox
                      label={t(
                        'settingsModule.registerModeSettings.general.registerDisplay.showHotButtonToggle',
                      )}
                      info={t(
                        'settingsModule.registerModeSettings.general.registerDisplay.showHotButtonToggleTooltip',
                      )}
                      checked={data ? data.showHotButtonToggle : false}
                      onChange={showHotButtonToggle =>
                        setValue<IRegisterSettings>(
                          { showHotButtonToggle },
                          SECTION_ID,
                        )
                      }
                    />
                  </Grid.Item>
                  <Grid.Item
                    mb={12}
                    sm={6}
                    csx={{
                      justifyContent: 'center',
                    }}>
                    <Checkbox
                      label={t(
                        'settingsModule.registerModeSettings.general.registerDisplay.showNotificationMessageForPrinterErrors',
                      )}
                      info={t(
                        'settingsModule.registerModeSettings.general.registerDisplay.showNotificationMessageForPrinterErrorsTooltip',
                      )}
                      checked={
                        data
                          ? data.showNotificationMessageForPrinterErrors
                          : false
                      }
                      onChange={showNotificationMessageForPrinterErrors =>
                        setValue<IRegisterSettings>(
                          { showNotificationMessageForPrinterErrors },
                          SECTION_ID,
                        )
                      }
                    />
                  </Grid.Item>
                </Grid>
              </Card.SubCard>
            </Grid.Item>
            <Grid.Item md={12} lg={4}>
              <Card.SubCard
                csx={{ height: '100%' }}
                title={t(
                  'settingsModule.registerModeSettings.general.orderTypes.title',
                )}
                info={t(
                  'settingsModule.registerModeSettings.general.orderTypes.toolTip',
                )}>
                <Grid rowGap={15} columnGap={15}>
                  <Grid.Item mb={12}>
                    <Checkbox
                      label={t(
                        'settingsModule.registerModeSettings.general.orderTypes.catering',
                      )}
                      checked={data ? data.isCateringAvailable : false}
                      onChange={isCateringAvailable =>
                        setValue<IRegisterSettings>(
                          { isCateringAvailable },
                          SECTION_ID,
                        )
                      }
                    />
                  </Grid.Item>
                  <Grid.Item mb={12}>
                    <Checkbox
                      label={t(
                        'settingsModule.registerModeSettings.general.orderTypes.toGo',
                      )}
                      checked={data ? data.isToGoAvailable : false}
                      onChange={isToGoAvailable =>
                        setValue<IRegisterSettings>(
                          { isToGoAvailable },
                          SECTION_ID,
                        )
                      }
                    />
                  </Grid.Item>
                  <Grid.Item mb={12}>
                    <Checkbox
                      label={t(
                        'settingsModule.registerModeSettings.general.orderTypes.delivery',
                      )}
                      checked={data ? data.isDeliveryAvailable : false}
                      onChange={isDeliveryAvailable =>
                        setValue<IRegisterSettings>(
                          { isDeliveryAvailable },
                          SECTION_ID,
                        )
                      }
                    />
                  </Grid.Item>
                </Grid>
              </Card.SubCard>
            </Grid.Item>
          </Grid>
        </Grid.Item>
        <Grid.Item sm={12} csx={{ marginTop: '15px' }}>
          <TimeBasedMenuTypeSection />
        </Grid.Item>
      </Grid>
    </>
  );
};

export default GeneralSettings;
