import { CellContext, createColumnHelper } from '@tanstack/react-table';
import {
  IKitchenTicketCustomizingReceiptBody,
  IKitchenTicketCustomizingReceiptBodyRow,
  IPrepStationVersion,
  IWithPrepStationVersionId,
  KITCHEN_PRINTER_COLOR_OPTIONS,
  KITCHEN_TICKET_FONT_SIZE_OPTIONS,
  KITCHEN_TICKET_FONT_WEIGHT_OPTIONS,
  KITCHEN_TICKET_JUSTIFY_OPTIONS,
  KitchenCustomizingReceiptsStatus,
  KitchenTicketJustify,
  actionCreatorsSettings,
  kitchenPrinterColorOptionsFactory,
  kitchenTicketFontSizeOptionsFactory,
  kitchenTicketFontWeightOptionsFactory,
  kitchenTicketJustifyOptionsFactory,
} from '@westondev/tableturn-core';
import orderBy from 'lodash/orderBy';
import React, { useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

import Dropdown from '@app/components/common/Dropdown';
import Switch from '@app/components/common/Switch';
import Table from '@app/components/common/Table';
import { store } from '@app/state/store';
import { useTheme } from '@emotion/react';
import {
  IKitchenTicketSettings,
  SettingsSectionId,
} from '@westondev/tableturn-core';
import cloneDeep from 'lodash/cloneDeep';

type ICustomizingReceiptsBodyTableType = {
  id: keyof IKitchenTicketCustomizingReceiptBody;
  isLocked: boolean;
} & IKitchenTicketCustomizingReceiptBodyRow;

const columnHelper = createColumnHelper<ICustomizingReceiptsBodyTableType>();

interface ICustomizingReceiptsTable
  extends WithTranslation,
    IWithPrepStationVersionId {
  data: IPrepStationVersion;
  sectionId: SettingsSectionId;
  title?: string;
}

const DISABLED_STATUS_VALUES = ['itemNameAndQuantity', 'modifiersInfo'];

// eslint-disable-next-line react-refresh/only-export-components
const CustomizingReceiptsTableBody = ({
  t,
  data,
  sectionId,
  title,
  prepStationVersionId,
}: ICustomizingReceiptsTable) => {
  const { updateKitchenTicketSettings: setValue } = bindActionCreators(
    actionCreatorsSettings,
    useDispatch(),
  );

  const theme = useTheme();

  const textColor = theme.colors.text;
  const kitchenTicketJustifyOptions = useMemo(
    () => kitchenTicketJustifyOptionsFactory(t),
    [t],
  );
  const kitchenTicketFontSizeOptions = useMemo(
    () => kitchenTicketFontSizeOptionsFactory(t),
    [t],
  );
  const kitchenTicketFontWeightOptions = useMemo(
    () => kitchenTicketFontWeightOptionsFactory(t),
    [t],
  );
  const kitchenPrinterColorOptions = useMemo(
    () => kitchenPrinterColorOptionsFactory(t),
    [t],
  );

  const updateRow = (
    newValue: any,
    id: keyof IKitchenTicketCustomizingReceiptBody,
    field: keyof IKitchenTicketCustomizingReceiptBodyRow,
  ) => {
    const ticketBody = (
      store.getState().settings.changeData.data as IKitchenTicketSettings
    )?.prepStationVersions?.[prepStationVersionId]?.ticketBody;

    setValue(
      {
        ticketBody: {
          ...ticketBody,
          [id]: {
            ...ticketBody[id],
            [field]: newValue,
          },
        },
      },
      sectionId,
      prepStationVersionId,
    );
  };

  const ticketBodyArray = useMemo(() => {
    const seatNumber = Object.keys(data?.ticketBody || {})
      .filter(key => key === 'seatNumber')
      .map(key => ({
        ...data?.ticketBody[key as keyof IKitchenTicketCustomizingReceiptBody],
        id: key as keyof IKitchenTicketCustomizingReceiptBody,
        isLocked: data?.groupBySeat && key === 'seatNumber' ? true : false,
      }));
    const otherFields = Object.keys(data?.ticketBody || {}).filter(
      key => key !== 'seatNumber',
    );
    const orderedOtherFields = orderBy(
      otherFields.map(key => ({
        ...data?.ticketBody[key as keyof IKitchenTicketCustomizingReceiptBody],
        id: key as keyof IKitchenTicketCustomizingReceiptBody,
        isLocked: data?.groupBySeat && key === 'seatNumber' ? true : false,
      })),
      'order',
    );
    return [...seatNumber, ...orderedOtherFields];
  }, [data]);

  const COLUMN_VALUES = useMemo(
    () => [
      columnHelper.accessor('status', {
        header: t(
          'loggedIn.settingsModule.kitchenTicketSettings.customizingReceiptsTable.status',
        ),
        meta: {
          getCellContext: (context: CellContext<any, unknown>) => {
            const rowData = context.row.original;
            return {
              locked: rowData.isLocked,
            };
          },
        },
        cell: info => (
          <Switch
            checked={
              info.getValue() === KitchenCustomizingReceiptsStatus.ACTIVE
            }
            isDisabled={
              DISABLED_STATUS_VALUES.includes(info.row.original.id) ||
              info.row.original.isLocked
            }
            onChange={isActive => {
              updateRow(
                isActive
                  ? KitchenCustomizingReceiptsStatus.ACTIVE
                  : KitchenCustomizingReceiptsStatus.INACTIVE,
                info.row.original.id,
                'status',
              );
            }}
          />
        ),
        size: 80,
      }),
      columnHelper.accessor('id', {
        header: t(
          'loggedIn.settingsModule.kitchenTicketSettings.customizingReceiptsTable.value.label',
        ),
        cell: info =>
          t(
            `loggedIn.settingsModule.kitchenTicketSettings.customizingReceiptsTable.value.options.${info.getValue()}`,
          ),
        minSize: 200,
      }),
      columnHelper.accessor('justify', {
        header: t(
          'loggedIn.settingsModule.kitchenTicketSettings.customizingReceiptsTable.justify.label',
        ),
        cell: info => (
          <Dropdown
            placeholder={t('commonTexts.placeholderDropdown')}
            data={[kitchenTicketJustifyOptions]}
            value={KITCHEN_TICKET_JUSTIFY_OPTIONS.indexOf(KitchenTicketJustify.LEFT) + 1}
            isDisabled={true}
            onChange={newJustify =>
              updateRow(
                KITCHEN_TICKET_JUSTIFY_OPTIONS[newJustify - 1],
                info.row.original.id,
                'justify',
              )
            }
          />
        ),
        minSize: 200,
      }),
      columnHelper.accessor('fontSize', {
        header: t(
          'loggedIn.settingsModule.kitchenTicketSettings.customizingReceiptsTable.fontSize.label',
        ),
        cell: info => (
          <Dropdown
            placeholder={t('commonTexts.placeholderDropdown')}
            data={[kitchenTicketFontSizeOptions]}
            value={
              info.getValue() === null
                ? 0
                : KITCHEN_TICKET_FONT_SIZE_OPTIONS.indexOf(info.getValue()) + 1
            }
            onChange={newFontSize =>
              updateRow(
                KITCHEN_TICKET_FONT_SIZE_OPTIONS[newFontSize - 1],
                info.row.original.id,
                'fontSize',
              )
            }
          />
        ),
        minSize: 200,
      }),
      columnHelper.accessor('fontWeight', {
        header: t(
          'loggedIn.settingsModule.kitchenTicketSettings.customizingReceiptsTable.fontWeight.label',
        ),
        cell: info => (
          <Dropdown
            placeholder={t('commonTexts.placeholderDropdown')}
            data={[kitchenTicketFontWeightOptions]}
            value={
              info.getValue() === null
                ? 0
                : KITCHEN_TICKET_FONT_WEIGHT_OPTIONS.indexOf(info.getValue()) +
                  1
            }
            onChange={newFontWeight =>
              updateRow(
                KITCHEN_TICKET_FONT_WEIGHT_OPTIONS[newFontWeight - 1],
                info.row.original.id,
                'fontWeight',
              )
            }
          />
        ),
        minSize: 200,
      }),
      columnHelper.accessor('color', {
        header: t(
          'loggedIn.settingsModule.kitchenTicketSettings.customizingReceiptsTable.color',
        ),
        cell: info => (
          <Dropdown
            placeholder={t('commonTexts.placeholderDropdown')}
            data={[kitchenPrinterColorOptions]}
            value={
              info.getValue() === null
                ? 0
                : KITCHEN_PRINTER_COLOR_OPTIONS.indexOf(info.getValue()) + 1
            }
            onChange={newColor =>
              updateRow(
                KITCHEN_PRINTER_COLOR_OPTIONS[newColor - 1],
                info.row.original.id,
                'color',
              )
            }
          />
        ),
        minSize: 200,
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, sectionId, textColor, prepStationVersionId],
  );

  const handleOnSort = (newData: ICustomizingReceiptsBodyTableType[]) => {
    const clonedTicketBody = cloneDeep(data?.ticketBody);

    newData.forEach((item, index) => {
      if (item.id === 'seatNumber') clonedTicketBody[item.id].order = 1;
      else clonedTicketBody[item.id].order = index;
    });
    setValue(
      {
        ticketBody: clonedTicketBody,
      },
      sectionId,
      prepStationVersionId,
    );
  };

  return (
    <Table
      mode="sort-vertically"
      renderEmptyValues
      nestedScrollEnabled
      title={
        title ||
        t(
          'loggedIn.settingsModule.kitchenTicketSettings.customizingReceiptsTable.title.ticketBody',
        )
      }
      columns={COLUMN_VALUES}
      data={data ? ticketBodyArray : []}
      onSortEnd={handleOnSort}
      alignHeaders={{
        prepend: 'center',
        append: 'center',
        justify: 'center',
        fontSize: 'center',
        fontWeight: 'center',
        color: 'center',
        line: 'center',
      }}
      cellCsx={{
        height: 70,
      }}
    />
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export default React.memo(CustomizingReceiptsTableBody);
