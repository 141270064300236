import Box from '@app/components/common/Box';
import Card from '@app/components/common/Card';
import Grid from '@app/components/common/Grid';
import MainScreenWithFilterContainer from '@app/components/common/MainScreenWithFilterContainer';
import Switch from '@app/components/common/Switch';
import { getMainBreadcrumbBucket } from '@app/helpers/factories/menu';
import {
  getPathWithOrgData,
  openNewTabWithOrgData,
} from '@app/helpers/navigation';
import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';
import useSessionStorageState from '@app/hooks/useSessionStorageState';
import { actionCreatorsApp, actionCreatorsMenuWeb } from '@app/state';
import { initializeBucketListBreadcrumb } from '@app/state/app/actions';
import {
  selectBreadCrumbs,
  selectIngredients,
} from '@app/state/menu/menuSelectors';
import { useAppDispatch } from '@app/state/store';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  BreadCrumbAction,
  menuIngredientCardFactory,
} from '@westondev/tableturn-core';
import { useEffect, useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import IngredientsTable from './IngredientsTable';

const IngredientsList = ({ t }: WithTranslation) => {
  // Redux
  const dispatch = useAppDispatch();
  const setShowToast = bindActionCreators(
    actionCreatorsApp.setShowToast,
    dispatch,
  );
  const ingredients = useSelector(selectIngredients);
  const breadcrumbs = useSelector(selectBreadCrumbs);

  const [showListView, setShowListView] = useSessionStorageState(
    'showListView',
    true,
  );

  // Local state
  const navigate = useNavigateWithOrg();

  const ingredientCardList = useMemo(
    () => menuIngredientCardFactory(ingredients),
    [ingredients],
  );

  const { addBreadcrumbLocationWeb } = bindActionCreators(
    actionCreatorsMenuWeb,
    useDispatch(),
  );

  const dropdownOptions = useMemo(
    () => [
      [{ label: 'All', value: 0 }],
      [
        { label: t('commonTexts.active'), value: 1 },
        { label: t('commonTexts.inactive'), value: 2 },
      ],
    ],
    [t],
  );

  useEffect(() => {
    if (!breadcrumbs.length) {
      const navMain = getMainBreadcrumbBucket(5, 53, navigate);
      if (!navMain) return;
      dispatch(initializeBucketListBreadcrumb(navMain));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [breadcrumbs]);

  const handleAddNewIngredient = () => {
    addBreadcrumbLocationWeb({
      action: BreadCrumbAction.ADD,
      text: t('menuScreen.menuLocation.actionButtons.addNewIngredient'),
      onPress: () => navigate('/menu/modifiers/ingredients/add'),
      pathURL: '/menu/modifiers/ingredients/add',
    });
  };

  return (
    <MainScreenWithFilterContainer
      dropdowns={[
        {
          id: 'activeFilter',
          label: t('commonTexts.status'),
          data: dropdownOptions,
          defaultValue: 1,
          dataExample: 1,
        },
      ]}
      noItemsText={t('commonTexts.noneFound', {
        elementName: t('screenNames.IngredientsScreen'),
      })}
      breakpoint="medium"
      data={ingredientCardList}
      optionList={[
        {
          text: t('menuScreen.menuLocation.actionButtons.addNewIngredient'),
          handler: handleAddNewIngredient,
        },
      ]}
      onOptionClickDisabled={() => {
        setShowToast({
          type: 'info',
          title: t('menuScreen.ingredientsMain.disabledActionToast.title'),
          description: t(
            'menuScreen.ingredientsMain.disabledActionToast.description',
          ),
        });
      }}
      sortAvailable={!showListView}
      groupFilters
      showChildren={showListView}
      customButtons={
        <Grid.Item
          mb={12}
          sm={showListView ? 7 : 6}
          md={showListView ? 7 : 6}
          lg={showListView ? 5 : 4}
          xl={showListView ? 6 : 5}>
          <Box
            csx={theme => ({
              display: 'flex',
              alignItems: 'center',
              height: '100%',
              borderLeft: `1px solid ${theme.colors.lightGrey}`,
              paddingLeft: '15px',
            })}>
            <Switch
              checked={showListView}
              onChange={isActive => setShowListView(isActive)}
              label={t('menuScreen.itemsMain.showListView')}
            />
          </Box>
        </Grid.Item>
      }
      renderItem={ingredient => {
        const pathURL = `/menu/modifiers/ingredients/${ingredient.id}`;
        return (
          <Card.Item
            title={ingredient.title}
            isActive={ingredient.isActive}
            showStatus
            tag={t('components.menuCard.tag.itemCount', {
              count: ingredient.itemCount,
            })}
            onClick={openInNewTab => {
              if (openInNewTab) return openNewTabWithOrgData(pathURL);
              const _navigate = () => navigate(pathURL);
              addBreadcrumbLocationWeb({
                action: BreadCrumbAction.NAV,
                text: ingredient.title,
                statusPath: `selectedMode.currentMenu.ingredients.${ingredient.id}.active`,
                onPress: _navigate,
                pathURL,
              });
            }}
            count={ingredient.itemCount}
            isLink={getPathWithOrgData(pathURL)}
          />
        );
      }}>
      {filterParams => <IngredientsTable filterParams={filterParams} />}
    </MainScreenWithFilterContainer>
  );
};

export default IngredientsList;
