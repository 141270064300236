import {
  factoriesEntitiesMap,
  factoriesSettingsEntitiesMap,
} from '@app/helpers/factories/factoriesMap';
import {
  getDetailsScreenData,
  getLocations,
  getMainScreenData,
} from '@app/helpers/modals/additionModal';
import { getSettingsMainScreenData } from '@app/helpers/settings/additionModa';
import { selectLocations, selectMenu } from '@app/state/menu/menuSelectors';
import { selectCurrentSettings } from '@app/state/selectors/settingsSelectors';
import { RootState } from '@app/state/store';
import {
  IMenuStore,
  ISettingsStore,
  IUserObject,
} from '@westondev/tableturn-core';
import { useEffect, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import CategoriesSelectionModal from './CategoriesSelectionModal';
import CombosSelectionModal from './CombosSelectionModal';
import DiscountSelectionModal from './DiscountSelectionModal';
import { IItemSelection } from './GenericSelectionModal/types';
import IngredientsSelectionModal from './IngredientsSelectionModal';
import ItemGroupsSelectionModal from './ItemGroupsSelectionModal';
import ItemsAndCombosSelectionModal from './ItemsAndCombosSelectionModal';
import ItemsSelectionModal from './ItemsSelectionModal';
import LocationWithRolesSelectionModal from './LocationWithRolesSelectionModal';
import LocationsSelectionModal from './LocationsSelectionModal';
import MenuTypesSelectionModal from './MenuTypesSelectionModal';
import ModifierGroupsSelectionModal from './ModifierGroupsSelectionModal';
import ModifierItemsSelectionModal from './ModifierItemsSelectionModal';
import ReportingGroupsSelectionModal from './ReportingGroupsSelectionModal';
import RolesSelectionModal from './RolesSelectionModal';
import SpecialsSelectionModal from './SpecialsSelectionModal';
import SubcategoriesSelectionModal from './SubcategoriesSelectionModal';
import UsersSelectionModal from './UsersSelectionModal';
import { ICustomSelectionModal } from './types';

const SelectionType = {
  locations: LocationsSelectionModal,
  users: UsersSelectionModal,
  roles: RolesSelectionModal,
  items: ItemsSelectionModal,
  modifierItems: ModifierItemsSelectionModal,
  modifierGroups: ModifierGroupsSelectionModal,
  subcategories: SubcategoriesSelectionModal,
  menuTypes: MenuTypesSelectionModal,
  categories: CategoriesSelectionModal,
  discounts: DiscountSelectionModal,
  combos: CombosSelectionModal,
  specials: SpecialsSelectionModal,
  locationsWithRoles: LocationWithRolesSelectionModal,
  ingredients: IngredientsSelectionModal,
  itemsAndCombos: ItemsAndCombosSelectionModal,
  itemGroups: ItemGroupsSelectionModal,
  reportingGroups: ReportingGroupsSelectionModal,
};

export interface ISelectionModal<T>
  extends Omit<ICustomSelectionModal<T>, 'buttons' | keyof WithTranslation> {
  type: keyof typeof SelectionType;
  mainScreenProps?: {
    entity: keyof IMenuStore;
    currentRelationsIds?: string[];
  } | null;
  detailsScreenProps?: {
    currentRelationsIds: string[];
    wantedEntity: keyof IMenuStore | keyof ISettingsStore;
    filterFunction?: (elements: string[]) => string[];
  } | null;
  extraLocationIds?: number[];
  isSettingsAccessLocations?: boolean;
  showOnly?: boolean;
  filterFunction?: (buttons: IItemSelection[]) => IItemSelection[];
  deleteAllMenuTypes?: () => void;
}

const SelectionModal = <T,>({
  type,
  mainScreenProps = null,
  detailsScreenProps = null,
  allowAssociateMultiple = true,
  showDescriptionPanel = false,
  scrollCardSectionNumberOfColumns = 5,
  showSelectedButtons = true,
  btnSuccessText,
  active,
  onModalClose,
  onAssociate,
  itemMenuTypeTemplate,
  title,
  description,
  extraLocationIds,
  isSettingsAccessLocations,
  showOnly,
  emptySectionMessage,
  associateOnSelect,
  itemMultipleMenuTypeTemplate,
  itemMultipleMenuTypeTemplateDescription,
  filterFunction,
  deleteAllMenuTypes,
  customOnDelete,
  selectedMenuTypeIds,
  showSelectAll,
  groupByMenuType,
  filterSelectedItems,
  onFilter,
  showSubcategoryDropdown,
  btnCancelText,
  selectedButtonIds,
}: ISelectionModal<T>) => {
  const masterMenu = useSelector((state: RootState) => state.menu.masterMenu);

  const currentMenu = useSelector(selectMenu);
  const currentSettings = useSelector(selectCurrentSettings);

  const masterSettings = useSelector(
    (state: RootState) => state.settings.master,
  );
  const locationGroups = useSelector(selectLocations);

  const locationRoles = useSelector(
    (state: RootState) => state.settings.selectedMode.currentSettings.roles,
  );

  const settingsAccessLocations = useSelector(
    (state: RootState) => state.loggedInUser.settingsAccessLocations,
  );

  const changedData = useSelector(
    (state: RootState) => state.settings.changeData.data,
  );

  const Component = SelectionType[type];

  const [buttons, setButtons] = useState<IItemSelection[]>([]);
  const [hasSet, setHasSet] = useState(false);

  useEffect(() => {
    if (active) {
      if (mainScreenProps) {
        const dif = getMainScreenData(
          mainScreenProps.entity,
          mainScreenProps.currentRelationsIds,
        );

        const entities: { [key: number]: any } = {};

        dif.forEach(id => {
          entities[Number(id)] = masterMenu[mainScreenProps.entity][Number(id)];
        });

        const result = factoriesEntitiesMap(mainScreenProps.entity, entities);
        setButtons(result ? result : []);
      }
      if (detailsScreenProps) {
        const dif = getDetailsScreenData(
          detailsScreenProps.currentRelationsIds,
          detailsScreenProps.wantedEntity,
          detailsScreenProps.filterFunction,
        );

        const entities: { [key: number]: any } = {};

        dif.forEach(id => {
          entities[Number(id)] = (currentMenu[
            detailsScreenProps.wantedEntity as keyof IMenuStore
          ] ||
            currentSettings[
              detailsScreenProps.wantedEntity as keyof ISettingsStore
            ])[Number(id)];
        });

        const result =
          factoriesEntitiesMap(
            detailsScreenProps.wantedEntity as keyof IMenuStore,
            entities,
          ) ||
          factoriesSettingsEntitiesMap(
            detailsScreenProps.wantedEntity as keyof ISettingsStore,
            entities,
          );

        setButtons(result ? result : []);
      }
      if (type === 'locations') {
        const dif = extraLocationIds
          ? getLocations(extraLocationIds, isSettingsAccessLocations)
          : getLocations();

        const result: IItemSelection[] = [];

        Object.values(locationGroups).forEach(locationGroup => {
          const locations =
            locationGroup.locations
              .filter(location => dif.includes(location.id))
              .map(location => ({
                id: location.id,
                title: location.name,
                description: `${location.streetLine1}\n${location.city}, ${location.state}`,
              })) || [];
          result.push(...locations);
        });
        setButtons(result ? result : []);
      }
      if (type === 'users' && !detailsScreenProps) {
        const dif = getSettingsMainScreenData(type);

        const users: { [key: number]: any } = {};

        dif.forEach(id => {
          users[Number(id)] = masterSettings.users[Number(id)];
        });

        const result: IItemSelection[] = Object.values(users).map(
          (user: IUserObject) => ({
            id: user.organizationUserId,
            title: `${user.user.firstName} ${user.user.lastName}`,
            description: user.user.email,
            isActive: user.active,
          }),
        );
        setButtons(result ? result : []);
      }
      if (type === 'locationsWithRoles') {
        const { locationIds } = changedData as IUserObject;
        const locations = Object.values(locationGroups)
          .map(locationGroup => locationGroup.locations)
          .flat()
          .map(location => ({
            id: location.id,
            title: location.streetLine1,
            description: `${location.city}, ${location.state}`,
          }))
          .filter(
            location =>
              settingsAccessLocations.includes(location.id) &&
              !locationIds.includes(location.id),
          );

        setButtons(locations ? locations : []);
      }
      if (type === 'roles' && !detailsScreenProps) {
        const { roleIds } = changedData as IUserObject;
        const roles = Object.values(locationRoles)
          .map(role => ({
            id: role.id,
            title: role.name,
            isActive: role.active,
          }))
          .filter(role => !roleIds.includes(role.id));

        setButtons(roles ? roles : []);
      }
      setHasSet(true);
    } else {
      setHasSet(false);
    }
  }, [
    type,
    mainScreenProps,
    masterMenu,
    currentMenu,
    masterSettings,
    detailsScreenProps,
    locationGroups,
    active,
    extraLocationIds,
    settingsAccessLocations,
    changedData,
    locationRoles,
    isSettingsAccessLocations,
    currentSettings,
  ]);

  return (
    <>
      {Component && active && hasSet && (
        <Component
          buttons={buttons}
          active={active && hasSet}
          onModalClose={onModalClose}
          onAssociate={onAssociate}
          title={title}
          scrollCardSectionNumberOfColumns={scrollCardSectionNumberOfColumns}
          description={description}
          itemMenuTypeTemplate={itemMenuTypeTemplate}
          allowAssociateMultiple={allowAssociateMultiple}
          showDescriptionPanel={showDescriptionPanel}
          btnSuccessText={btnSuccessText}
          showSelectedButtons={showSelectedButtons}
          showOnly={showOnly}
          emptySectionMessage={emptySectionMessage}
          associateOnSelect={associateOnSelect}
          itemMultipleMenuTypeTemplate={itemMultipleMenuTypeTemplate}
          itemMultipleMenuTypeTemplateDescription={
            itemMultipleMenuTypeTemplateDescription
          }
          deleteAllMenuTypes={deleteAllMenuTypes}
          customOnDelete={customOnDelete}
          filterFunction={filterFunction}
          selectedMenuTypeIds={selectedMenuTypeIds}
          showSelectAll={showSelectAll}
          groupByMenuType={groupByMenuType}
          filterSelectedItems={filterSelectedItems}
          onFilter={onFilter as (option: unknown, id: number) => void}
          showSubcategoryDropdown={showSubcategoryDropdown}
          btnCancelText={btnCancelText}
          selectedButtonIds={selectedButtonIds}
        />
      )}
    </>
  );
};

export default SelectionModal;
