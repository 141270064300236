import Card from '@app/components/common/Card';
import Grid from '@app/components/common/Grid';
import MainScreenWithFilterContainer from '@app/components/common/MainScreenWithFilterContainer';
import { IMainScreenWithFilterContainer } from '@app/components/common/MainScreenWithFilterContainer/MainScreenWithFilterContainer';
import {
  getPathWithOrgData,
  openNewTabWithOrgData,
} from '@app/helpers/navigation';
import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';
import {
  IUserCard,
  settingsSelectors,
  usersCardFactory,
  usersSelectors,
} from '@westondev/tableturn-core';
import { useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import AddUserModal from './AddUserModal';

interface IUserWebCard extends IUserCard {
  roles: string[];
}

const UsersList = ({ t }: WithTranslation) => {
  const navigate = useNavigateWithOrg();

  const users = useSelector(usersSelectors.selectUsers);
  const roles = useSelector(settingsSelectors.selectRoles);

  const formattedUsersArray: IUserWebCard[] = useMemo(
    () =>
      usersCardFactory(users).map(userCard => ({
        ...userCard,
        roles: userCard.roleIds.map(roleId => roles[roleId]?.name),
      })),
    [users, roles],
  );

  const rolesInUsers = useMemo(
    () =>
      new Set(formattedUsersArray.map(userObject => userObject.roleIds).flat()),
    [formattedUsersArray],
  );

  const rolesOptionsFiltered = useMemo(
    () =>
      Object.values(roles)
        .map(role => ({
          value: role.id,
          label: role.name,
        }))
        .filter(roleObject => rolesInUsers.has(roleObject.value)),
    [roles, rolesInUsers],
  );

  const dropdownOptions = useMemo(
    () => [
      [{ label: t('commonTexts.all'), value: 0 }],
      [
        { label: t('commonTexts.active'), value: 1 },
        { label: t('commonTexts.inactive'), value: 2 },
      ],
    ],
    [t],
  );

  const dropdowns = useMemo(
    (): IMainScreenWithFilterContainer<IUserWebCard>['dropdowns'] => [
      {
        id: 'roleIds',
        label: 'Role',
        data: [
          [{ label: t('commonTexts.all'), value: 0 }],
          rolesOptionsFiltered,
        ],
        dataExample: 0,
        isMultiSelect: true,
        bucket: 'roles',
      },
      {
        id: 'activeFilter',
        label: t('commonTexts.status'),
        data: dropdownOptions,
        defaultValue: 1,
        dataExample: 1,
      },
    ],
    [dropdownOptions, rolesOptionsFiltered, t],
  );

  return (
    <MainScreenWithFilterContainer
      dropdowns={dropdowns}
      groupFilters
      noItemsText={t('commonTexts.noneFound', {
        elementName: t('screenNames.UsersSettingsScreen'),
      })}
      sortAvailable
      customButtons={<Grid.Item mb={0} sm={0} md={0} lg={4} xl={5} />}
      data={formattedUsersArray}
      optionList={<AddUserModal />}
      renderItem={user => {
        const pathURL = `/settings/accounts/users/${user.id}`;
        return (
          <Card.Item
            title={user.title}
            subTitle={user.email}
            showStatus
            isActive={user.isActive}
            onClick={openInNewTab => {
              if (openInNewTab) return openNewTabWithOrgData(pathURL);
              navigate(pathURL);
            }}
            isLink={getPathWithOrgData(pathURL)}
          />
        );
      }}
    />
  );
};

export default UsersList;
