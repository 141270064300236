import App from '@app/App';
import LoggedInLayout from '@app/components/LoggedIn/LoggedInLayout';
import AddEditEndOfShift from '@app/components/LoggedIn/Reports/EndOfShift/AddEditEndOfShift';
import EndOfShiftDetails from '@app/components/LoggedIn/Reports/EndOfShift/EndOfShiftDetails';
import AddEditItemComboSales from '@app/components/LoggedIn/Reports/ItemComboSales/AddEditItemComboSales';
import ItemComboSalesDetails from '@app/components/LoggedIn/Reports/ItemComboSales/ItemComboSalesDetails';
import AddEditSalesOverview from '@app/components/LoggedIn/Reports/SalesOverview/AddEditSalesOverview';
import SalesOverviewDetails from '@app/components/LoggedIn/Reports/SalesOverview/SalesOverviewDetails';
import RoleDetails from '@app/components/LoggedIn/Settings/Accounts/Roles/RoleDetails';
import UserDetails from '@app/components/LoggedIn/Settings/Accounts/Users/UserDetails';
import UnderDevelopment from '@app/components/common/UnderDevelopment';
import ForgotPassword from '@app/views/Authentication/ForgotPassword';
import OrgInvite from '@app/views/Authentication/OrgInvite';
import ResetPassword from '@app/views/Authentication/ResetPassword';
import SignIn from '@app/views/Authentication/SignIn';
import SignUp from '@app/views/Authentication/SignUp';
import VerifyCode from '@app/views/Authentication/VerifyCode';
import DesignSystem from '@app/views/DesignSystem';
import Customers from '@app/views/LoggedIn/Customers/Customers';
import CustomerDetails from '@app/views/LoggedIn/Customers/Edit/CustomerDetails';
import Home from '@app/views/LoggedIn/Home';
import Menu from '@app/views/LoggedIn/Menu';
import Categories from '@app/views/LoggedIn/Menu/Edit/Categories';
import CategoriesDetails from '@app/views/LoggedIn/Menu/Edit/Categories/CategoriesDetails';
import Combos from '@app/views/LoggedIn/Menu/Edit/Combos';
import CombosDetails from '@app/views/LoggedIn/Menu/Edit/Combos/CombosDetails';
import Discounts from '@app/views/LoggedIn/Menu/Edit/Discounts';
import DiscountDetails from '@app/views/LoggedIn/Menu/Edit/Discounts/DiscountDetails';
import Ingredients from '@app/views/LoggedIn/Menu/Edit/Ingredients';
import IngredientDetails from '@app/views/LoggedIn/Menu/Edit/Ingredients/IngredientDetails';
import ItemGroups from '@app/views/LoggedIn/Menu/Edit/ItemGroups';
import ItemGroupsDetails from '@app/views/LoggedIn/Menu/Edit/ItemGroups/ItemGroupsDetails';
import Items from '@app/views/LoggedIn/Menu/Edit/Items';
import ItemDetails from '@app/views/LoggedIn/Menu/Edit/Items/ItemDetails';
import MenuTypes from '@app/views/LoggedIn/Menu/Edit/MenuTypes';
import MenuTypesDetails from '@app/views/LoggedIn/Menu/Edit/MenuTypes/MenuTypesDetails';
import ModifierGroups from '@app/views/LoggedIn/Menu/Edit/ModifierGroups';
import ModifierGroupsDetails from '@app/views/LoggedIn/Menu/Edit/ModifierGroups/ModifierGroupsDetails';
import ModifierItems from '@app/views/LoggedIn/Menu/Edit/ModifierItems';
import ModifierItemsDetails from '@app/views/LoggedIn/Menu/Edit/ModifierItems/ModifierItemsDetails';
import ReportingGroups from '@app/views/LoggedIn/Menu/Edit/ReportingGroups';
import ReportingGroupDetails from '@app/views/LoggedIn/Menu/Edit/ReportingGroups/ReportingGroupDetails';
import Specials from '@app/views/LoggedIn/Menu/Edit/Specials';
import SpecialDetails from '@app/views/LoggedIn/Menu/Edit/Specials/SpecialDetails';
import Subcategories from '@app/views/LoggedIn/Menu/Edit/Subcategories';
import SubcategoryDetails from '@app/views/LoggedIn/Menu/Edit/Subcategories/SubcategoryDetails';
import FreshSheet from '@app/views/LoggedIn/Menu/FreshSheet';
import MyOrganizations from '@app/views/LoggedIn/MyOrganizations';
import Reports from '@app/views/LoggedIn/Reports';
import EndOfShift from '@app/views/LoggedIn/Reports/Edit/EndOfShift';
import ItemComboSales from '@app/views/LoggedIn/Reports/Edit/ItemComboSales';
import SalesOverview from '@app/views/LoggedIn/Reports/Edit/SalesOverview';
import Settings from '@app/views/LoggedIn/Settings';
import DevicesAndLicensesSettings from '@app/views/LoggedIn/Settings/DevicesAndLicenses';
import AccountsRolesSettings from '@app/views/LoggedIn/Settings/Edit/Accounts/AccountsRoles';
import AccountsUsersSettings from '@app/views/LoggedIn/Settings/Edit/Accounts/AccountsUsers';
import GeneralSettings from '@app/views/LoggedIn/Settings/Edit/General';
import OnlineOrderingSettings from '@app/views/LoggedIn/Settings/Edit/General/OnlineOrdering';
// import HomeScreenSettings from '@app/views/LoggedIn/Settings/Edit/HomeScreen';
import LockScreenSettings from '@app/views/LoggedIn/Settings/Edit/LockScreen';
// import ManagementSettings from '@app/views/LoggedIn/Settings/Edit/Management';
import AddEditCreditCardBatchReport from '@app/components/LoggedIn/Reports/CreditCardBatch/AddEditCreditCardBatchReport';
import CreditCardBatchReportDetails from '@app/components/LoggedIn/Reports/CreditCardBatch/CreditCardBatchReportDetails';
import AddEditEmployeeSalesAndTipsReport from '@app/components/LoggedIn/Reports/EmployeeSalesAndTips/AddEditEmployeeSalesAndTipsReport';
import EmployeeSalesAndTipsReportDetails from '@app/components/LoggedIn/Reports/EmployeeSalesAndTips/EmployeeSalesAndTipsReportDetails';
import AddEditVoidReasonsReport from '@app/components/LoggedIn/Reports/VoidReasons/AddEditVoidReasonsReport';
import VoidReasonsReportDetails from '@app/components/LoggedIn/Reports/VoidReasons/VoidReasonsReportDetails';
import CreditCardBatch from '@app/views/LoggedIn/Reports/Edit/CreditCardBatch';
import EmployeeSalesAndTips from '@app/views/LoggedIn/Reports/Edit/EmployeeSalesAndTips';
import VoidReasons from '@app/views/LoggedIn/Reports/Edit/VoidReasons';
import Payments from '@app/views/LoggedIn/Settings/Edit/Payments';
import GuestCheck from '@app/views/LoggedIn/Settings/Edit/Printing/GuestCheck';
import GuestReceipt from '@app/views/LoggedIn/Settings/Edit/Printing/GuestReceipt';
import KitchenTicket from '@app/views/LoggedIn/Settings/Edit/Printing/KitchenTicket';
import PrepStationsSettings from '@app/views/LoggedIn/Settings/Edit/Printing/PrepStations';
import PrintingRulesSettings from '@app/views/LoggedIn/Settings/Edit/Printing/PrintingRules';
import RegisterTicket from '@app/views/LoggedIn/Settings/Edit/Printing/RegisterTicket';
import RegisterModeSettings from '@app/views/LoggedIn/Settings/Edit/RegisterMode';
import AdsTable from '@app/views/LoggedIn/Settings/Edit/RegisterMode/AdsTable';
import CoursingSettings from '@app/views/LoggedIn/Settings/Edit/RegisterMode/Coursing';
import DiscountReasonsSettings from '@app/views/LoggedIn/Settings/Edit/RegisterMode/DiscountReasons';
import ModTagsSettings from '@app/views/LoggedIn/Settings/Edit/RegisterMode/ModTags';
import OpenItemTypesSettings from '@app/views/LoggedIn/Settings/Edit/RegisterMode/OpenItemTypes';
import SeatOrderTagsSettings from '@app/views/LoggedIn/Settings/Edit/RegisterMode/SeatOrderTags';
import SpecialTagsSettings from '@app/views/LoggedIn/Settings/Edit/RegisterMode/SpecialTags';
import TaxesSettings from '@app/views/LoggedIn/Settings/Edit/Taxes';
import TaxesDetails from '@app/views/LoggedIn/Settings/Edit/Taxes/TaxesDetails';
import LocationManagementSettings from '@app/views/LoggedIn/Settings/LocationManagement';
import TableLayoutSettings from '@app/views/LoggedIn/Settings/TableLayout';
import Verify from '@app/views/LoggedIn/User/Verify';
import { Navigate, Outlet, createBrowserRouter } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import PrivateRouteAuth from './PrivateRouteAuth';
import PrivateRouteUnVerified from './PrivateRouteUnVerified';
import PrivateRouteVerified from './PrivateRouteVerified';

const bucketPath = ({
  path,
  mainElement,
  detailsElement,
  addMassUpdatePath = false,
  massUpdateElement = detailsElement,
}: {
  path: string;
  mainElement: JSX.Element;
  detailsElement: JSX.Element;
  massUpdateElement?: JSX.Element;
  addMassUpdatePath?: boolean;
}) => ({
  path,
  element: mainElement,
  children: [
    {
      path: 'add',
      element: detailsElement,
    },
    {
      path: ':id',
      element: detailsElement,
    },
    ...(addMassUpdatePath
      ? [
          {
            path: 'mass-update',
            element: massUpdateElement,
          },
        ]
      : []),
  ],
});

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
  },
  {
    path: 'org-invite',
    element: <OrgInvite />,
  },

  {
    element: <PrivateRouteAuth />,
    children: [
      {
        path: 'auth/sign-in',
        index: true,
        element: <SignIn />,
      },
      {
        path: 'auth/forgot-password',
        element: <Outlet />,
        children: [
          {
            index: true,
            element: <ForgotPassword />,
          },
          {
            path: 'verify-code',
            element: <VerifyCode />,
          },
          {
            path: 'reset-password',
            element: <ResetPassword />,
          },
        ],
      },
      {
        path: 'auth/sign-up',
        element: <SignUp />,
      },
      {
        path: 'auth/sign-up-invite',
        element: <SignUp />,
      },
      {
        path: '*',
        element: <Navigate to="/auth/sign-in" replace />,
      },
    ],
  },
  {
    element: <PrivateRoute />,
    children: [
      {
        element: <PrivateRouteUnVerified />,
        children: [
          {
            path: 'verify',
            index: true,
            element: <Verify />,
          },
        ],
      },
      {
        element: <PrivateRouteVerified />,
        children: [
          {
            path: 'my-organizations',
            index: true,
            element: <MyOrganizations />,
          },
          {
            path: ':orgId/:locationId',
            element: <LoggedInLayout />,
            children: [
              {
                path: 'home',
                index: true,
                element: <Home />,
              },
              {
                path: 'menu',
                element: <Menu />,
                children: [
                  bucketPath({
                    path: 'menus',
                    mainElement: <MenuTypes />,
                    detailsElement: <MenuTypesDetails />,
                  }),
                  bucketPath({
                    path: 'categories',
                    mainElement: <Categories />,
                    detailsElement: <CategoriesDetails />,
                  }),
                  bucketPath({
                    path: 'subcategories',
                    mainElement: <Subcategories />,
                    detailsElement: <SubcategoryDetails />,
                  }),
                  bucketPath({
                    path: 'items',
                    mainElement: <Items />,
                    detailsElement: <ItemDetails />,
                    addMassUpdatePath: true,
                    massUpdateElement: <ItemDetails isMassUpdate />,
                  }),
                  {
                    path: 'modifiers',
                    element: <Outlet />,
                    children: [
                      bucketPath({
                        path: 'modifier-groups',
                        mainElement: <ModifierGroups />,
                        detailsElement: <ModifierGroupsDetails />,
                      }),
                      bucketPath({
                        path: 'modifier-items',
                        mainElement: <ModifierItems />,
                        detailsElement: <ModifierItemsDetails />,
                      }),
                      bucketPath({
                        path: 'ingredients',
                        mainElement: <Ingredients />,
                        detailsElement: <IngredientDetails />,
                      }),
                    ],
                  },
                  {
                    path: 'combos',
                    element: <Outlet />,
                    children: [
                      bucketPath({
                        path: 'combos',
                        mainElement: <Combos />,
                        detailsElement: <CombosDetails />,
                      }),
                      bucketPath({
                        path: 'item-groups',
                        mainElement: <ItemGroups />,
                        detailsElement: <ItemGroupsDetails />,
                      }),
                    ],
                  },
                  bucketPath({
                    path: 'specials',
                    mainElement: <Specials />,
                    detailsElement: <SpecialDetails />,
                  }),
                  bucketPath({
                    path: 'discounts',
                    mainElement: <Discounts />,
                    detailsElement: <DiscountDetails />,
                  }),
                  bucketPath({
                    path: 'reporting-groups',
                    mainElement: <ReportingGroups />,
                    detailsElement: <ReportingGroupDetails />,
                  }),
                ],
              },
              {
                path: 'menu/menu-locations',
                element: <UnderDevelopment pageName="Menu Locations" />,
              },
              {
                path: 'menu/fresh-sheet',
                element: <FreshSheet />,
              },
              {
                path: 'settings',
                element: <Settings />,
                children: [
                  {
                    path: 'general',
                    element: <Outlet />,
                    children: [
                      {
                        index: true,
                        path: 'general-settings',
                        element: <GeneralSettings />,
                      },
                      {
                        path: 'online-ordering',
                        element: <OnlineOrderingSettings />,
                      },
                    ],
                  },
                  {
                    path: 'accounts',
                    element: <Outlet />,
                    children: [
                      bucketPath({
                        path: 'users',
                        mainElement: <AccountsUsersSettings />,
                        detailsElement: <UserDetails />,
                      }),
                      bucketPath({
                        path: 'roles',
                        mainElement: <AccountsRolesSettings />,
                        detailsElement: <RoleDetails />,
                      }),
                    ],
                  },
                  {
                    path: 'printing',
                    element: <Outlet />,
                    children: [
                      {
                        path: 'prep-stations',
                        element: <PrepStationsSettings />,
                      },
                      {
                        path: 'printing-rules',
                        element: <PrintingRulesSettings />,
                      },
                      {
                        path: 'ticket',
                        element: <Outlet />,
                        children: [
                          {
                            path: 'register-ticket',
                            element: <RegisterTicket />,
                          },
                          {
                            path: 'kitchen-ticket',
                            element: <KitchenTicket />,
                          },
                          {
                            path: 'guest-check',
                            element: <GuestCheck />,
                          },
                          {
                            path: 'guest-receipt',
                            element: <GuestReceipt />,
                          },
                        ],
                      },
                    ],
                  },
                  // {
                  //   path: 'management',
                  //   element: <ManagementSettings />,
                  // },
                  {
                    path: 'lock-screen',
                    element: <LockScreenSettings />,
                  },
                  // {
                  //   path: 'home-screen',
                  //   element: <HomeScreenSettings />,
                  // },
                  {
                    path: 'register',
                    element: <RegisterModeSettings />,
                    children: [
                      {
                        path: 'special-tags',
                        element: <SpecialTagsSettings />,
                      },
                      {
                        path: 'seat-and-order-tags',
                        element: <SeatOrderTagsSettings />,
                      },
                      {
                        path: 'mod-tags',
                        element: <ModTagsSettings />,
                      },
                      {
                        path: 'discount-reasons',
                        element: <DiscountReasonsSettings />,
                      },
                      {
                        path: 'open-item-types',
                        element: <OpenItemTypesSettings />,
                      },
                      {
                        path: 'coursing',
                        element: <CoursingSettings />,
                      },
                      {
                        path: 'ads',
                        element: <AdsTable />,
                      },
                    ],
                  },
                  {
                    path: 'payments',
                    element: <Payments />,
                  },
                  bucketPath({
                    path: 'taxes',
                    mainElement: <TaxesSettings />,
                    detailsElement: <TaxesDetails />,
                  }),
                ],
              },
              {
                path: 'settings/location-management',
                element: <LocationManagementSettings />,
              },
              {
                path: 'settings/devices-and-licenses',
                element: <DevicesAndLicensesSettings />,
              },
              {
                path: 'settings/table-layout',
                element: <TableLayoutSettings />,
              },
              {
                path: 'reports',
                element: <Reports />,
                children: [
                  {
                    path: 'item-combo-sales',
                    element: <ItemComboSales />,
                    children: [
                      {
                        path: 'add',
                        element: <AddEditItemComboSales />,
                      },
                      {
                        path: 'edit/:id',
                        element: <AddEditItemComboSales />,
                      },
                      {
                        path: ':id',
                        element: <ItemComboSalesDetails />,
                      },
                    ],
                  },
                  {
                    path: 'sales-overview',
                    element: <SalesOverview />,
                    children: [
                      {
                        path: 'add',
                        element: <AddEditSalesOverview />,
                      },
                      {
                        path: 'edit/:id',
                        element: <AddEditSalesOverview />,
                      },
                      {
                        path: ':id',
                        element: <SalesOverviewDetails />,
                      },
                    ],
                  },
                  {
                    path: 'end-of-shift',
                    element: <EndOfShift />,
                    children: [
                      {
                        path: 'add',
                        element: <AddEditEndOfShift />,
                      },
                      {
                        path: 'edit/:id',
                        element: <AddEditEndOfShift />,
                      },
                      {
                        path: ':id',
                        element: <EndOfShiftDetails />,
                      },
                    ],
                  },
                  {
                    path: 'credit-card-batch',
                    element: <CreditCardBatch />,
                    children: [
                      {
                        path: 'add',
                        element: <AddEditCreditCardBatchReport />,
                      },
                      {
                        path: 'edit/:id',
                        element: <AddEditCreditCardBatchReport />,
                      },
                      {
                        path: ':id',
                        element: <CreditCardBatchReportDetails />,
                      },
                    ],
                  },
                  {
                    path: 'employee-sales-and-tips',
                    element: <EmployeeSalesAndTips />,
                    children: [
                      {
                        path: 'add',
                        element: <AddEditEmployeeSalesAndTipsReport />,
                      },
                      {
                        path: 'edit/:id',
                        element: <AddEditEmployeeSalesAndTipsReport />,
                      },
                      {
                        path: ':id',
                        element: <EmployeeSalesAndTipsReportDetails />,
                      },
                    ],
                  },
                  {
                    path: 'void-reasons',
                    element: <VoidReasons />,
                    children: [
                      {
                        path: 'add',
                        element: <AddEditVoidReasonsReport />,
                      },
                      {
                        path: 'edit/:id',
                        element: <AddEditVoidReasonsReport />,
                      },
                      {
                        path: ':id',
                        element: <VoidReasonsReportDetails />,
                      },
                    ],
                  },
                ],
              },
              {
                path: 'reservations',
                element: <UnderDevelopment pageName="Reservations" />,
              },
              {
                path: 'customers',
                element: <Customers />,
                children: [
                  {
                    path: 'add',
                    element: <CustomerDetails />,
                  },
                  {
                    path: ':id',
                    element: <CustomerDetails />,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },

  {
    path: 'design-system',
    element: <DesignSystem />,
  },
  {
    path: '*',
    element: <Navigate to="/" replace />,
  },
]);

export default router;
