import Button from '@app/components/common/Button';
import Icon from '@app/components/common/Icon';
import Typography from '@app/components/common/Typography';
import { useTheme } from '@emotion/react';

const LinesBeforeButton = ({
  onClick,
  count,
}: {
  onClick?: () => void;
  count: number;
}) => {
  const theme = useTheme();
  return (
    <Button
      disableHoverEffect
      onClick={onClick}
      csx={{
        padding: '3px',
        border: `1px solid ${theme.colors.darkBlue}`,
        backgroundColor: theme.colors.lighterBlue,
        borderRadius: '3px',
        width: '30px',
        height: '30px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <Typography align="center" color="darkBlue" variant="caption">
        {count || 0}
      </Typography>
      <Icon name="MdWrapText" size="15px" color="darkBlue" />
    </Button>
  );
};

export default LinesBeforeButton;
