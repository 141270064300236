import { MQ_MIN_MEDIUM, MQ_MIN_SMALL } from '@app/theme/types';
import { Theme, css } from '@emotion/react';
import { CARD_HEIGHT } from './types';

export const organizationCardStyles = (theme: Theme) =>
  css({
    width: 'auto',
    height: `${parseInt(CARD_HEIGHT) - 110}px`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    borderRadius: '15px',
    userSelect: 'none',
    boxShadow: theme.shadows[1],
    ':focus': {
      outline: '2px solid transparent',
    },
    ':hover': {
      cursor: 'pointer',
      boxShadow: theme.shadows[2],
      '.logo': {
        backgroundColor: theme.colors.persistentSemanticBlue,
      },
      '.organizationName': {
        backgroundColor: theme.colors.persistentSemanticBlue,
      },
    },
    '.logo': {
      borderRadius: '15px 15px 0px 0px',
      width: '100%',
      height: '0px',
      flexGrow: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.colors.persistentDarkerBlue,
      '> img': {
        height: '100%',
        width: '100%',
        objectFit: 'contain',
      },
      '> svg': {
        height: '100%',
        width: '90%',
      },
    },
    '.organizationName': {
      borderRadius: '0px 0px 15px 15px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexGrow: 0,
      width: '100%',
      height: '41px',
      backgroundColor: theme.colors.persistentDarkerBlue,
    },
    [MQ_MIN_SMALL]: {
      height: '240px',
      '.organizationName': {
        height: '62px',
      },
    },
    [MQ_MIN_MEDIUM]: {
      height: CARD_HEIGHT,
    },
  });

export const createOrganizationCardStyles = (theme: Theme) =>
  css({
    width: 'auto',
    height: `${parseInt(CARD_HEIGHT, 10) - 114}px`,
    border: `2px dashed ${theme.colors.lightGrey}`,
    borderRadius: '15px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
    userSelect: 'none',
    svg: {
      width: '35px',
    },
    ':hover': {
      cursor: 'pointer',
      boxShadow: theme.shadows[1],
      border: `2px dashed ${theme.colors.persistentSemanticBlue}`,
      backgroundColor: theme.colors.lighterBlue,
      p: {
        color: theme.colors.persistentSemanticBlue,
      },
      svg: {
        fill: theme.colors.persistentSemanticBlue,
      },
    },
    [MQ_MIN_SMALL]: {
      height: '240px',
    },
    [MQ_MIN_MEDIUM]: {
      height: parseInt(CARD_HEIGHT, 10) - 4 + 'px',
      svg: {
        width: '45px',
      },
    },
  });

export const skeletonStyles = () =>
  css({
    width: 'auto',
    height: '150px',
    [MQ_MIN_SMALL]: {
      height: '240px',
    },
    [MQ_MIN_MEDIUM]: {
      height: parseInt(CARD_HEIGHT) - 4 + 'px',
    },
  });
