import {
  DEFAULT_OPTIONS_LIST,
  MENU_BASE_TRANSLATION,
  MenuActionBtnTypes,
} from '@app/components/common/ActionButtons';
import { IActionOption } from '@app/components/common/ActionButtons/types';
import Box from '@app/components/common/Box';
import Card from '@app/components/common/Card';
import Grid from '@app/components/common/Grid';
import MainScreenWithFilterContainer from '@app/components/common/MainScreenWithFilterContainer';
import Switch from '@app/components/common/Switch';
import { getMainBreadcrumbBucket } from '@app/helpers/factories/menu';
import {
  getPathWithOrgData,
  openNewTabWithOrgData,
} from '@app/helpers/navigation';
import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';
import useSessionStorageState from '@app/hooks/useSessionStorageState';
import { actionCreatorsMenuWeb } from '@app/state';
import { initializeBucketListBreadcrumb } from '@app/state/app/actions';
import {
  selectBreadCrumbs,
  selectCardDiscountList,
  selectIsMenuMasterMode,
} from '@app/state/menu/menuSelectors';
import { useAppDispatch } from '@app/state/store';
import { bindActionCreators } from '@reduxjs/toolkit';
import { BreadCrumbAction } from '@westondev/tableturn-core';
import { ParseKeys } from 'i18next';
import zipWith from 'lodash/zipWith';
import { useCallback, useEffect, useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import DiscountsTable from './DiscountsTable';

const DiscountsList = ({ t }: WithTranslation) => {
  // Redux
  const dispatch = useAppDispatch();

  const addBreadcrumbLocationWeb = bindActionCreators(
    actionCreatorsMenuWeb.addBreadcrumbLocationWeb,
    dispatch,
  );

  const discounts = useSelector(selectCardDiscountList);
  const breadcrumbs = useSelector(selectBreadCrumbs);
  const isMasterMenu = useSelector(selectIsMenuMasterMode);

  const [showListView, setShowListView] = useSessionStorageState(
    'showListView',
    true,
  );

  const navigate = useNavigateWithOrg();

  const dropdownOptions = useMemo(
    () => [
      [{ label: 'All', value: 0 }],
      [
        { label: t('commonTexts.active'), value: 1 },
        { label: t('commonTexts.inactive'), value: 2 },
      ],
    ],
    [t],
  );

  const handleAddNewDiscount = useCallback(() => {
    addBreadcrumbLocationWeb({
      action: BreadCrumbAction.ADD,
      text: t('menuScreen.menuLocation.actionButtons.addNewDiscountTag'),
      onPress: () => navigate('/menu/discounts/add'),
      pathURL: '/menu/discounts/add',
    });
  }, [addBreadcrumbLocationWeb, navigate, t]);

  const actionDropdownOptions = useMemo(() => {
    const optionsArray = isMasterMenu
      ? DEFAULT_OPTIONS_LIST[MenuActionBtnTypes.CREATE_NEW_DISCOUNT]
      : DEFAULT_OPTIONS_LIST[MenuActionBtnTypes.CREATE_NEW_DISCOUNT_LOCATION];

    const handlersArray = isMasterMenu
      ? [handleAddNewDiscount]
      : [handleAddNewDiscount];

    return zipWith(
      optionsArray,
      handlersArray,
      ([text, icon], handler): IActionOption => ({
        text: t(`${MENU_BASE_TRANSLATION}.${text}` as ParseKeys),
        handler,
        icon,
      }),
    );
  }, [handleAddNewDiscount, isMasterMenu, t]);

  useEffect(() => {
    if (!breadcrumbs.length) {
      const navMain = getMainBreadcrumbBucket(8, null, navigate);
      if (!navMain) return;
      dispatch(initializeBucketListBreadcrumb(navMain));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [breadcrumbs]);

  return (
    <>
      <MainScreenWithFilterContainer
        dropdowns={[
          {
            id: 'activeFilter',
            label: t('commonTexts.status'),
            data: dropdownOptions,
            defaultValue: 1,
            dataExample: 1,
          },
        ]}
        noItemsText={t('commonTexts.noneFound', {
          elementName: t('screenNames.DiscountScreen'),
        })}
        data={discounts}
        optionList={actionDropdownOptions}
        sortAvailable={!showListView}
        groupFilters
        showChildren={showListView}
        customButtons={
          <Grid.Item
            mb={12}
            sm={showListView ? 7 : 6}
            md={showListView ? 7 : 6}
            lg={showListView ? 5 : 4}
            xl={showListView ? 6 : 5}>
            <Box
              csx={theme => ({
                display: 'flex',
                alignItems: 'center',
                height: '100%',
                borderLeft: `1px solid ${theme.colors.lightGrey}`,
                paddingLeft: '15px',
              })}>
              <Switch
                checked={showListView}
                onChange={isActive => setShowListView(isActive)}
                label={t('menuScreen.itemsMain.showListView')}
              />
            </Box>
          </Grid.Item>
        }
        renderItem={discount => {
          const pathURL = `/menu/discounts/${discount.id}`;
          return (
            <Card.Item
              title={discount.title}
              isActive={discount.isActive}
              showStatus
              onClick={openInNewTab => {
                if (openInNewTab) return openNewTabWithOrgData(pathURL);

                addBreadcrumbLocationWeb({
                  action: BreadCrumbAction.NAV,
                  text: discount.title,
                  onPress: () => {
                    navigate(pathURL);
                  },
                  statusPath: `selectedMode.currentMenu.discounts.${discount.id}.active`,
                  pathURL,
                });
              }}
              isLink={getPathWithOrgData(pathURL)}
            />
          );
        }}>
        {filterParams => <DiscountsTable filterParams={filterParams} />}
      </MainScreenWithFilterContainer>
    </>
  );
};

export default DiscountsList;
