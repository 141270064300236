import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import { IAccordionSection } from '@app/components/common/Accordion/AccordionSection/types';
import Checkbox from '@app/components/common/Checkbox';
import Divider from '@app/components/common/Divider';
import Dropdown from '@app/components/common/Dropdown';
import Grid from '@app/components/common/Grid';
import MoneyInput from '@app/components/common/MoneyInput';
import { makeSelectItemData } from '@app/state/menu/menuSelectors';
import { displayFlexEndColumnStyles } from '@app/theme/commonStyles';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  IDiscount,
  SectionId,
  TApplyTo,
  TDiscountTaxesType,
  TDiscountType,
  actionCreatorsMenu,
} from '@westondev/tableturn-core';
import { useEffect, useMemo, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

const APPLY_TO_OPTIONS: Record<number, TApplyTo> = {
  1: TApplyTo.ITEM,
  2: TApplyTo.ORDER,
};

const TAXES_OPTIONS: Record<number, TDiscountTaxesType> = {
  1: TDiscountTaxesType.APPLY_BEFORE,
  2: TDiscountTaxesType.APPLY_AFTER,
};

const DISCOUNT_TYPE_OPTIONS: Record<number, TDiscountType> = {
  1: TDiscountType.PERCENT,
  2: TDiscountType.FIXED,
  3: TDiscountType.OVERRIDE,
};

const SECTION_ID = SectionId.DISCOUNT_SCHEME;
const getMemoizedItemData = makeSelectItemData('discounts', SECTION_ID);

const DiscountSchemeSection = ({
  t,
  ...props
}: IAccordionSection & WithTranslation) => {
  const dispatch = useDispatch();
  const { updateMenuBucket: setValue } = bindActionCreators(
    actionCreatorsMenu,
    dispatch,
  );
  const { itemData } = useSelector(getMemoizedItemData);

  const [isApplyToItem, setIsApplyToItem] = useState(false);

  const applyToDropdownValue = useMemo(
    () =>
      Number(
        Object.keys(APPLY_TO_OPTIONS).find(
          key => APPLY_TO_OPTIONS[Number(key)] === itemData?.applyTo,
        ),
      ),
    [itemData?.applyTo],
  );

  useEffect(() => {
    setIsApplyToItem(
      applyToDropdownValue ===
        Object.values(APPLY_TO_OPTIONS).indexOf(TApplyTo.ITEM) + 1,
    );
  }, [applyToDropdownValue]);

  const taxesDropdownValue = useMemo(
    () =>
      Number(
        Object.keys(TAXES_OPTIONS).find(
          key => TAXES_OPTIONS[Number(key)] === itemData?.taxes,
        ),
      ),
    [itemData?.taxes],
  );

  const typeDropdownValue = useMemo(
    () =>
      Number(
        Object.keys(DISCOUNT_TYPE_OPTIONS).find(
          key => DISCOUNT_TYPE_OPTIONS[Number(key)] === itemData?.type,
        ),
      ),
    [itemData?.type],
  );

  const isPercentage = useMemo(
    () => itemData?.type === TDiscountType.PERCENT,
    [itemData?.type],
  );

  const isOverride = useMemo(
    () => itemData?.type === TDiscountType.OVERRIDE,
    [itemData?.type],
  );

  const setApplyToValue = (applyTo: number) => {
    const newValue =
      applyTo in APPLY_TO_OPTIONS ? APPLY_TO_OPTIONS[applyTo] : undefined;

    const changes: Partial<IDiscount> = {
      applyTo: newValue,
    };

    if (newValue === TApplyTo.ITEM) {
      changes.taxes = TDiscountTaxesType.APPLY_BEFORE;
    }

    setValue(changes, SECTION_ID);
  };

  return (
    <AccordionSection
      title={t('app.modals.diffModal.sectionTitles.discountScheme')}
      titleTextContainerCsx={{ minWidth: '170px' }}
      {...props}>
      <>
        <Grid rowGap={20} columnGap={20}>
          <Grid.Item
            mb={12}
            sm={6}
            md={6}
            lg={3}
            csx={displayFlexEndColumnStyles}>
            <Dropdown
              label={t(
                'menuScreen.discountDetails.discountScheme.inputs.applyTo.label',
              )}
              placeholder={t(
                'menuScreen.discountDetails.discountScheme.inputs.applyTo.placeholder',
              )}
              info={t(
                'menuScreen.discountDetails.discountScheme.inputs.applyTo.toolTip',
              )}
              data={[
                [
                  {
                    label: t(
                      'menuScreen.discountDetails.discountScheme.inputs.applyTo.options.item',
                    ),
                    value: 1,
                  },
                  {
                    label: t(
                      'menuScreen.discountDetails.discountScheme.inputs.applyTo.options.order',
                    ),
                    value: 2,
                  },
                ],
              ]}
              onChange={setApplyToValue}
              value={applyToDropdownValue}
            />
          </Grid.Item>
          <Grid.Item
            mb={12}
            sm={6}
            md={6}
            lg={3}
            csx={displayFlexEndColumnStyles}>
            <Dropdown
              label={t(
                'menuScreen.discountDetails.discountScheme.inputs.discountType.label',
              )}
              placeholder={t(
                'menuScreen.discountDetails.discountScheme.inputs.discountType.placeholder',
              )}
              info={t(
                'menuScreen.discountDetails.discountScheme.inputs.discountType.toolTip',
              )}
              data={[
                [
                  {
                    label: t(
                      'menuScreen.discountDetails.discountScheme.inputs.discountType.options.percent',
                    ),
                    value: 1,
                  },
                  {
                    label: t(
                      'menuScreen.discountDetails.discountScheme.inputs.discountType.options.fixed',
                    ),
                    value: 2,
                  },
                  {
                    label: t(
                      'menuScreen.discountDetails.discountScheme.inputs.discountType.options.override',
                    ),
                    value: 3,
                  },
                ],
              ]}
              onChange={discountType => {
                const newValue =
                  discountType in DISCOUNT_TYPE_OPTIONS
                    ? DISCOUNT_TYPE_OPTIONS[discountType]
                    : undefined;
                setValue(
                  {
                    type: newValue,
                    value:
                      newValue === TDiscountType.PERCENT &&
                      itemData?.value > 100
                        ? 100
                        : itemData?.value,
                  },
                  SECTION_ID,
                );
              }}
              value={typeDropdownValue}
            />
          </Grid.Item>
          <Grid.Item
            mb={12}
            sm={6}
            md={6}
            lg={3}
            csx={displayFlexEndColumnStyles}>
            <MoneyInput
              label={t(
                `menuScreen.discountDetails.discountScheme.inputs.value.${
                  isPercentage ? 'percentage' : 'amount'
                }.label`,
              )}
              info={
                isOverride
                  ? undefined
                  : t(
                      `menuScreen.discountDetails.discountScheme.inputs.value.${
                        isPercentage ? 'percentage' : 'amount'
                      }.toolTip`,
                    )
              }
              prefix={isPercentage ? '' : '$'}
              placeholder="0"
              suffix={isPercentage ? '%' : ''}
              precision={isPercentage ? 0 : 2}
              maxValue={isPercentage ? 100 : undefined}
              value={itemData?.value}
              onValueChange={value => setValue({ value }, SECTION_ID)}
            />
          </Grid.Item>
        </Grid>
        <Divider
          csx={{
            marginTop: 20,
            marginBottom: 20,
          }}
        />
        <Grid rowGap={20} columnGap={20}>
          <Grid.Item
            mb={12}
            sm={6}
            md={6}
            lg={3}
            csx={displayFlexEndColumnStyles}>
            <Dropdown
              label={t(
                'menuScreen.discountDetails.discountScheme.inputs.taxes.label',
              )}
              placeholder={t(
                'menuScreen.discountDetails.discountScheme.inputs.taxes.placeholder',
              )}
              info={t(
                'menuScreen.discountDetails.discountScheme.inputs.taxes.toolTip',
              )}
              data={[
                [
                  {
                    label: t(
                      'menuScreen.discountDetails.discountScheme.inputs.taxes.options.applyBefore',
                    ),
                    value: 1,
                  },
                  {
                    label: t(
                      'menuScreen.discountDetails.discountScheme.inputs.taxes.options.applyAfter',
                    ),
                    value: 2,
                  },
                ],
              ]}
              onChange={taxes => {
                const newValue =
                  taxes in TAXES_OPTIONS ? TAXES_OPTIONS[taxes] : undefined;
                setValue(
                  {
                    taxes: newValue,
                  },
                  SECTION_ID,
                );
              }}
              value={
                isApplyToItem
                  ? Object.values(TAXES_OPTIONS).indexOf(TDiscountTaxesType.APPLY_BEFORE) + 1
                  : taxesDropdownValue
              }
              isDisabled={isApplyToItem}
            />
          </Grid.Item>
          <Grid.Item
            mb={12}
            sm={6}
            md={6}
            lg={3}
            csx={[displayFlexEndColumnStyles, { paddingBottom: 13 }]}>
            <Checkbox
              checked={itemData?.exclusive}
              onChange={exclusive => setValue({ exclusive }, SECTION_ID)}
              label={t(
                'menuScreen.discountDetails.discountScheme.inputs.exclusive.label',
              )}
            />
          </Grid.Item>
        </Grid>
      </>
    </AccordionSection>
  );
};

export default DiscountSchemeSection;
