import {
  ICategory,
  ICombo,
  IDiscount,
  IIngredient,
  IItem,
  IItemGroup,
  IMenuStore,
  IMenuType,
  IModifierGroups,
  IModifierItem,
  IReportingGroup,
  ISettingsStore,
  ISpecial,
  ISubcategory,
  menuCategoryCardFactory,
  menuDiscountCardFactory,
  menuIngredientCardFactory,
  menuItemGroupCardFactory,
  menuModifierGroupsCardFactory,
  menuModifierItemCardFactory,
  menuReportingGroupCardFactory,
  menuSpecialCardFactory,
  menuSubcategoryCardFactory,
  menuTypeCardFactory,
} from '@westondev/tableturn-core';
import { menuComboCardFactory } from './menu/cardFactories/combos/menuComboCardFactory';
import { menuItemCardFactory } from './menu/cardFactories/items/menuItemCardFactory';

export const factoriesEntitiesMap = (
  entity: keyof IMenuStore,
  values: IMenuStore[keyof IMenuStore],
) => {
  let result;
  // TODO: add factories for all the menu buckets
  switch (entity) {
    case 'items':
      result = menuItemCardFactory(values as Record<number, IItem>);
      break;
    case 'combos':
      result = menuComboCardFactory(values as Record<number, ICombo>);
      break;
    case 'categories':
      result = menuCategoryCardFactory(values as { [key: number]: ICategory });
      break;
    case 'subcategories':
      result = menuSubcategoryCardFactory(
        values as Record<number, ISubcategory>,
      );
      break;
    case 'specials':
      result = menuSpecialCardFactory(values as Record<number, ISpecial>);
      break;
    case 'menuTypes':
      result = menuTypeCardFactory(values as Record<number, IMenuType>);
      break;
    case 'discounts':
      result = menuDiscountCardFactory(values as Record<number, IDiscount>);
      break;
    case 'modifierGroups':
      result = menuModifierGroupsCardFactory(
        values as Record<number, IModifierGroups>,
      );
      break;
    case 'ingredients':
      result = menuIngredientCardFactory(values as Record<number, IIngredient>);
      break;
    case 'modifierItems':
      result = menuModifierItemCardFactory(
        values as Record<number, IModifierItem>,
      );
      break;

    case 'itemGroups':
      result = menuItemGroupCardFactory(
        values as { [key: number]: IItemGroup },
      );

      break;
    case 'reportingGroups':
      result = menuReportingGroupCardFactory(
        values as { [key: number]: IReportingGroup },
      );
      break;

    default:
      break;
  }

  if (result) {
    result = result.map(item => ({
      ...item,
      subcategoryId: 0,
      ...('status' in item ? { isActive: item.status } : {}),
      ...('itemCount' in item ? { count: item.itemCount } : {}),
    }));
  }
  return result;
};

export const factoriesSettingsEntitiesMap = (
  entity: keyof ISettingsStore,
  values: ISettingsStore[keyof ISettingsStore],
) => {
  let result;
  switch (entity) {
    case 'roles':
      result = Object.values(values).map(role => ({
        id: role.id,
        title: role.name,
        isActive: role.active,
      }));
      break;
    default:
      break;
  }

  return result;
};
