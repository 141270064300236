import { IFilterParams } from '@app/components/common/MainScreenWithFilterContainer/MainScreenWithFilterContainer';
import { createColumnHelper } from '@tanstack/react-table';
import { IItemTableRow } from '@westondev/tableturn-core';
import { WithTranslation } from 'react-i18next';

export const columnHelper = createColumnHelper<IItemTableRow>();

export const ITEMS_TABLE_ID = 'main-screen-items-table';
export const ROW_ID_PREFIX = 'mainScreen-items-row-';

export type IMainScreenTable = WithTranslation & {
  filterParams: IFilterParams;
};
