import BlueDot from '@app/components/common/BlueDot';
import Box from '@app/components/common/Box';
import ButtonLink from '@app/components/common/Button/ButtonLink';
import Checkbox from '@app/components/common/Checkbox';
import Divider from '@app/components/common/Divider';
import Icon from '@app/components/common/Icon';
import SelectionModal from '@app/components/common/SelectionModal';
import { IItemSelection } from '@app/components/common/SelectionModal/GenericSelectionModal/types';
import {
  getPathWithOrgData,
  openNewTabWithOrgData,
} from '@app/helpers/navigation';
import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';
import useRefreshMenuWeb from '@app/hooks/useRefreshMenuWeb';
import { actionCreatorsMenuWeb } from '@app/state';
import { selectItems } from '@app/state/menu/menuSelectors';
import {
  actionCreatorsMenu,
  BreadCrumbAction,
  IComboErrorFields,
  IItemGroupOfCombo,
  SectionId,
  useFormError,
} from '@westondev/tableturn-core';
import { useMemo, useRef, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

interface INestedModifierGroupCardOptions extends WithTranslation {
  itemGroupId: number;
  required: boolean;
  menuTypeVersionId: number;
  itemGroup: IItemGroupOfCombo;
  isMasterMode: boolean;
}

const SECTION_ID = SectionId.ITEM_GROUPS;

const ItemsGroupCardHeaderOptions = ({
  itemGroupId,
  menuTypeVersionId,
  required,
  itemGroup,
  isMasterMode,
  t,
}: INestedModifierGroupCardOptions) => {
  const dispatch = useDispatch();
  const { updateCombo: setValue, associateItemToItemGroup } =
    bindActionCreators(actionCreatorsMenu, dispatch);

  const addBreadcrumbLocationWeb = bindActionCreators(
    actionCreatorsMenuWeb.addBreadcrumbLocationWeb,
    useDispatch(),
  );

  const { checkForChangesAndNavigateWeb } = bindActionCreators(
    actionCreatorsMenuWeb,
    useDispatch(),
  );

  const navigate = useNavigateWithOrg();
  const onRefresh = useRefreshMenuWeb();
  const listItemRef = useRef<HTMLDivElement>(null);

  const [openAddExistingItem, setOpenAddExistingItem] = useState(false);

  const menuItems = useSelector(selectItems);

  const itemsError = useFormError<IComboErrorFields>(
    `menuTypeVersions.${menuTypeVersionId}.itemGroups.${itemGroupId}.items`,
  );

  const itemIds = useMemo(
    () => Object.values(itemGroup?.items || {}).map(item => `${item.itemId}`),
    [itemGroup?.items],
  );

  const handleAssociateItems = (itemsSelected: IItemSelection[]) => {
    associateItemToItemGroup(itemsSelected, itemGroup.items);
    setOpenAddExistingItem(false);
    itemsError?.clear();
  };

  const filterItemsFunction = (selectedItems: string[]) => {
    return selectedItems.filter(
      item => !!menuItems[Number(item)]?.menuTypeVersions?.[menuTypeVersionId],
    );
  };

  const pathURL = `/menu/combos/item-groups/${itemGroup.itemGroupId}`;

  const pathURLWithOrg = getPathWithOrgData(pathURL);

  return (
    <>
      <SelectionModal
        type="items"
        detailsScreenProps={{
          currentRelationsIds: itemIds,
          wantedEntity: 'items',
          filterFunction: filterItemsFunction,
        }}
        active={openAddExistingItem}
        onModalClose={() => setOpenAddExistingItem(false)}
        onAssociate={buttons => handleAssociateItems(buttons)}
      />
      <Box
        ref={listItemRef}
        csx={{
          display: 'flex',
          alignItems: 'center',
          gap: '15px',
        }}>
        <Box>
          <Checkbox
            checked={required}
            label={
              <Box
                csx={{
                  display: 'flex',
                  gap: '5px',
                  alignItems: 'center',
                }}>
                {(itemGroup as IItemGroupOfCombo)?.override?.required && (
                  <Box>
                    <BlueDot />
                  </Box>
                )}{' '}
                {t('commonTexts.required')}
              </Box>
            }
            onChange={value => {
              setValue(
                { required: value },
                SECTION_ID,
                menuTypeVersionId,
                itemGroupId,
              );
            }}
            isDisabled={!itemGroup.active && !isMasterMode}></Checkbox>
        </Box>
        <Divider
          direction="vertical"
          lineCsx={theme => ({
            borderColor: theme.colors.lightGrey,
            height: '35px',
          })}
        />

        <ButtonLink
          to={pathURLWithOrg}
          variant="primary"
          iconPosition="right"
          icon={<Icon name="MdExitToApp" />}
          onClick={openInNewTab => {
            if (openInNewTab) return openNewTabWithOrgData(pathURL);

            checkForChangesAndNavigateWeb(
              () =>
                addBreadcrumbLocationWeb({
                  action: BreadCrumbAction.NAV,
                  text: itemGroup.name,
                  onPress: () => navigate(pathURLWithOrg),
                  pathURL: pathURLWithOrg,
                }),
              onRefresh,
            );
          }}>
          {t('menuScreen.comboDetails.itemGroups.editItemGroup')}
        </ButtonLink>
      </Box>
    </>
  );
};

export default ItemsGroupCardHeaderOptions;
