import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import Icon from '@app/components/common/Icon';
import { IFilterParams } from '@app/components/common/MainScreenWithFilterContainer/MainScreenWithFilterContainer';
import Table from '@app/components/common/Table';
import Typography from '@app/components/common/Typography';
import { REPORTS_SCREEN_BY_BUCKET_NAME } from '@app/constants';
import { getPathWithOrgData } from '@app/helpers/navigation';
import useRootSelector from '@app/hooks/useRootSelector';
import { deleteReportWeb } from '@app/state/reports/reportsWebActions';
import { bindActionCreators } from '@reduxjs/toolkit';
import { ColumnHelper, FilterFns, Row } from '@tanstack/react-table';
import {
  EReportsType,
  filter,
  getRepeatTypeTranslation,
  IEndOfShiftReport,
  IItemComboSalesReport,
  IReportsStore,
  reportsSelectors,
  TReportRow,
  useSort,
} from '@westondev/tableturn-core';
import { useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ItemComboTableCategoryCell from './ItemComboTableSpecialCells/ItemComboTableCategoryCell';
import ItemComboTableGroupByCell from './ItemComboTableSpecialCells/ItemComboTableGroupByCell';
import ItemComboTableOrderTypesCell from './ItemComboTableSpecialCells/ItemComboTableOrderTypesCell';
import ItemComboTableOwnerIdsCell from './ItemComboTableSpecialCells/ItemComboTableOwnerIdsCell';
import ItemComboTableReportingGroupCell from './ItemComboTableSpecialCells/ItemComboTableReportingGroupCell';
import ItemComboTableSubcategoryCell from './ItemComboTableSpecialCells/ItemComboTableSubcategoryCell';
import { itemsTableNameStyles } from './styles';
import { columnHelper, ITEM_COMBO_SALES_ROW_ID_PREFIX } from './types';

type IReportsTable = WithTranslation & {
  bucket?: keyof IReportsStore;
  filterParams: IFilterParams;
};

const ItemComboSalesTable = ({
  filterParams,
  bucket = 'itemComboSalesReports',
  t,
}: IReportsTable) => {
  const handleDelete = bindActionCreators(deleteReportWeb, useDispatch());
  const onSort = useSort<TReportRow<IItemComboSalesReport>>('title');

  const navigate = useNavigate();

  const isMasterMode = useRootSelector(
    state => state.reports.selectedMode.type === EReportsType.MASTER,
  );

  const selectorString = useMemo(() => {
    switch (bucket) {
      case 'itemComboSalesReports':
        return 'selectItemComboSalesReportsArray';
      case 'salesOverviewReports':
        return 'selectSalesOverviewReportsArray';
      case 'creditCardBatchReports':
        return 'selectCreditCardBatchReportsArray';
      case 'employeeSalesAndTipsReports':
        return 'selectEmployeeSalesAndTipsReportsArray';
      case 'endOfShiftReports':
        return 'selectEndOfShiftReportsArray';
      case 'voidReasonsReports':
        return 'selectVoidReasonsReportsArray';
    }
  }, [bucket]);

  const reportIdKey = useMemo(() => {
    switch (bucket) {
      case 'itemComboSalesReports':
        return 'itemComboSalesReportId';
      case 'salesOverviewReports':
        return 'salesOverviewReportId';
      case 'creditCardBatchReports':
        return 'creditCardBatchReportId';
      case 'employeeSalesAndTipsReports':
        return 'employeeSalesAndTipsReportId';
      case 'endOfShiftReports':
        return 'endOfShiftReportId';
      case 'voidReasonsReports':
        return 'voidReasonsReportId';
    }
  }, [bucket]);

  const reports = useSelector(
    reportsSelectors[
      selectorString
    ] as typeof reportsSelectors.selectItemComboSalesReportsArray,
  ) as TReportRow<IItemComboSalesReport>[];

  const reportsFiltered = useMemo(
    () =>
      filter<TReportRow<IItemComboSalesReport>>(
        reports,
        { ...filterParams } as {
          [key: string]:
            | number
            | string
            | {
                [key: string]: number | string;
              }
            | number[]
            | string[];
        },
        null,
        onSort,
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterParams, reports],
  );

  const customFilterFn = (row: Row<TReportRow<IItemComboSalesReport>>) => {
    return (
      row.depth > 0 ||
      reportsFiltered.findIndex(item => item.id === row.original.id) !== -1
    );
  };

  const COLUMN_VALUES = useMemo(
    () => {
      const _columnValues = [
        columnHelper.accessor('title', {
          header: t('menuScreen.itemsMain.table.headers.name'),
          filterFn: 'customFilterFn' as keyof FilterFns,
          cell: info => {
            const isSubRow = info.row.depth !== 0;
            const itemId =
              //@ts-expect-error - TS doesn't know that isItemComboSales is a boolean
              info.row.original[reportIdKey] as number;
            const pathUrl = getPathWithOrgData(
              `${REPORTS_SCREEN_BY_BUCKET_NAME[bucket]}/${itemId}`,
            );
            return (
              <Box
                csx={[
                  itemsTableNameStyles(info.row.original.isActive),
                  { flexDirection: 'row' },
                ]}
                onClick={() => {
                  navigate(pathUrl);
                }}>
                <Box className="dot color" />

                <Typography
                  csx={{
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    flex: 1,
                  }}
                  color={!isSubRow ? 'semanticBlue' : undefined}>
                  {info.getValue()}
                </Typography>
              </Box>
            );
          },
          size: 300,
        }),
        columnHelper.accessor('time', {
          header: 'Time',
          cell: info => <Typography>{info.getValue()}</Typography>,
          minSize: 150,
        }),

        columnHelper.accessor('categoryIds', {
          header: 'Categories',
          // cell: info => <Typography>{info.getValue()}</Typography>,
          cell: info => (
            <ItemComboTableCategoryCell categoryIds={info.getValue()} />
          ),
          minSize: 150,
        }),
        columnHelper.accessor('subcategoryIds', {
          header: 'Subcategories',
          cell: info => (
            <ItemComboTableSubcategoryCell subcategoryIds={info.getValue()} />
          ),
          minSize: 150,
        }),
        // columnHelper.accessor('orderStatuses', {
        //   header: 'Order Status',
        //   cell: info => (
        //     <ItemComboTableOrderStatusesCell orderStatuses={info.getValue()} />
        //   ),
        //   minSize: 150,
        // }),
        columnHelper.accessor('orderTypes', {
          header: 'Order Types',
          cell: info => (
            <ItemComboTableOrderTypesCell orderTypes={info.getValue()} />
          ),
          minSize: 150,
        }),
        columnHelper.accessor('reportingGroupIds', {
          header: 'Reporting Groups',
          cell: info => (
            <ItemComboTableReportingGroupCell
              reportingGroupIds={info.getValue()}
            />
          ),
          minSize: 150,
        }),
        columnHelper.accessor('repeatType', {
          header: 'Scheduled',
          cell: info => (
            <Box
              csx={[
                itemsTableNameStyles(info.row.original.isSchedulingEnabled),
                { flexDirection: 'row' },
              ]}>
              <Box className="dot color" />

              <Typography
                csx={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  flex: 1,
                }}>
                {info.row.original.isSchedulingEnabled
                  ? getRepeatTypeTranslation(info.getValue())
                  : t('commonTexts.na')}
              </Typography>
            </Box>
          ),
          minSize: 150,
        }),
        columnHelper.accessor(reportIdKey as 'id', {
          header: t('commonTexts.actions'),
          cell: info => {
            const id = info.getValue();
            const bucketUrl = REPORTS_SCREEN_BY_BUCKET_NAME[bucket];
            return (
              <Box
                csx={{
                  display: 'flex',
                  gap: '10px',
                }}>
                <Button
                  color="light"
                  onClick={() =>
                    navigate(getPathWithOrgData(`${bucketUrl}/edit/${id}`))
                  }
                  variant="secondary"
                  csx={{ width: '50px' }}
                  icon={<Icon name="MdEdit" />}
                />
                <Button
                  color="danger"
                  disabled={!isMasterMode}
                  onClick={() => handleDelete(id, bucket)}
                  variant="danger"
                  csx={{ width: '50px' }}
                  icon={<Icon name="MdDeleteForever" />}
                />
              </Box>
            );
          },
          size: 140,
        }),
      ];

      switch (bucket) {
        case 'itemComboSalesReports': {
          // add the following accessor at index 1
          const groupByColumn = (
            columnHelper as unknown as ColumnHelper<
              TReportRow<IItemComboSalesReport>
            >
          ).accessor('groupBy', {
            header: 'Group By',
            cell: info => (
              <ItemComboTableGroupByCell groupBy={info.getValue()} />
            ),
            minSize: 150,
          });

          return [_columnValues[0], groupByColumn, ..._columnValues.slice(1)];
        }

        case 'endOfShiftReports': {
          const ownerIdsColumn = (
            columnHelper as unknown as ColumnHelper<
              TReportRow<IEndOfShiftReport>
            >
          ).accessor('ownerIds', {
            header: 'Owner',
            cell: info => (
              <ItemComboTableOwnerIdsCell ownerIds={info.getValue()} />
            ),
            minSize: 150,
          });

          return [_columnValues[0], ownerIdsColumn, ..._columnValues.slice(1)];
        }

        default:
          return _columnValues;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [bucket, isMasterMode, reportIdKey, t],
  );

  const tableFilters = useMemo(
    () =>
      Object.entries(filterParams || {})
        .filter(([key]) =>
          COLUMN_VALUES.some(column => column.accessorKey === key),
        )
        .map(([key, filterParam]) => {
          return {
            id: key,
            value: filterParam,
          };
        }),
    [filterParams, COLUMN_VALUES],
  );

  return (
    <Box
      csx={{
        padding: '15px',
        height: '100%',
      }}>
      <Table
        scrollEnabled
        filterFns={{ customFilterFn }}
        columnFilters={tableFilters}
        columnSorting={[{ id: 'title', desc: false }]}
        manualSorting={false}
        // alwaysShowSortIcon
        enableAlphabeticalSorting
        nestedScrollEnabled
        data={reports}
        columns={COLUMN_VALUES as any}
        cellCsx={{
          height: '70px',
          paddingTop: '5px',
          paddingBottom: '5px',
        }}
        rowIdPrefix={ITEM_COMBO_SALES_ROW_ID_PREFIX}
        renderEmptyValues
      />
    </Box>
  );
};

export default ItemComboSalesTable;
