import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';

import Table from '@app/components/common/Table';
import { RootState } from '@app/state/store';
import { createColumnHelper } from '@tanstack/react-table';
import {
  ChildrenType,
  getCategoryChildrenType,
  IMenuType,
  MENU_TYPE_INITIAL_STATE,
} from '@westondev/tableturn-core';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import Card from '../../Card';
import DropdownItemBadge from '../../Dropdown/MultiSelectSearchDropdown/DropdownItemBadge';
import Icon from '../../Icon';
import Switch from '../../Switch';
import Typography from '../../Typography';
import GenericSelectionModal from '../GenericSelectionModal';
import { IItemSelection } from '../GenericSelectionModal/types';
import { ICustomSelectionModal } from '../types';
import { MenuTypeStatus } from './Filter/types';

interface ITable {
  name: string | undefined | null;
  posName: string | undefined | null;
  subcategories: number | string;
}

const MenuTypesSelectionModal = ({
  t,
  active = false,
  onModalClose,
  buttons = [],
  onAssociate,
  title,
  description,
  associateOnSelect,
  allowAssociateMultiple,
  showDescriptionPanel,
  showSelectedButtons,
  btnSuccessText,
  selectedMenuTypeIds,
  deleteAllMenuTypes,
  btnCancelText,
  ...otherProps
}: ICustomSelectionModal<unknown>) => {
  //redux
  const { menuTypes, categories } = useSelector(
    (state: RootState) => state.menu.selectedMode.currentMenu,
  );

  //local
  const [selectedMenuType, setSelectedMenuType] = useState<IMenuType>();
  const [dataTable, setDataTable] = useState<ITable[]>([]);
  const [menuTypeStatus, setMenuTypeStatus] = useState<MenuTypeStatus>(
    MenuTypeStatus.active,
  );

  const handleOnPress = (button: IItemSelection) => {
    const _menuType = menuTypes[Number(button.id)] || MENU_TYPE_INITIAL_STATE;

    setSelectedMenuType(_menuType);

    const _categories = Object.values(categories)
      .filter(category => _menuType.categoryIds.includes(category.id))
      .map(category => ({
        name: category.name,
        posName: category.posName,
        subcategories:
          getCategoryChildrenType(
            category.subcategoryMenuTypeItemsIds,
            category.subcategoryMenuTypeCombosIds,
          ) === ChildrenType.SUBCATEGORY
            ? category.subcategoryIds.length
            : '-',
      }));

    setDataTable(_categories);
  };

  const filteredButtons = useMemo(
    () =>
      menuTypeStatus === MenuTypeStatus.all
        ? buttons
        : buttons.filter(btn => {
            switch (menuTypeStatus) {
              case MenuTypeStatus.inactive:
                return !btn.isActive;
              case MenuTypeStatus.active:
                return btn.isActive;
            }
          }),
    [buttons, menuTypeStatus],
  );

  const statusText = {
    [MenuTypeStatus.all]: t('commonTexts.all'),
    [MenuTypeStatus.active]: t('commonTexts.active'),
    [MenuTypeStatus.inactive]: t('commonTexts.inactive'),
  };

  const setAllMenuTypeStatus = () => {
    setMenuTypeStatus(MenuTypeStatus.all);
  };

  const onFilter = (value: unknown) => {
    if (menuTypeStatus === value) {
      return setAllMenuTypeStatus();
    }
    setMenuTypeStatus(value as MenuTypeStatus);
  };

  const columnHelperAddress = createColumnHelper<ITable>();

  const COLUMN_VALUES = [
    columnHelperAddress.accessor('name', {
      header: 'Name',
      cell: info => info.getValue(),
      size: 150,
    }),
    columnHelperAddress.accessor('posName', {
      header: 'POS Name',
      cell: info => info.getValue(),
      size: 150,
    }),
    columnHelperAddress.accessor('subcategories', {
      header: 'Subcategories',
      cell: info => info.getValue() || '---',
      size: 50,
    }),
  ];

  return (
    <GenericSelectionModal
      onPress={button => handleOnPress(button)}
      associateOnSelect={associateOnSelect}
      onAssociate={onAssociate}
      onModalClose={onModalClose}
      active={active}
      showDescriptionPanel={showDescriptionPanel}
      allowAssociateMultiple={allowAssociateMultiple}
      buttons={filteredButtons}
      showSelectedButtons={showSelectedButtons}
      btnSuccessText={btnSuccessText}
      onFilter={onFilter}
      onFilterClear={setAllMenuTypeStatus}
      filterOptions={[
        {
          id: 1,
          label: t('menuScreen.itemDetails.menuTypes.filterModal.statusText'),
          options: [
            { label: t('commonTexts.all'), value: MenuTypeStatus.all },
            { label: t('commonTexts.active'), value: MenuTypeStatus.active },
            {
              label: t('commonTexts.inactive'),
              value: MenuTypeStatus.inactive,
            },
          ],
        },
      ]}
      isFilterClearDisabled={menuTypeStatus === MenuTypeStatus.all}
      filterValue={[{ id: 1, values: [menuTypeStatus] }]}
      selectedMenuTypeIds={selectedMenuTypeIds}
      showCommonFilter={false}
      filterInfo={
        <>
          <Box
            csx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            {menuTypeStatus !== MenuTypeStatus.all && (
              <DropdownItemBadge
                key={menuTypeStatus}
                text={statusText[menuTypeStatus]}
                onClick={() => {
                  setAllMenuTypeStatus();
                }}
              />
            )}
            {selectedMenuTypeIds && (
              <Switch
                checked={
                  selectedMenuTypeIds.length === 0 ||
                  filteredButtons.length === selectedMenuTypeIds.length
                    ? true
                    : false
                }
                label={t(
                  'settingsModule.registerModeSettings.general.modTags.allMenuTypes',
                )}
                labelCsx={theme => ({
                  color:
                    selectedMenuTypeIds.length === 0 ||
                    filteredButtons.length === selectedMenuTypeIds.length
                      ? theme.colors.textBlue
                      : theme.colors.semanticRed,
                })}
                onChange={() => {
                  deleteAllMenuTypes &&
                    filteredButtons.length !== selectedMenuTypeIds.length &&
                    deleteAllMenuTypes();
                }}
              />
            )}
          </Box>
        </>
      }
      title={
        <Box>
          <Typography
            variant="subtitle"
            fontWeight="medium"
            csx={{ marginBottom: '2px' }}>
            {title || t('menuScreen.selectionModal.menuTypes.title')}
          </Typography>
          <Typography color="darkestGrey">
            {description ||
              t('menuScreen.selectionModal.menuTypes.description')}
          </Typography>
        </Box>
      }
      selectedSectionTitle={t(
        'menuScreen.selectionModal.menuTypes.selectedSectionTitle',
      )}
      emptySectionMessage={t(
        'menuScreen.selectionModal.menuTypes.emptySectionText',
      )}
      noCardSelectedMessage={t(
        'menuScreen.selectionModal.menuTypes.noCardSelectedText',
      )}
      noAvailableCardsMessage={t(
        'menuScreen.selectionModal.menuTypes.noAvailableCardsText',
      )}
      btnCancelText={btnCancelText}
      {...otherProps}>
      {({ onSelect, isButtonSelected }) => (
        <Box csx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <Card.SubCard>
            <Box
              csx={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}>
              <Box>
                <Typography color="persistentSemanticBlue" fontWeight="medium">
                  {selectedMenuType?.name}
                </Typography>
                <Box csx={{ display: 'flex', gap: '15px' }}>
                  <Typography color="darkestGrey">
                    POS Name: {selectedMenuType?.posName}
                  </Typography>
                  <Typography color="darkestGrey">
                    ID: {selectedMenuType?.id}
                  </Typography>
                </Box>
              </Box>
              <Box csx={{ display: 'flex', flexDirection: 'row' }}>
                {isButtonSelected ? (
                  <Button
                    icon={<Icon name="MdDeleteForever" />}
                    variant="danger"
                    csx={{ minWidth: '50px' }}
                    onClick={() => onSelect()}
                  />
                ) : (
                  <Button onClick={() => onSelect()} variant="secondary">
                    {t('menuScreen.selectionModal.selectButton')}
                  </Button>
                )}
              </Box>
            </Box>
          </Card.SubCard>

          <Box csx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
            <Card.SubCard>
              <Box
                csx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                <Typography color="persistentSemanticBlue" fontWeight="medium">
                  {t('loggedIn.menuModule.tabs.categories')}
                </Typography>
                <Table
                  isStriped
                  align={{
                    subcategories: 'right',
                  }}
                  columns={COLUMN_VALUES}
                  data={dataTable}
                />
              </Box>
            </Card.SubCard>
          </Box>
        </Box>
      )}
    </GenericSelectionModal>
  );
};

export default MenuTypesSelectionModal;
