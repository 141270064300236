import { TCsx } from '@emotion/react';
import { IMenuStore } from '@westondev/tableturn-core';
import { WithTranslation } from 'react-i18next';
import { TFilterGenericSelectionModal } from '../FilterGenericSelectionModal/types';
import { MenuTypeStatus } from '../MenuTypesSelectionModal/Filter/types';

export interface IItemSelection {
  id: number | string;
  title: string;
  entity?: keyof IMenuStore;
  description?: string;
  isActive?: boolean;
  menuTypeId?: number[];
  roleIds?: number[];
  isSelected?: boolean;
  count?: number;
  isHidden?: boolean;
  subcategoryId?: number;
}

interface IChildrenFunction {
  onSelect: (extraInfo?: Partial<IItemSelection>) => void;
  isButtonSelected: boolean;
}

export type IGenericSelectionModal<T> = {
  active?: boolean;
  onModalClose: () => void;
  buttons: IItemSelection[];
  selectedButtonIds?: number[];
  scrollCardSectionNumberOfColumns?: number;
  title: string | React.ReactNode;
  onPress: (button: IItemSelection, isSelected: boolean) => void;
  children?: (params: IChildrenFunction) => React.ReactElement;
  selectedSectionTitle: string;
  onAssociate: (buttons: IItemSelection[], openInNewTab?: boolean) => void;
  emptySectionMessage: string;
  noCardSelectedMessage: string;
  noAvailableCardsMessage: string;
  filterInfo?: React.ReactNode;
  itemMenuTypeTemplate?: boolean;
  currentButtonInfo?: Partial<IItemSelection>;
  allowAssociateMultiple?: boolean;
  showDescriptionPanel?: boolean;
  btnSuccessText?: string;
  showSelectedButtons?: boolean;
  showOnly?: boolean;
  filterSelectedItems?: boolean;
  associateOnSelect?: boolean;
  itemMultipleMenuTypeTemplate?: boolean;
  itemMultipleMenuTypeTemplateDescription?: string;
  showCommonFilter?: boolean;
  defaultFilterValue?: MenuTypeStatus;
  showSelectAll?: boolean;
  onFilter?: TFilterGenericSelectionModal<T>['onFilter'];
  filterOptions?: TFilterGenericSelectionModal<T>['options'];
  filterValue?: TFilterGenericSelectionModal<T>['currentValue'];
  onFilterClear?: TFilterGenericSelectionModal<T>['onClear'];
  onFilterClearAll?: TFilterGenericSelectionModal<T>['onClearAll'];
  isFilterClearDisabled?: TFilterGenericSelectionModal<T>['isClearDisabled'];
  isFilterClearAllDisabled?: TFilterGenericSelectionModal<T>['isClearAllDisabled'];
  isFilterActive?: TFilterGenericSelectionModal<T>['isActive'];
  selectedMenuTypeIds?: number[];
  customOnDelete?: (id: string | number) => void;
  groupByMenuType?: boolean;
  filterFunction?: (elements: IItemSelection[]) => IItemSelection[];
  showIsLoadingOnPress?: boolean;
  preSelectedItem?: IItemSelection;
  onIsLink?: (id: number) => string | undefined;
  showSubcategoryDropdown?: boolean;
  btnCancelText?: string;
  buttonsContainerCsx?: TCsx;
} & WithTranslation;

export const emptyButton: IItemSelection = {
  id: -1,
  title: '',
  description: '',
};
