import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import Icon from '@app/components/common/Icon';
import Switch from '@app/components/common/Switch';
import Table from '@app/components/common/Table';
import Typography from '@app/components/common/Typography';
import { areSettingsMasterModeSelector } from '@app/state/selectors/settingsSelectors';
import { formatServiceChargeAmount } from '@app/state/settings/paymentsFactory';
import { store } from '@app/state/store';
import { createColumnHelper } from '@tanstack/react-table';
import {
  actionCreatorsSettings,
  actionCreatorsSettingsChangeData,
  generateTabletGeneratedId,
  IPaymentsSettings,
  IServiceCharge,
  OrderType,
  orderTypesText,
  ServiceChargeAssignTo,
  ServiceChargeDiscount,
  ServiceChargeType,
  SettingsSectionId,
} from '@westondev/tableturn-core/';
import { cloneDeep } from 'lodash';
import { useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { cellsStyle } from './styles';

interface IServiceChargeTable {
  id: number;
  active: boolean;
  posName: string;
  name: string;
  charge: number;
  chargeType: ServiceChargeType;
  assignTo: string;
  discount: string;
  orderTypes: OrderType[];
  minGuests: number;
  taxes: string;
  actions: string;
}

interface IServiceChargeTableProps extends WithTranslation {
  data: IServiceChargeTable[];
  navigateToServiceCharge: (id: number | string) => void;
}

const SECTION_ID = SettingsSectionId.PAYMENTS;

const ServiceCharge = ({
  t,
  data,
  navigateToServiceCharge,
}: IServiceChargeTableProps) => {
  const isMasterMode = useSelector(areSettingsMasterModeSelector);

  const dispatch = useDispatch();

  const { updateGenericSettings: setValue } = bindActionCreators(
    actionCreatorsSettings,
    dispatch,
  );

  const { clearSettingsError } = bindActionCreators(
    actionCreatorsSettingsChangeData,
    dispatch,
  );

  const { setSettingsChangeData } = bindActionCreators(
    actionCreatorsSettingsChangeData,
    dispatch,
  );

  const joinOrderTypes = (orderTypes: OrderType[]) => {
    const displayedOrderTypes = orderTypes.map(
      orderType => orderTypesText[orderType as OrderType],
    );
    displayedOrderTypes.sort();
    return displayedOrderTypes.join(', ');
  };

  const deleteServiceCharge = (id: number, errorIds: string[]) => {
    errorIds.forEach(errorId => clearSettingsError(errorId));

    const currentData = store.getState().settings.changeData
      .data as IPaymentsSettings;
    const clonedServiceCharges = cloneDeep(currentData?.serviceCharges || {});
    delete clonedServiceCharges[id];
    setValue<IPaymentsSettings>(
      { ...currentData, serviceCharges: clonedServiceCharges },
      SECTION_ID,
      true,
    );
  };

  const addServiceCharge = () => {
    const changeData = store.getState().settings.changeData;
    const serviceCharges =
      (changeData.data as IPaymentsSettings)?.serviceCharges || {};

    const newServiceChargeId = generateTabletGeneratedId();

    const defaultData: IServiceCharge = {
      name: '',
      posName: '',
      active: true,
      charge: 0,
      chargeType: ServiceChargeType.FIXED,
      assignTo: ServiceChargeAssignTo.SERVER,
      discount: ServiceChargeDiscount.PRE_DISCOUNT,
      orderTypes: [
        OrderType.CATERING,
        OrderType.DINE_IN,
        OrderType.DELIVERY,
        OrderType.TO_GO,
      ],
      serviceAreas: [],
      minGuests: 0,
      taxIds: [],
      id: newServiceChargeId,
      autoApply: false,
    };

    setSettingsChangeData({
      ...changeData,
      data: {
        ...(changeData.originalData as IPaymentsSettings),
        serviceCharges: {
          ...serviceCharges,
          [newServiceChargeId]: {
            ...defaultData,
          },
        },
      },
      hasChanges: true,
    });
    navigateToServiceCharge(newServiceChargeId);
  };

  const columnHelper = createColumnHelper<IServiceChargeTable>();
  const COLUMN_VALUES = useMemo(
    () => [
      columnHelper.accessor('active', {
        header: t(
          'settingsModule.paymentsSettings.serviceChargeSettings.status',
        ),
        cell: info => (
          <Box csx={cellsStyle}>
            <Switch
              checked={info.getValue()}
              onChange={newValue => {
                const tableObject = store.getState().settings.changeData
                  .data as IPaymentsSettings;
                const serviceChargesData = tableObject?.serviceCharges;
                const id = info.row.original.id;

                setValue<IPaymentsSettings>(
                  {
                    ...tableObject,
                    serviceCharges: {
                      ...serviceChargesData,
                      [id]: {
                        ...serviceChargesData[id],
                        active: newValue,
                      },
                    },
                  },
                  SECTION_ID,
                );
              }}
            />
          </Box>
        ),
        minSize: 60,
      }),
      columnHelper.accessor('name', {
        header: t('settingsModule.paymentsSettings.serviceChargeSettings.name'),
        cell: info => (
          <Box csx={cellsStyle}>
            <Typography>
              {info.getValue() ? info.getValue() : '- - -'}
            </Typography>
          </Box>
        ),
        minSize: 120,
      }),
      columnHelper.accessor('posName', {
        header: t(
          'settingsModule.paymentsSettings.serviceChargeSettings.posName',
        ),
        cell: info => (
          <Box csx={cellsStyle}>
            <Typography>
              {info.getValue() ? info.getValue() : '- - -'}
            </Typography>
          </Box>
        ),
      }),
      columnHelper.accessor('charge', {
        header: t(
          'settingsModule.paymentsSettings.serviceChargeSettings.charge',
        ),
        cell: info => (
          <Box csx={cellsStyle}>
            <Typography>
              {formatServiceChargeAmount(
                info.row.original.chargeType,
                info.getValue(),
              )}
            </Typography>
          </Box>
        ),
        minSize: 60,
      }),
      columnHelper.accessor('assignTo', {
        header: t(
          'settingsModule.paymentsSettings.serviceChargeSettings.assignTo',
        ),
        cell: info => (
          <Box csx={cellsStyle}>
            <Typography>
              {info.getValue() ? info.getValue() : '- - -'}
            </Typography>
          </Box>
        ),
        minSize: 120,
      }),
      columnHelper.accessor('discount', {
        header: t(
          'settingsModule.paymentsSettings.serviceChargeSettings.prePostDiscount',
        ),
        cell: info => (
          <Box csx={cellsStyle}>
            <Typography
              csx={{
                marginLeft: '15px',
              }}>
              {info.getValue() ? info.getValue().split('-')[0] : '- - -'}
            </Typography>
          </Box>
        ),
        minSize: 90,
      }),
      columnHelper.accessor('orderTypes', {
        header: t('settingsModule.taxesSettings.taxesTable.orderTypes'),
        cell: info => (
          <Box csx={cellsStyle}>
            <Typography>
              {info.getValue() === null
                ? t('commonTexts.all')
                : joinOrderTypes(info.getValue())}
            </Typography>
          </Box>
        ),
        minSize: 120,
      }),
      columnHelper.accessor('minGuests', {
        header: t(
          'settingsModule.paymentsSettings.serviceChargeSettings.minGuests',
        ),
        cell: info => (
          <Box
            csx={[
              cellsStyle,
              {
                width: '100%',
              },
            ]}>
            <Typography
              align="center"
              csx={{
                marginLeft: '20px',
              }}>
              {info.getValue()}
            </Typography>
          </Box>
        ),
        minSize: 60,
      }),
      columnHelper.accessor('taxes', {
        header: t(
          'settingsModule.paymentsSettings.serviceChargeSettings.taxes',
        ),
        cell: info => (
          <Box csx={cellsStyle}>
            <Typography>
              {info.getValue() ? info.getValue() : '- - -'}
            </Typography>
          </Box>
        ),
        minSize: 120,
      }),
      columnHelper.accessor('actions', {
        header: t(
          'settingsModule.paymentsSettings.serviceChargeSettings.actions',
        ),
        cell: info => (
          <Box
            csx={{
              display: 'flex',
              gap: '10px',
            }}>
            <Button
              variant="primary"
              csx={{
                width: '50px',
              }}
              icon={<Icon name="MdEdit" />}
              onClick={() => {
                navigateToServiceCharge(info.row.original.id);
              }}
            />
            <Button
              onClick={() => {
                deleteServiceCharge(info.row.original.id, []);
              }}
              icon={<Icon name="MdDeleteForever" />}
              csx={{
                width: '50px',
              }}
              disabled={!isMasterMode}
              variant="danger"
            />
          </Box>
        ),
        minSize: 110,
        enableSorting: false,
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, isMasterMode],
  );

  return (
    <Table
      title={t(
        'settingsModule.paymentsSettings.serviceChargeSettings.serviceCharges',
      )}
      renderEmptyValues
      cellCsx={{ height: 100 }}
      columns={COLUMN_VALUES}
      data={data}
      manualSorting={false}
      enableAlphabeticalSorting={true}
      addRowText={t(
        'settingsModule.paymentsSettings.serviceChargeSettings.addServiceCharge',
      )}
      onAddClick={addServiceCharge}
      noDataPlaceholder={
        <Box
          csx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '70px',
          }}>
          <Typography align="center">
            {t(
              'settingsModule.paymentsSettings.serviceChargeSettings.tableEmptyDataTitle',
            )}
          </Typography>
        </Box>
      }
    />
  );
};

export default ServiceCharge;
