import Box from '@app/components/common/Box';
import Divider from '@app/components/common/Divider';
import Typography from '@app/components/common/Typography';
import useRootSelector from '@app/hooks/useRootSelector';
import { AnyAction, bindActionCreators } from '@reduxjs/toolkit';
import {
  EReportsType,
  IReportsStore,
  OrderType,
  reportTimeFactory,
  TReportsStoreData,
  useLoadReportsChangeData,
} from '@westondev/tableturn-core';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import ItemComboTableCategoryCell from '../../ItemComboSales/ItemComboSalesList/ItemComboSalesTable/ItemComboTableSpecialCells/ItemComboTableCategoryCell';
import ItemComboTableSubcategoryCell from '../../ItemComboSales/ItemComboSalesList/ItemComboSalesTable/ItemComboTableSpecialCells/ItemComboTableSubcategoryCell';
import ItemComboTableReportingGroupCell from '../../ItemComboSales/ItemComboSalesList/ItemComboSalesTable/ItemComboTableSpecialCells/ItemComboTableReportingGroupCell';
import Button from '@app/components/common/Button';
import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';
import LoadingPDFCard from '../../LoadingPDFCard';
import {
  deleteReportWeb,
  fetchReportPDFWeb,
} from '@app/state/reports/reportsWebActions';
import { css } from '@emotion/react';
import { WithTranslation } from 'react-i18next';
import Icon from '@app/components/common/Icon';
import ItemComboTableOrderTypesCell from '../ItemComboSalesList/ItemComboSalesTable/ItemComboTableSpecialCells/ItemComboTableOrderTypesCell';
import { REPORTS_SCREEN_BY_BUCKET_NAME } from '@app/constants';

interface IItemComboSalesDetails extends WithTranslation {
  bucket?: keyof IReportsStore;
}

const ItemComboSalesDetails = ({
  t,
  bucket = 'itemComboSalesReports',
}: IItemComboSalesDetails) => {
  const navigate = useNavigateWithOrg();
  const dispatch = useDispatch();
  const deleteReport = bindActionCreators(deleteReportWeb, dispatch);

  const id = useParams().id;

  const report = useLoadReportsChangeData(Number(id), bucket);

  const handleEditClick = () => {
    const bucketBaseRoute = REPORTS_SCREEN_BY_BUCKET_NAME[bucket];
    const newUrl = `${bucketBaseRoute}/edit/${id}`;
    navigate(newUrl);
  };

  const pdfUrl = useRootSelector(state => state.reports.changeData.reportUrl);
  const isMasterMode = useRootSelector(
    state => state.reports.selectedMode.type === EReportsType.MASTER,
  );

  useEffect(() => {
    if (report) dispatch(fetchReportPDFWeb() as unknown as AnyAction);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report]);

  useEffect(() => {
    return () => {
      if (pdfUrl) URL.revokeObjectURL(pdfUrl);
    };
  }, [pdfUrl]);

  if (!report) return null;

  return (
    <Box
      csx={{
        height: '100%',
      }}>
      <Box
        csx={{
          display: 'flex',
          flexDirection: 'row',
          height: '100%',
          width: '100%',
          gap: 10,
        }}>
        <Box
          csx={theme => ({
            width: 200,
            height: '100%',
            backgroundColor: 'white',
            borderRadius: 10,
            boxShadow: theme.shadows[1],
            display: 'flex',
            flexDirection: 'column',
            gap: 15,
          })}>
          <Typography
            variant="heading"
            color="darkBlue"
            fontWeight="medium"
            csx={{ textAlign: 'center', padding: '20px 10px 5px 10px' }}>
            {report.name}
          </Typography>
          <Divider lineCsx={{ borderColor: 'lightGrey' }} />

          <Box
            csx={{
              padding: '0 15px',
              display: 'flex',
              flexDirection: 'column',
              gap: 15,
              flex: 1,
            }}>
            <FiltersBlock name="period" report={report} />
            {bucket !== 'creditCardBatchReports' && (
              <>
                <FiltersBlock name="categoryIds" value={report.categoryIds} />
                <FiltersBlock
                  name="subcategoryIds"
                  value={report.subcategoryIds}
                />
                {/* <FiltersBlock name="orderStatuses" value={report.orderStatuses} /> */}
                <FiltersBlock name="orderTypes" value={report.orderTypes} />
                <FiltersBlock
                  name="reportingGroupIds"
                  value={report.reportingGroupIds}
                />
              </>
            )}
          </Box>

          <Divider lineCsx={{ borderColor: 'lightGrey' }} />
          <Box
            csx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 10,
              padding: '15px',
              paddingTop: '0',
              marginTop: '-5px',
              marginBottom: '-5px',
            }}>
            <Button
              variant="secondary"
              csx={{ width: '100%', height: 55 }}
              icon={<Icon name="MdEdit" />}
              onClick={handleEditClick}>
              {t('commonButtons.edit')}
            </Button>

            <Button
              variant="danger"
              csx={{ width: '100%', height: 55 }}
              icon={<Icon name="MdDeleteForever" />}
              disabled={!isMasterMode}
              onClick={() => deleteReport(Number(id), bucket, true)}>
              {t('commonButtons.delete')}
            </Button>
          </Box>
        </Box>

        {!pdfUrl && <LoadingPDFCard />}

        {!!pdfUrl && (
          <object
            data={pdfUrl}
            type="application/pdf"
            width="100%"
            height="100%"
            style={{
              flex: 1,
            }}>
            <LoadingPDFCard />
          </object>
        )}
      </Box>
    </Box>
  );
};

const filtersBlockValueStyle = css({
  fontSize: '18px',
  fontWeight: 500,
  WebkitLineClamp: 2,
});

const FiltersBlock = <K extends keyof TReportsStoreData>({
  name,
  value,
  report,
}: {
  name: K;
  value?: TReportsStoreData[K];
  report?: TReportsStoreData;
}) => {
  let displayValue;
  let displayNameString = name as string;

  switch (name) {
    case 'period':
      displayNameString = 'Period:';
      if (report)
        displayValue = (
          <Typography csx={filtersBlockValueStyle}>
            {reportTimeFactory(report)}
          </Typography>
        );
      break;
    case 'categoryIds': {
      displayNameString = 'Categories:';
      displayValue = (
        <ItemComboTableCategoryCell
          categoryIds={value as number[] | null}
          csx={filtersBlockValueStyle}
        />
      );
      break;
    }
    case 'subcategoryIds':
      displayNameString = 'Subcategories:';
      displayValue = (
        <ItemComboTableSubcategoryCell
          subcategoryIds={value as number[] | null}
          csx={filtersBlockValueStyle}
        />
      );
      break;
    // case 'orderStatuses':
    //   displayNameString = 'Order Status:';
    //   displayValue = (
    //     <ItemComboTableOrderStatusesCell
    //       orderStatuses={value as OrderStatus[]}
    //       csx={filtersBlockValueStyle}
    //     />
    //   );
    //   break;
    case 'orderTypes':
      displayNameString = 'Order Types:';
      displayValue = (
        <ItemComboTableOrderTypesCell
          orderTypes={value as OrderType[]}
          csx={filtersBlockValueStyle}
        />
      );
      break;
    case 'reportingGroupIds':
      displayNameString = 'Reporting Groups:';
      displayValue = (
        <ItemComboTableReportingGroupCell
          reportingGroupIds={value as number[] | null}
          csx={filtersBlockValueStyle}
        />
      );
      break;
  }

  return (
    <Box
      csx={theme => ({
        borderRadius: 10,
        borderColor: theme.colors.lightGrey,
        padding: '12px',
        border: `1px solid ${theme.colors.lightGrey}`,
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
      })}>
      <Typography color="darkGrey" csx={{ fontSize: 16, fontWeight: '500' }}>
        {displayNameString}
      </Typography>
      {displayValue}
    </Box>
  );
};

export default ItemComboSalesDetails;
