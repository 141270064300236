import Box from '@app/components/common/Box';
// import useRefreshMenuWeb from '@app/hooks/useRefreshMenuWeb';
// import { actionCreatorsMenuWeb } from '@app/state';
// import { bindActionCreators } from '@reduxjs/toolkit';
import { WithTranslation } from 'react-i18next';
// import { useDispatch } from 'react-redux';
import BreadCrumbReports from '@app/components/LoggedIn/Reports/BreadCrumbReports';
import CreditCardBatchReportList from '@app/components/LoggedIn/Reports/CreditCardBatch/CreditCardBatchReportList';
import EmployeeSalesAndTipsReportList from '@app/components/LoggedIn/Reports/EmployeeSalesAndTips/EmployeeSalesAndTipsReportList';
import EndOfShiftList from '@app/components/LoggedIn/Reports/EndOfShift/EndOfShiftList';
import ItemComboSalesList from '@app/components/LoggedIn/Reports/ItemComboSales/ItemComboSalesList';
import SalesOverviewList from '@app/components/LoggedIn/Reports/SalesOverview/SalesOverviewList';
import VoidReasonsReportList from '@app/components/LoggedIn/Reports/VoidReasons/VoidReasonsReportList';
import { getPathWithOrgData } from '@app/helpers/navigation';
import { IReportsStore } from '@westondev/tableturn-core';
import { Outlet, useMatch, useParams } from 'react-router-dom';

interface IReportingBucketNavSwitch {
  bucket: keyof IReportsStore;
}

const ReportingBucketNavSwitch = ({
  bucket,
}: IReportingBucketNavSwitch & WithTranslation) => {
  const id = useParams().id;
  const isAdd = !!useMatch(getPathWithOrgData('/reports/:bucket/add'));
  const isEdit = !!useMatch(getPathWithOrgData('/reports/:bucket/edit/:id'));

  if (isAdd || isEdit || (id && !Number.isNaN(Number(id))))
    return (
      <Box
        csx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}>
        <BreadCrumbReports
          bucket={bucket}
          id={Number(id)}
          isPDFView={!!id && !isEdit}
        />
        <Box
          csx={{
            padding: '4px 15px 15px 15px',
            flex: 1,
          }}>
          <Outlet />
        </Box>
      </Box>
    );

  return (
    <Box csx={{ padding: '15px', height: '100%' }}>
      <ListComponent bucket={bucket} />
    </Box>
  );
};

const ListComponent = ({ bucket }: IReportingBucketNavSwitch) => {
  switch (bucket) {
    case 'itemComboSalesReports':
      return <ItemComboSalesList />;
    case 'salesOverviewReports':
      return <SalesOverviewList />;
    case 'endOfShiftReports':
      return <EndOfShiftList />;
    case 'creditCardBatchReports':
      return <CreditCardBatchReportList />;
    case 'employeeSalesAndTipsReports':
      return <EmployeeSalesAndTipsReportList />;
    case 'voidReasonsReports':
      return <VoidReasonsReportList />;
  }
};

export default ReportingBucketNavSwitch;
