import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import Card from '@app/components/common/Card';
import Divider from '@app/components/common/Divider';
import Grid from '@app/components/common/Grid';
import Icon from '@app/components/common/Icon';
import MainScreenWithFilterContainer from '@app/components/common/MainScreenWithFilterContainer';
import { IMainScreenWithFilterContainer } from '@app/components/common/MainScreenWithFilterContainer/MainScreenWithFilterContainer';
import SelectionModal from '@app/components/common/SelectionModal';
import { IItemSelection } from '@app/components/common/SelectionModal/GenericSelectionModal/types';
import Switch from '@app/components/common/Switch';
import { getMainBreadcrumbBucket } from '@app/helpers/factories/menu';
import {
  getPathWithOrgData,
  openNewTabWithOrgData,
} from '@app/helpers/navigation';
import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';
import useSessionStorageState from '@app/hooks/useSessionStorageState';
import { actionCreatorsMenuWeb } from '@app/state';
import {
  selectBreadCrumbs,
  selectCardItemList,
  selectCategoriesDropdownList,
  selectIsMenuMasterMode,
  selectMenuTypesDropdownList,
  selectSubcategoriesDropdownList,
} from '@app/state/menu/menuSelectors';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  BreadCrumbAction,
  IItemCard,
  actionCreatorsMenu,
} from '@westondev/tableturn-core';
import intersection from 'lodash/intersection';
import { useEffect, useMemo, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ItemMassUpdateModal from '../ItemMassUpdateModal';
import ItemsTable from './ItemsTable';

const ItemsList = ({ t }: WithTranslation) => {
  // Redux
  const dispatch = useDispatch();
  const assignItemsToLocationWeb = bindActionCreators(
    actionCreatorsMenuWeb.assignItemsToLocationWeb,
    dispatch,
  );
  const breadcrumbs = useSelector(selectBreadCrumbs);
  const itemCardList = useSelector(selectCardItemList);
  const categoriesOptions = useSelector(selectCategoriesDropdownList);
  const subcategoriesOptions = useSelector(selectSubcategoriesDropdownList);
  const menuTypesOptions = useSelector(selectMenuTypesDropdownList);
  const isMasterMode = useSelector(selectIsMenuMasterMode);
  // Local state
  const navigate = useNavigateWithOrg();
  const [additionModalState, setAdditionModalState] = useState(false);
  const [selectedMenuTypeIds, setSelectedMenuTypeIds] = useState<
    null | number[]
  >(null);
  const [massUpdate, setMassUpdate] = useState(false);
  const [showListView, setShowListView] = useSessionStorageState(
    'showListView',
    true,
  );

  const categoriesSet = useMemo(
    () =>
      new Set(
        (selectedMenuTypeIds !== null
          ? itemCardList.filter(
              item =>
                intersection(item.menuTypeId, selectedMenuTypeIds).length > 0,
            )
          : itemCardList
        )
          .map(item => item.categoryId)
          .flat(),
      ),
    [itemCardList, selectedMenuTypeIds],
  );

  const categoriesOptionsFiltered = useMemo(
    () =>
      categoriesOptions.filter(category => categoriesSet.has(category.value)),
    [categoriesOptions, categoriesSet],
  );

  const menusSet = useMemo(
    () => new Set(itemCardList.map(item => item.menuTypeId).flat()),
    [itemCardList],
  );

  const menusOptionsFiltered = useMemo(
    () => menuTypesOptions.filter(menu => menusSet.has(menu.value)),
    [menuTypesOptions, menusSet],
  );

  const dropdowns = useMemo(
    (): IMainScreenWithFilterContainer<IItemCard>['dropdowns'] => [
      {
        id: 'menuTypeId',
        data: [
          [{ label: t('commonTexts.all'), value: 0 }],
          menusOptionsFiltered,
        ],
        label: 'Menu',
        defaultValue: null,
        dataExample: 0,
        bucket: 'menuTypes',
        isMultiSelect: true,
      },
      {
        id: 'categoryId',
        data: [
          [{ label: t('commonTexts.all'), value: 0 }],
          categoriesOptionsFiltered,
        ],
        label: 'Category',
        defaultValue: null,
        dataExample: 0,
        bucket: 'categories',
        isMultiSelect: true,
      },
      {
        id: 'subcategoryId',
        data: [],
        getData: ({ categoryId: categoryIdFilter }) => {
          let _subcategoriesOptions: typeof subcategoriesOptions = [];
          if (typeof categoryIdFilter !== 'string') {
            _subcategoriesOptions = subcategoriesOptions.filter(option =>
              Array.isArray(categoryIdFilter)
                ? categoryIdFilter.includes(option.categoryId)
                : option.categoryId === categoryIdFilter,
            );
          }
          return [
            [{ label: t('commonTexts.all'), value: 0 }],
            _subcategoriesOptions,
          ];
        },
        label: 'Subcategory',
        defaultValue: null,
        dataExample: 0,
        getIsDisabled: (data, { categoryId: categoryIdFilter }) => {
          if (typeof categoryIdFilter === 'string' || categoryIdFilter === 0)
            return true;
          return !data[1].length;
        },
        bucket: 'subcategories',
        isMultiSelect: true,
      },
    ],
    [categoriesOptionsFiltered, subcategoriesOptions, t, menusOptionsFiltered],
  );

  const { setBreadCrumb } = bindActionCreators(actionCreatorsMenu, dispatch);

  const addBreadcrumbLocationWeb = bindActionCreators(
    actionCreatorsMenuWeb.addBreadcrumbLocationWeb,
    dispatch,
  );

  useEffect(() => {
    if (!breadcrumbs.length) {
      const navMain = getMainBreadcrumbBucket(4, null, navigate);
      navMain && setBreadCrumb([navMain]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [breadcrumbs]);

  const handleAddNewModItem = () => {
    addBreadcrumbLocationWeb({
      action: BreadCrumbAction.ADD,
      text: t('menuScreen.menuLocation.actionButtons.addNewItemTag'),
      onPress: () => navigate('/menu/items/add'),
      pathURL: '/menu/items/add',
    });
  };

  const actionDropdownOptions = useMemo(
    () => {
      const actions = [
        {
          text: t('menuScreen.menuLocation.actionButtons.addNewItem'),
          handler: handleAddNewModItem,
        },
      ];

      return actions;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, isMasterMode],
  );

  const handleItemAssociation = (buttons: IItemSelection[]) => {
    assignItemsToLocationWeb(
      buttons.map(button => Number(button.id)),
      'items',
    );
    setAdditionModalState(false);
  };

  return (
    <>
      <ItemMassUpdateModal
        isActive={massUpdate}
        onClose={() => setMassUpdate(false)}
        onCancel={() => setMassUpdate(false)}
      />
      <SelectionModal
        type="items"
        mainScreenProps={{ entity: 'items' }}
        active={additionModalState}
        onModalClose={() => setAdditionModalState(false)}
        onAssociate={handleItemAssociation}
      />

      <MainScreenWithFilterContainer
        dropdowns={dropdowns}
        onFilterChange={(id, value) => {
          if (id === 'menuTypeId') {
            setSelectedMenuTypeIds(value);
          }
        }}
        sortAvailable={!showListView}
        showChildren={showListView}
        customButtons={
          <Grid.Item
            mb={12}
            sm={showListView ? 7 : 6}
            md={showListView ? 7 : 6}
            lg={showListView ? 5 : 4}
            xl={showListView ? 6 : 5}>
            <Box
              csx={{
                display: 'flex',
                alignItems: 'center',
                height: '100%',
                paddingLeft: '15px',
                gap: 10,
              }}>
              <Button
                variant="primary"
                onClick={() => setMassUpdate(true)}
                icon={<Icon name="CustomLayers" color="textWhite" />}>
                {t('menuScreen.itemsMain.massUpdate.button')}
              </Button>
              <Divider csx={{ height: '100%' }} direction="vertical" />
              <Switch
                checked={showListView}
                onChange={isActive => setShowListView(isActive)}
                label={t('menuScreen.itemsMain.showListView')}
              />
            </Box>
          </Grid.Item>
        }
        breakpoint="medium"
        data={itemCardList}
        optionList={actionDropdownOptions}
        noItemsText={t('commonTexts.noneFound', {
          elementName: t('screenNames.ItemsScreen'),
        })}
        groupFilters
        optionalFiltersMatch={['upc']}
        renderItem={item => {
          const pathURL = `/menu/items/${item.id}`;
          return (
            <Card.Item
              {...item}
              isActive={true}
              showStatus={false}
              onClick={openInNewTab => {
                if (openInNewTab) return openNewTabWithOrgData(pathURL);

                addBreadcrumbLocationWeb({
                  action: BreadCrumbAction.NAV,
                  text: item.title,
                  onPress: () => {
                    navigate(pathURL, {
                      state: { menuTypeId: selectedMenuTypeIds },
                    });
                  },
                  statusPath: `selectedMode.currentMenu.items.${item.id}.active`,
                  pathURL,
                });
              }}
              isLink={getPathWithOrgData(pathURL)}
            />
          );
        }}>
        {filterParams => <ItemsTable filterParams={filterParams} />}
      </MainScreenWithFilterContainer>
    </>
  );
};

export default ItemsList;
