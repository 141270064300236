import { lngItemType } from '@app/types';
import { IMenuStore, IReportsStore } from '@westondev/tableturn-core';

export const MENU_SCREEN_BY_BUCKET_NAME: Record<keyof IMenuStore, string> = {
  items: '/menu/items',
  menuTypes: '/menu/menus',
  categories: '/menu/categories',
  subcategories: '/menu/subcategories',
  modifierGroups: '/menu/modifiers/modifier-groups',
  modifierItems: '/menu/modifiers/modifier-items',
  ingredients: '/menu/modifiers/ingredients',
  discounts: '/menu/discounts',
  combos: '/menu/combos/combos',
  itemGroups: '/menu/combos/item-groups',
  hotButtons: '/menu/hot-buttons',
  specials: '/menu/specials',
  reportingGroups: '/menu/reporting-groups',
};

export const REPORTS_SCREEN_BY_BUCKET_NAME: Record<
  keyof IReportsStore,
  string
> = {
  itemComboSalesReports: '/reports/item-combo-sales',
  salesOverviewReports: '/reports/sales-overview',
  endOfShiftReports: '/reports/end-of-shift',
  creditCardBatchReports: '/reports/credit-card-batch',
  employeeSalesAndTipsReports: '/reports/employee-sales-and-tips',
  voidReasonsReports: '/reports/void-reasons',
};

export enum CallMethods {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  DELETE = 'delete',
  PATCH = 'patch',
  STREAM = 'stream',
}

export enum ErrorCode {
  OUTDATED_MENU = 5,
  OUTDATED_SETTINGS = 17,
  OUTDATED_MENU_AND_SETTINGS = 18,
  LICENSE_ALREADY_IN_USE = 10,
  LICENSE_MISSING = 60,
}

export const LANGUAGES: lngItemType[] = [
  { key: 'en', name: 'English' },
  { key: 'es', name: 'Spanish' },
];

export enum statesList {
  AL = 'AL',
  AR = 'AR',
  AS = 'AS',
  AZ = 'AZ',
  CA = 'CA',
  CO = 'CO',
  CT = 'CT',
  DC = 'DC',
  DE = 'DE',
  FL = 'FL',
  GA = 'GA',
  GU = 'GU',
  HI = 'HI',
  IA = 'IA',
  ID = 'ID',
  IL = 'IL',
  IN = 'IN',
  KS = 'KS',
  KY = 'KY',
  LA = 'LA',
  MA = 'MA',
  MD = 'MD',
  ME = 'ME',
  MI = 'MI',
  MN = 'MN',
  MO = 'MO',
  MS = 'MS',
  MT = 'MT',
  NC = 'NC',
  ND = 'ND',
  NE = 'NE',
  NH = 'NH',
  NJ = 'NJ',
  NM = 'NM',
  NV = 'NV',
  NY = 'NY',
  OH = 'OH',
  OK = 'OK',
  OR = 'OR',
  PA = 'PA',
  PR = 'PR',
  RI = 'RI',
  SC = 'SC',
  SD = 'SD',
  TN = 'TN',
  TX = 'TX',
  UT = 'UT',
  VA = 'VA',
  VI = 'VI',
  VT = 'VT',
  WA = 'WA',
  WI = 'WI',
  WV = 'WV',
  WY = 'WY',
}

export type statesTypes =
  | 'AK'
  | 'AL'
  | 'AR'
  | 'AS'
  | 'AZ'
  | 'CA'
  | 'CO'
  | 'CT'
  | 'DC'
  | 'DE'
  | 'FL'
  | 'GA'
  | 'GU'
  | 'HI'
  | 'IA'
  | 'ID'
  | 'IL'
  | 'IN'
  | 'KS'
  | 'KY'
  | 'LA'
  | 'MA'
  | 'MD'
  | 'ME'
  | 'MI'
  | 'MN'
  | 'MO'
  | 'MS'
  | 'MT'
  | 'NC'
  | 'ND'
  | 'NE'
  | 'NH'
  | 'NJ'
  | 'NM'
  | 'NV'
  | 'NY'
  | 'OH'
  | 'OK'
  | 'OR'
  | 'PA'
  | 'PR'
  | 'RI'
  | 'SC'
  | 'SD'
  | 'TN'
  | 'TX'
  | 'UT'
  | 'VA'
  | 'VI'
  | 'VT'
  | 'WA'
  | 'WI'
  | 'WV'
  | 'WY';

export const MODULE_PATH_INDEX = 3;
export const BUCKET_PATH_INDEX = 4;
