import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import Card from '@app/components/common/Card';
import Grid from '@app/components/common/Grid';
import SelectionModal from '@app/components/common/SelectionModal';
import Typography from '@app/components/common/Typography';
import {
  selectCategories,
  selectChangeMassUpdateData,
  selectCurrentMenuTypes,
  selectItems,
  selectSubcategories,
} from '@app/state/menu/menuSelectors';
import { TCsx } from '@emotion/react';
import { useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ItemMassUpdateModal from '../../ItemMassUpdateModal';
import { massUpdateInformationSectionStyles } from './styles';

type IMassUpdateInformationSection = WithTranslation & {
  csx?: TCsx;
};

const MassUpdateInformationSection = ({
  t,
  csx,
}: IMassUpdateInformationSection) => {
  const [massUpdate, setMassUpdate] = useState(false);
  const [itemsModal, setItemsModal] = useState(false);

  const currentMenuTypes = useSelector(selectCurrentMenuTypes);
  const currentCategories = useSelector(selectCategories);
  const currentSubcategories = useSelector(selectSubcategories);
  const currentItems = useSelector(selectItems);
  const massUpdateData = useSelector(selectChangeMassUpdateData);

  return (
    <>
      <SelectionModal
        type="items"
        active={itemsModal}
        onModalClose={() => setItemsModal(false)}
        onAssociate={() => null}
        showDescriptionPanel={false}
        showSelectedButtons={false}
        scrollCardSectionNumberOfColumns={6}
        detailsScreenProps={{
          currentRelationsIds: Object.keys(currentItems).filter(
            id => massUpdateData.bucketIds.indexOf(Number(id)) === -1,
          ),
          wantedEntity: 'items',
        }}
        showOnly={true}
      />
      <ItemMassUpdateModal
        isActive={massUpdate}
        onClose={() => setMassUpdate(false)}
        onCancel={() => setMassUpdate(false)}
        updateChangeData={true}
      />
      <Card.SubCard csx={[massUpdateInformationSectionStyles, csx]}>
        <Grid>
          <Grid.Item mb={12} md={6}>
            <Grid rowGap={10}>
              <Grid.Item>
                <Typography
                  color="textBlue"
                  csx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                  }}>
                  <Typography color="textBlue" fontWeight="bold">
                    {massUpdateData.bucketIds.length}
                  </Typography>
                  &nbsp;
                  {t(
                    'menuScreen.itemsMain.massUpdate.massUpdateInformation.itemsSelectedFrom',
                  )}
                </Typography>
              </Grid.Item>
              <Grid.Item>
                <Grid columnGap={20}>
                  <Grid.Item mb={12} md={6}>
                    <Box
                      csx={theme => ({
                        paddingRight: 40,
                        borderRight:
                          massUpdateData.selectionCriteria?.categoryIds !==
                            null ||
                          massUpdateData.selectionCriteria?.subcategoryIds !==
                            null
                            ? `1px solid ${theme.colors.lightGrey}`
                            : undefined,
                      })}>
                      <Grid rowGap={10}>
                        <Grid.Item>
                          <Typography>
                            {t('menuScreen.common.bucket.menuTypes.plural')}:
                          </Typography>
                        </Grid.Item>
                        <Grid.Item>
                          <Box className="chip-container">
                            {massUpdateData.selectionCriteria?.menuTypeIds.map(
                              id => (
                                <Box className="chip" key={id}>
                                  <Typography
                                    fontWeight="medium"
                                    color="textBlue">
                                    {currentMenuTypes[id]?.name}
                                  </Typography>
                                </Box>
                              ),
                            )}
                          </Box>
                        </Grid.Item>
                      </Grid>
                    </Box>
                  </Grid.Item>
                  {massUpdateData.selectionCriteria?.categoryIds !== null && (
                    <Grid.Item mb={12} md={6}>
                      <Grid rowGap={10}>
                        <Grid.Item>
                          <Typography>
                            {t('menuScreen.common.bucket.categories.plural')}:
                          </Typography>
                        </Grid.Item>
                        <Grid.Item>
                          <Box className="chip-container">
                            {(
                              massUpdateData.selectionCriteria?.categoryIds ||
                              []
                            ).map(id => (
                              <Box className="chip" key={id}>
                                <Typography
                                  fontWeight="medium"
                                  color="textBlue">
                                  {currentCategories[id]?.name}
                                </Typography>
                              </Box>
                            ))}
                          </Box>
                        </Grid.Item>
                      </Grid>
                    </Grid.Item>
                  )}
                  {massUpdateData.selectionCriteria?.subcategoryIds !==
                    null && (
                    <Grid.Item mb={12} md={6}>
                      <Grid rowGap={10}>
                        <Grid.Item>
                          <Typography>
                            {t('menuScreen.common.bucket.subcategories.plural')}
                            :
                          </Typography>
                        </Grid.Item>
                        <Grid.Item>
                          <Box className="chip-container">
                            {(
                              massUpdateData.selectionCriteria
                                ?.subcategoryIds || []
                            ).map(id => (
                              <Box className="chip" key={id}>
                                <Typography
                                  fontWeight="medium"
                                  color="textBlue">
                                  {currentSubcategories[id]?.name}
                                </Typography>
                              </Box>
                            ))}
                          </Box>
                        </Grid.Item>
                      </Grid>
                    </Grid.Item>
                  )}
                </Grid>
              </Grid.Item>
            </Grid>
          </Grid.Item>
          <Grid.Item mb={12} md={6}>
            <Grid columnGap={20}>
              <Grid.Item mb={12} md={6}>
                <Button
                  className="button"
                  variant="primary"
                  onClick={() => setItemsModal(true)}>
                  {`${t(
                    'menuScreen.itemsMain.massUpdate.massUpdateInformation.viewSelection',
                  )}\n(${massUpdateData.bucketIds.length} ${t(
                    'menuScreen.common.bucket.items.plural',
                  )})`}
                </Button>
              </Grid.Item>
              <Grid.Item mb={12} md={6}>
                <Button
                  className="button"
                  variant="primary"
                  onClick={() => setMassUpdate(true)}>
                  {t(
                    'menuScreen.itemsMain.massUpdate.massUpdateInformation.editItemsSelection',
                  )}
                </Button>
              </Grid.Item>
            </Grid>
          </Grid.Item>
        </Grid>
      </Card.SubCard>
    </>
  );
};

export default MassUpdateInformationSection;
