import Box from '@app/components/common/Box';
import Card from '@app/components/common/Card';
import Grid from '@app/components/common/Grid';
import MainScreenWithFilterContainer from '@app/components/common/MainScreenWithFilterContainer';
import Switch from '@app/components/common/Switch';
import { getMainBreadcrumbBucket } from '@app/helpers/factories/menu';
import {
  getPathWithOrgData,
  openNewTabWithOrgData,
} from '@app/helpers/navigation';
import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';
import useSessionStorageState from '@app/hooks/useSessionStorageState';
import { actionCreatorsMenuWeb } from '@app/state';
import { initializeBucketListBreadcrumb } from '@app/state/app/actions';
import {
  selectBreadCrumbs,
  selectCardSpecialList,
} from '@app/state/menu/menuSelectors';
import { useAppDispatch } from '@app/state/store';
import { bindActionCreators } from '@reduxjs/toolkit';
import { BreadCrumbAction } from '@westondev/tableturn-core';
import { useEffect } from 'react';
import { WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import SpecialsTable from './SpecialsTable';

const SpecialsList = ({ t }: WithTranslation) => {
  // Redux
  const dispatch = useAppDispatch();

  const addBreadcrumbLocationWeb = bindActionCreators(
    actionCreatorsMenuWeb.addBreadcrumbLocationWeb,
    dispatch,
  );

  const specialsList = useSelector(selectCardSpecialList);

  const breadcrumbs = useSelector(selectBreadCrumbs);

  const [showListView, setShowListView] = useSessionStorageState(
    'showListView',
    true,
  );

  const navigate = useNavigateWithOrg();

  useEffect(() => {
    if (!breadcrumbs.length) {
      const navMain = getMainBreadcrumbBucket(7, null, navigate);
      if (!navMain) return;
      dispatch(initializeBucketListBreadcrumb(navMain));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [breadcrumbs]);

  const handleAddNewSpecial = () => {
    addBreadcrumbLocationWeb({
      action: BreadCrumbAction.ADD,
      text: t('menuScreen.menuLocation.actionButtons.addNewSpecialTag'),
      onPress: () => navigate('/menu/specials/add'),
      pathURL: '/menu/specials/add',
    });
  };

  return (
    <>
      <MainScreenWithFilterContainer
        noItemsText={t('commonTexts.noneFound', {
          elementName: t('screenNames.SpecialsScreen'),
        })}
        data={specialsList}
        optionList={[
          {
            text: t('menuScreen.menuLocation.actionButtons.addNewSpecial'),
            handler: handleAddNewSpecial,
          },
        ]}
        sortAvailable={!showListView}
        groupFilters
        showChildren={showListView}
        customButtons={
          <Grid.Item
            mb={12}
            sm={showListView ? 7 : 6}
            md={showListView ? 7 : 6}
            lg={showListView ? 5 : 4}
            xl={showListView ? 6 : 5}>
            <Box
              csx={theme => ({
                display: 'flex',
                alignItems: 'center',
                height: '100%',
                borderLeft: `1px solid ${theme.colors.lightGrey}`,
                paddingLeft: '15px',
              })}>
              <Switch
                checked={showListView}
                onChange={isActive => setShowListView(isActive)}
                label={t('menuScreen.itemsMain.showListView')}
              />
            </Box>
          </Grid.Item>
        }
        renderItem={special => {
          const pathURL = `/menu/specials/${special.id}`;
          return (
            <Card.Item
              {...special}
              isActive={true}
              showStatus={false}
              onClick={openInNewTab => {
                if (openInNewTab) return openNewTabWithOrgData(pathURL);

                addBreadcrumbLocationWeb({
                  action: BreadCrumbAction.NAV,
                  text: special.title,
                  onPress: () => {
                    navigate(pathURL);
                  },
                  statusPath: `selectedMode.currentMenu.specials${special.id}.active`,
                  pathURL,
                });
              }}
              isLink={getPathWithOrgData(pathURL)}
            />
          );
        }}>
        {filterParams => <SpecialsTable filterParams={filterParams} />}
      </MainScreenWithFilterContainer>
    </>
  );
};

export default SpecialsList;
